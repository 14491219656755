import { BsThreeDotsVertical } from "react-icons/bs";
import no_tickets from "../../../icons/no_tickets.svg";
import TicketAction from "./TicketAction";
import { useContext, useEffect, useState } from "react";
import { KycManager } from "../../../context-api/KycContext";
import TicketDetails from "./TicketDetails"; 
import { variableManager } from "../../../context-api/VariableContex";

export default function TicketsList() {
  const { setShowSupport, showSupport, ticket, setTicket } =
    useContext(KycManager);
  const { nameCase, tickets, setTickets, handleGetTickets } = useContext(variableManager);
  const [ticketActions, setTicketActions] = useState({ id: "", view: false });
  useEffect(() => {
    handleGetTickets();
    return () => {
      setTicket({ ...ticket, listPage: true, detailsPage: false });
    };
  }, []);

  

 

  return (
    <div
      className="activities-container tickets-list-container"
      onClick={() => {
        setTicketActions({ ...ticketActions, view: false });
      }}
    >
      {ticket.listPage && !ticket.detailsPage ? (
        <div className="table-section">
          {tickets.all.length ? (
            <table>
              <thead>
                <tr>
                  {/* <td>
                    <input type="checkbox" />
                  </td> */}
                  <td>Ticket ID</td>
                  <td>Date</td>
                  <td>Category</td>
                  <td>Title</td>
                  <td>Description</td>
                  <td>Status</td>
                  <td>Action</td>
                </tr>
              </thead>

              <tbody>
                {tickets.all.map((ticket, i) => {
                  return (
                    <tr
                      key={i}
                      className="pointer"
                      onClick={() => {
                        setTickets({ ...tickets, single: ticket });
                        setTicket({
                          ...ticket,
                          detailsPage: true,
                          listPage: false,
                        });
                      }}
                    >
                      {/* <td>
                        <input type="checkbox" />
                      </td> */}
                      <td>{ticket.ticketId}</td>
                      <td>{new Date(ticket.createdAt).toDateString()}</td>
                      <td>{nameCase(ticket.category)}</td>
                      <td className="">{nameCase(ticket.title)}</td>
                      <td className="table-truncate">{ticket.description}</td>
                      <td className=""> {ticket.status}</td>
                      <td className="">
                        {" "}
                        <BsThreeDotsVertical
                          onClick={(e) => {
                            e.stopPropagation();
                            setTicketActions({
                              ...ticketActions,
                              view: !ticketActions.view,
                              id: ticket.tiid,
                            });
                          }}
                        />
                        {ticketActions.view &&
                        ticketActions.id === ticket.tiid ? (
                          <TicketAction element={ticket} id={ticket.tiid} />
                        ) : null}{" "}
                      </td>
                    </tr>
                  );
                })}

               
              </tbody>
            </table>
          ) : (
            <div>
              <center>
                <div className="icon">
                  <img draggable="false" src={no_tickets} alt="no tickets" />
                </div>

                <h5>No Ticket Data</h5>
                <p>Click on the button below to create your first ticket</p>

                <div className="buttons">
                  <button
                    className="mo-active-btn-25"
                    onClick={() =>
                      setShowSupport({ ...showSupport, createTicket: true })
                    }
                  >
                    Create Ticket
                  </button>
                  {/* <button className="mo-skip-btn">Live Chat</button> */}
                </div>
              </center>
            </div>
          )}
        </div>
      ) : ticket.detailsPage && !ticket.listPage ? (
        <TicketDetails />
      ) : null}
    </div>
  );
}
