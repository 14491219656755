

export function HandleSelected(obj, objectKey, property){
    const updatedObj = {...obj};

    Object.keys(updatedObj[objectKey]).forEach((key)=>{
        updatedObj[objectKey][key] = false;
    })

    updatedObj[objectKey][property] = true
}