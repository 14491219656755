import { useContext, useState } from "react";
import "../../../styles/billspayment_page.scss";
import { paymentsList } from "./itemLists";
import { KycManager } from "../../../context-api/KycContext";
import RechargeDetails from "../../modals/bills_payment/airtime/RechargeDetails";
import { HandleSelected } from "../../../utils/HandleSelected";
import { useNavigate } from "react-router-dom";
import { variableManager } from "../../../context-api/VariableContex";

export default function BillsList() {
  const { handleBillStatusChange, setPayModalShow,
    payModalShow, initPayModal } = useContext(KycManager);
    const {user} = useContext(variableManager)
const [initialBill] = useState({
    receive: false,
    send: false,
    airtime: false,
    cableTv: false,
    electricity: false,
    examination: false,
})
  const [showPayment, setShowPayment] = useState({
  ...initialBill,
  });
const navigate = useNavigate()
  return (
    <div className="activities-container bills-list-container">
      <h5>Payments</h5>
    

      <div className="list-body">
        {paymentsList.map((e, i) => {
          return (
            <div
              key={i}
              className="item"
              onClick={() => { 


                setShowPayment({...initialBill, [e.tag]:true})

                if (
                  e.tag !== "receive" &&
                  e.tag !== "send" &&
                  e.tag !== "pos"
                ) { 
                    navigate("/user/dashboard")
                    handleBillStatusChange(e.tag, "details")
                }else{
                    if(e.tag==="pos"){
                        navigate("/user/dashboard/pos")
                    }
                    if(e.tag==="send"){
                        navigate("/user/dashboard/activities")
                        setPayModalShow({ ...payModalShow, chooseMethod: true })
                    }
                    if(e.tag==="receive"){
                        navigate("/user/dashboard/activities");
                        if (user.Accounts[0]) {
                          // setModalShowAccount(true)
                          setPayModalShow({ ...initPayModal, receiveMethod: true });
                        }
                    }
                }
              }}
            >
              <center>
                <div className="icon">{e.image}</div>
              </center>

              <p className="title">{e.title}</p>
              <p className="description">{e.desc}</p>
            </div>
          );
        })}

        {/* <div className="item">
          <center>
            <svg
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M56.8327 30.9994C56.8327 45.2594 45.2594 56.8327 30.9994 56.8327C16.7393 56.8327 5.16602 45.2594 5.16602 30.9994C5.16602 16.7393 16.7393 5.16602 30.9994 5.16602"
                stroke="#737B8B"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M47.8412 3.79314L47.7105 14.1256L58.043 14.2563"
                stroke="#F2000B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M60.7903 1.37343L47.7113 14.1257"
                stroke="#F2000B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M26.0179 38.75V21.0735C26.0178 20.6979 26.1246 20.3328 26.3217 20.0357C26.5187 19.7385 26.7947 19.5262 27.1065 19.4319C27.4182 19.3377 27.7479 19.3669 28.0438 19.515C28.3397 19.6631 28.5851 19.9217 28.7414 20.2501L37.1336 37.8749C37.2899 38.2033 37.5353 38.4619 37.8312 38.61C38.1271 38.7581 38.4568 38.7873 38.7685 38.6931C39.0803 38.5988 39.3563 38.3865 39.5533 38.0893C39.7504 37.7922 39.8572 37.4271 39.8571 37.0515V19.375M23.25 25.8333H42.625M23.25 32.2917H42.625"
                stroke="#4DC736"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </center>

          <p className="title">Receive Money</p>
          <p className="description">Receive payment from anyone</p>
        </div> */}

        {/* <div className="item">
          <center>
            <div className="icon">
              <svg
                width="62"
                height="62"
                viewBox="0 0 62 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.0179 38.75V21.0735C26.0178 20.6979 26.1246 20.3328 26.3217 20.0357C26.5187 19.7385 26.7947 19.5262 27.1065 19.4319C27.4182 19.3377 27.7479 19.3669 28.0438 19.515C28.3397 19.6631 28.5851 19.9217 28.7414 20.2501L37.1336 37.8749C37.2899 38.2033 37.5353 38.4619 37.8312 38.61C38.1271 38.7581 38.4568 38.7873 38.7685 38.6931C39.0803 38.5988 39.3563 38.3865 39.5533 38.0893C39.7504 37.7922 39.8572 37.4271 39.8571 37.0515V19.375M23.25 25.8333H42.625M23.25 32.2917H42.625"
                  stroke="#4DC736"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M56.8327 30.9994C56.8327 45.2594 45.2594 56.8327 30.9994 56.8327C16.7393 56.8327 5.16602 45.2594 5.16602 30.9994C5.16602 16.7393 16.7393 5.16602 30.9994 5.16602"
                  stroke="#737B8B"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M60.7083 11.6243V1.29102H50.375"
                  stroke="#32D16D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M47.791 14.2077L60.7077 1.29102"
                  stroke="#32D16D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M26.0179 38.75V21.0735C26.0178 20.6979 26.1246 20.3328 26.3217 20.0357C26.5187 19.7385 26.7947 19.5262 27.1065 19.4319C27.4182 19.3377 27.7479 19.3669 28.0438 19.515C28.3397 19.6631 28.5851 19.9217 28.7414 20.2501L37.1336 37.8749C37.2899 38.2033 37.5353 38.4619 37.8312 38.61C38.1271 38.7581 38.4568 38.7873 38.7685 38.6931C39.0803 38.5988 39.3563 38.3865 39.5533 38.0893C39.7504 37.7922 39.8572 37.4271 39.8571 37.0515V19.375M23.25 25.8333H42.625M23.25 32.2917H42.625"
                  stroke="#4DC736"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </center>
          <p className="title">Send Money</p>
          <p className="description">Receive payment from anyone</p>
        </div> */}
      </div>

      {/* <RechargeDetails
        show={showPayment.airtime} 
        onHide={() => setShowPayment({ ...showPayment, airtime:false })}
        backdrop={"static"}
      /> */}
    </div>
  );
}
