import { useState } from 'react';
import Modal from 'react-bootstrap/Modal'; 
import close from "../../../icons/close.svg" 
import "../../../styles/modals.scss" 
import PayNow from './PayNow';
import SendToBank from './SendToBank';
import ChooseMethod from './ChooseMethod';
import ContactList from './ContactList';
import SendToUsername from './SendToUsername';
export default function SendPayment(props) {
    const [userName, setUsername] = useState("")
    const [modalShowPayNow, setModalShowPayNow] = useState(false)
    return (
        <div className="sendpayment">
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Header  >
                    <div className="modal-header pointer">
                        <img draggable="false" src={close} alt="close" />
                    </div>
                </Modal.Header>
                <Modal.Body className='modalContent'>
                    {/* <h2>Send Payment to</h2>  */}

                    <div className="input-area"> 
                    </div>



                </Modal.Body>

            </Modal>

            {/* <div className="other-modals">
                <PayNow
                    // show={modalShowPayNow}
                    onHide={(e) => { setModalShowPayNow(false) }}
                    backdrop="static"
                />

                <SendToBank
                    //  show={modalShowPayNow}
                    onHide={(e) => { setModalShowPayNow(false) }}
                    backdrop="static"
                />

                <ChooseMethod
                    //  show={modalShowPayNow}
                    onHide={(e) => { setModalShowPayNow(false) }}
                    backdrop="static"
                />
                <ContactList
                    //  show={modalShowPayNow}
                    onHide={(e) => { setModalShowPayNow(false) }}
                    backdrop="static"
                />

                <SendToUsername
                    show={modalShowPayNow}
                    onHide={(e) => { setModalShowPayNow(false) }}
                    backdrop="static"
                />
            </div> */}
        </div>
    );
}

