import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import success from "../../icons/success_vector.svg";
import failed from "../../icons/fail_vector.svg";
import close from "../../icons/close.svg";
import "../../styles/modals.scss"; 
import { variableManager } from "../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";
export default function SingleNotification(props) { 

  const { msg, singleNotification, readNotification, setReadNotification, } = useContext(variableManager);
 
   return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <h5 className="text-center w-100">{singleNotification.title || "Notification"}</h5>
          <div
            className="modal-header pointer"
            onClick={() =>
                setReadNotification(false)
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <center>
            {
              readNotification && msg.spinner?(
                <Spinner/>
              ):(
                 <div className="detail">
              <h5 className="mo-info">{singleNotification.title}</h5>
              <p className="mantra">{singleNotification.body}</p>
            </div>
              )
            }
           
          </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}
