import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
export default function SendToBank(props) {
  const [userName, setUsername] = useState("");
  const { payModalShow, setPayModalShow, showCreate, setShowCreate } =
    useContext(KycManager);
  const [filled, setFilled] = useState(false);
  const [bankName, setBankName] = useState("");

  const {
    bankList,
    accountDetails,
    setAccountDetails,
    handleValidateBankAccount,
    handleFormatAmount,handleValidateBalance,
    msg,
    setMsg,
    user,
  } = useContext(variableManager);

  const [error, setError] = useState("");

  // console.log(user)

 
  useEffect(() => {
    handleValidation();
  }, [accountDetails]);

  function handleValidation() {
    if (
      !accountDetails.accountBank ||
      !accountDetails.accountName ||
      !accountDetails.accountNumber ||
      !accountDetails.amount ||
      !accountDetails.narration
    ) {
      setFilled(false);
    } else {
      setFilled(true);
    }
  }
  // console.log(bankName)
  function handleChange(event) {
    setError("");
    setMsg({ ...msg, error: "", success: "" });
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (event.target.name === "amount") {
      const value = event.target.value.replace(/,/g, "");
      setAccountDetails({
        ...accountDetails,
        [event.target.name]: value ? value : "",
      });
    } else {
      setAccountDetails({
        ...accountDetails,
        [event.target.name]: value ? value : "",
      });
    }
  }

  return (
    <div className="pay-now-container">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() => {
              setPayModalShow({ ...payModalShow, toBank: false });
              setError("")
              setMsg({ ...msg, error: "", success: "", spinner: false });
              setAccountDetails({
                ...accountDetails,
                accountName: "",
                accountBank: "",
                bankName:"",
                accountNumber: "",
                pin: "",
                narration: "",
                amount: "",
              });
            }}
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <div className="input-area">
            <div className="">
              <label htmlFor="bank">Bank</label>
              {accountDetails.bankName && (
                <input
                  type="text"
                  defaultValue={accountDetails.bankName}
                  placeholder={accountDetails.bankName}
                  onFocus={() =>
                    setAccountDetails({
                      ...accountDetails,
                      bankName: "",
                      accountNumber: "",
                      accountName: "",
                    })
                  }
                />
              )}{" "}
              {!accountDetails.bankName && (
                <select
                  name="accountBank"
                  id="bank"
                  className="select"
                  onChange={(e) => {
                    handleChange(e);
                    setAccountDetails({
                      ...accountDetails,
                      accountName: "",
                      accountNumber: "",
                      accountBank: e.target.value,
                      bankName: e.target.selectedOptions[0].dataset.set,
                    });
                    setBankName(e.target.selectedOptions[0].dataset.set);
                    // handleValidation();
                    //  if(accountDetails.accountNumber >= 10){
                    //     setAccountDetails({...accountDetails, accountName:""})
                    //  }
                  }}
                >
                  <option value="">Select Bank</option>
                  {bankList.length
                    ? bankList.map((bank, i) => {
                        return (
                          <option
                            value={bank.code}
                            data-set={bank.name}
                            key={i}
                          >
                            {bank.name}
                          </option>
                        );
                      })
                    : null}
                </select>
              )}
              <div
                className="d-flex justify-content-end mo-blue pointer"
                style={{ fontSize: "10px", userSelect: "none" }}
                onClick={() => {
                  setPayModalShow({ ...payModalShow, chooseContact: true });
                }}
              >
                Choose beneficiary
              </div>
            </div>

            <div className=" ">
              <label htmlFor="accountNumber">Account Number </label>

              {accountDetails.accountName && accountDetails.accountBank ? (
                <input
                  type="text"
                  id="accountNumber_locked"
                  placeholder="0000000000997"
                  name="accountNumber"
                  maxLength={10}
                  value={accountDetails.accountNumber}
                  onChange={(e) => {
                    handleChange(e);
                    // handleValidation();
                  }}
                  onClick={() =>
                    setAccountDetails({
                      ...accountDetails,
                      accountNumber: accountDetails.accountNumber,
                      accountName: "",
                    })
                  }
                />
              ) : (
                <input
                  type="text"
                  id="accountNumber"
                  placeholder="00000000009"
                  name="accountNumber"
                  maxLength={10}
                  value={accountDetails.accountNumber}
                  onChange={(e) => {
                    handleChange(e);
                    // handleValidation();
                    if (
                      accountDetails.accountBank &&
                      e.target.value.length === 10
                    ) {
                      handleValidateBankAccount(e.target.value);
                    } else {
                      setAccountDetails({
                        ...accountDetails,
                        accountName: "",
                        accountNumber: e.target.value,
                      });
                    }
                  }}
                />
              )}

              <span className="account-name d-flex justify-content-end fw-bolder">
                {accountDetails.accountName ? (
                  accountDetails.accountName
                ) : msg.error && msg.error === "Name cannot be verified at the moment" ? (
                  <span className="mo-danger">{msg.error}</span>
                ) : null}
              </span>
            </div>

            <div className=" ">
              <label htmlFor="username">Amount </label>
              <input
                type="text"
                id="username"
                placeholder="0000000000 "
                name="amount"
                value={handleFormatAmount(accountDetails.amount)}
                onChange={(e) => {
                  handleChange(e);
                  // handleValidation();
                }}
              />
            </div>
            <div className=" ">
              <label htmlFor="description">Payment Description </label>
              <input
                type="text"
                id="description"
                placeholder=" "
                name="narration"
                value={accountDetails.narration}
                onChange={(e) => {
                  handleChange(e);
                  // handleValidation();
                }}
              />
            </div>

            <div className="checkbox">
              <label htmlFor="saveBeneficiary">
                <input
                  type="checkbox"
                  name="saveBeneficiary"
                  id="saveBeneficiary"
                  value={accountDetails.saveBeneficiary}
                  onChange={(e) => handleChange(e)}
                />{" "}
                Save Beneficiary
              </label>
            </div>

            <div className="button-section">
              {/* <button
                onClick={() =>
                  setAccountDetails({
                    ...accountDetails,
                    accountBank: "",
                    accountName: "",
                    accountNumber: "",
                    amount: "",
                    narration: "",
                    saveBeneficiary:false
                  })
                }
              >
                clear
              </button> */}
              {/* FOR INSUFFICIENT BALANCE */}
              {error && <p className="mo-danger">{error}</p>}

              {payModalShow.toBank &&
              msg.error &&
              msg.error !== "Name cannot be verified at the moment" ? (
                <p className="mo-danger">{msg.error}</p>
              ) : null}{" "}
              {payModalShow.toBank && msg.success ? (
                <p className="mo-danger fw-bold">{msg.success}</p>
              ) : null}
              <button
                className={filled ? "btn-active" : "btn-inactive"}
                onClick={() => {
                  // handleSendtoBank();
                  if (filled && handleValidateBalance(accountDetails.amount, user)) {
                    setShowCreate({ ...showCreate, enterPin: true });
                  }else{
                    if(!handleValidateBalance(accountDetails.amount, user)){
                      setError("Insufficient funds. Kindly fund your wallet or send a lower amount")
                    }
                  }
                }}
              >
                {msg.spinner ? <Spinner size="sm" /> : "Pay Now"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
