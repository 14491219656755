import celebrate from "../../icons/celebrate.png"
import facebook from "../../icons/facebook.png"
import instagram from "../../icons/instagram.png"
import linkedin from "../../icons/linkedin.png"
import twitter from "../../icons/twitter.png"
import { Link } from "react-router-dom"
import "../../styles/resetAutoresponse.scss"
export default function ResetAutResponse() {
    const socials = [
        { social: facebook, url: "", alt:"facebook" }, 
        { social: twitter, url: "", alt:"twitter"  }, 
        { social: linkedin, url: "", alt:"linkedin"  }, 
        { social: instagram, url: "", alt:"instagram"  }
    ]

    return (
        <div className="background">
            <div className="reset-autoresponse-container">
            <div className="content">
                <div className="welcome">
                    <h2>Welcome to Mongoro</h2>
                    <div className="image">
                        <img src={celebrate} alt="welcome" />
                    </div>
                </div>

                <p className="mt-5 mb-5">Thanks for contacting Mongoro Team. To reset your password, kindly click on the reset button below.</p>

                <div className="button-area">
                    <button>Reset Password</button>
                </div>
                <p>Thanks, </p>

                <b>Support Team, Mongoro Team</b>

                <center className="contact">
                    <p>+234 09169451169</p>
                    <p>www.infos@mongoro.com</p>
                    <p>21, Blantyre Cresent, Wuse 2, Abuja</p>
                </center>

                <div className="socials">

                   {
                    socials.map((data)=>{
                        return  <Link to={data.url}><img src={ data.social} alt={data.alt} /></Link>
                    })
                   }

                </div>

                <p className="text-center w-100">Having trouble viewing this email? <span className="pointer">Click here</span> to view in your browser</p>
            </div>
        </div>
        </div>
    )
}