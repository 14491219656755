import "../../styles/createAccount.scss"; 
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { variableManager } from "../../context-api/VariableContex";
import { KycManager } from "../../context-api/KycContext";
import CreatePin from "../modals/security/CreatePin";
import { Spinner } from "react-bootstrap";

export default function FinishAccountSetupForm() { 

  const navigate = useNavigate();
  const { handleCompleteSetup, success, moreDetails, setMoreDetails, user, msg } =
    useContext(variableManager);
  const { showCreate, setShowCreate, states, modalShowCompletesetup, setModalShowCompletesetup, profile, setProfile, } =
    useContext(KycManager);
  const [empty, setEmpty] = useState(false);
  const [filled, setFilled] = useState(false);

 


  function handleChange(event) {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setMoreDetails({
      ...moreDetails,
      [event.target.name]: value 
    }); 

    if (
       moreDetails.address !=="" &&
      moreDetails.state !=="" &&
      moreDetails.gender !=="" &&
      moreDetails.city !=="" &&
      moreDetails.dateOfBirth !=="" &&
      moreDetails.occupation !==""
    ) {
      setFilled(true);
    } 
  }
 
 
  function handleValidation() {
    if (
      moreDetails.address === "" ||
      moreDetails.state === "" ||
      moreDetails.city === "" ||
      moreDetails.gender === "" ||
      moreDetails.dateOfBirth === "" ||
      moreDetails.occupation === "" ||
      moreDetails.purpose === ""
    ) {
      setEmpty(true); 
    } else {
      handleCompleteSetup()
    }
  }  

  return (
    <div className="finish-setup-form-container create-account-container">
      <div className="form-area">
        <div className="content">
          <h2>Finish your Account setup</h2>
          <p className="mantra">
            We make it easy for everyone to onboard and also know that your
            information is safe with us
          </p>

          <div className="input-area">
            <div className="label">
              <label htmlFor="name">Home Address</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="name"
                placeholder="No 5, yoruba street"
                name="address"
                onChange={(e) => handleChange(e)}
              />
            </div>
            {empty && moreDetails.address === "" && (
              <div className="mo-danger">Address is required</div>
            )}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="phone">Country</label>
            </div>
            <div className="input phone">
              <select
                name="country"
                id="country-code"
                onChange={(e) => handleChange(e)}
              >
                <option value="nigeria"> Nigeria</option>
                <option value="nigeria">United States</option>
              </select>
            </div>
            {empty && moreDetails.country === "" && (
              <div className="mo-danger">Address is required</div>
            )}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="state">State</label>
            </div>
            <div className="input"> 
              <select name="state" id="" onChange={(e) => handleChange(e)}>
                <option value="">Select state</option>
                {states.length
                  ? states.map((e, i) => {
                      return (
                        <option value={e.toLowerCase()} key={i}>
                          {e}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
            {empty && moreDetails.state === "" && (
              <div className="mo-danger">State is required</div>
            )}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="username">City</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="username"
                name="city"
                placeholder="Enter your city"
                onChange={(e) => handleChange(e)}
              />
            </div>
            {empty && moreDetails.city === "" && (
              <div className="mo-danger">City is required</div>
            )}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="password">Select Gender</label>
            </div>
            <div className="input">
              <select name="gender" id="" onChange={(e) => handleChange(e)}>
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            {empty && moreDetails.gender === "" && (
              <div className="mo-danger">Gender is required</div>
            )}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="dob">Date of Birth</label>
            </div>
            <div className="input">
              <input
                type="date"
                max={new Date(Date.now() - 16 * 365 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]}
                // max={new Date(Date.now() - 65 * 365 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]}
                id="dob"
                name="dateOfBirth"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            {empty && moreDetails.dateOfBirth === "" && (
              <div className="mo-danger">Date of Birth is required</div>
            )}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="occupation">Occupation</label>
            </div>
            <div className="input"> 

              <select name="occupation"  id="occupation" onChange={(e) => handleChange(e)} >
                <option value="">Select occupation</option>
                <option value="account_collector" >Account Collector</option>
                <option value="goods_business">Business (Goods) </option>
                <option value="services_business">Business (Services) </option> 
                <option value="civil servant">Civil Servant</option>
                <option value="corporate">Corporate (Office worker) </option>
                <option value="freelancer">Freelancer</option>
                <option value="student">Student</option>
                <option value="unemployed" >Unemployed</option>
              </select>



            </div>
            {empty && moreDetails.occupation === "" && (
              <div className="mo-danger">Occupation is required</div>
            )}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="confirm-password">
                Purpose for signing up on Mongoro
              </label>
            </div>
            <div className="input">
              <select
                name="purpose"
                id="purpose"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Purpose from List</option>
                <option value="personal">Personal</option>
                <option value="businness">Business</option>
                <option value="agency">Agency</option>
              </select>
            </div>
            {empty && moreDetails.purpose === "" && (
              <div className="mo-danger">Purpose is required</div>
            )}
          </div>

          <div className="buttons">
            <button
            disabled={msg.spinner?"{true}":""}
              className={!empty && filled ? "  pointer" : "next-btn "}
              onClick={() => {
                handleValidation(); 
              }}
            >
              {
                msg.spinner?(
                  <Spinner
                  size="sm"
                  />
                ):"Next"
              }
            </button>

            <button
              className="skip-btn pointer"
              onClick={() => { 
                if (!modalShowCompletesetup) {
                  navigate("/signup/success"); 
                } else {
                  setModalShowCompletesetup(false); 
                } 
              }}
            >
              Skip
            </button>
          </div>
        </div>
      </div>

      <CreatePin
        show={showCreate.pin}
        onHide={() => setShowCreate({ ...showCreate, pin: false })}
        backdrop={"static"}
      />
    </div>
  );
}
