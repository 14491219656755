import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../context-api/KycContext";
import "../../styles/modals.scss";
import add_photo from "../../icons/add_photo.png";
import { variableManager } from "../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";

export default function KycPVC(props) {
  const {modalShowDriversLicense, setModalShowDriversLicense} = useContext(KycManager);
  const {msg, handleImageChange, image, setImage, handleIdentityUpload } = useContext(variableManager); 
  const [idDetails, setIdDetails] = useState({ 
    expireAt: "",
    idNumber:""
  });
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onHide={()=>{setImage({...image, toDisplay:""})}} ></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Voter's Card</h2>
        <p className="small-p">
          Please Upload your Permanent Voter's Card Here
        </p>

        <div className="input-area">
          <div className="label">
            <label htmlFor="enterEvn">ID Number</label>
          </div>

          <div className="input">
            <input
              type="text"
              id="enterBvn"
              placeholder="Enter Number"
              onChange={(e) => { 
                setIdDetails({
                  ...idDetails,
                  idNumber:e.target.value, 
                });
              }}
            />
          </div>

          <div className="upload-image-container upload-id-field ">
            <p className="mt-3 small-p">Upload photo of ID</p>

            {image.toDisplay ? (
              <div className="file-input-area">
                {image.toDisplay && (
                  <img
                    src={image.toDisplay}
                    alt="permanent voter's card"
                    className="img-flui"
                  />
                )}

                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="upload-image"
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                />
              </div>
            ) : (
              <div className="file-input-area">
                <label className="label" htmlFor="upload-image">
                  <p className="drag small-p">Upload</p>
                  <p className="small-p">or</p>
                  <p className="text-primary small-p">Browse files</p>
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="upload-image"
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                />
              </div>
            )}
            <p className="mt-3 small-p">Accepted format: jpeg, PNG</p>
          </div>
        </div>

        <div className="buttons">
          <button
          disabled={msg.spinner || !idDetails.idNumber || !image.toDisplay?("{true}"):("")}
            className={image.toDisplay && idDetails.idNumber ? "btn-active" : "btn-inactive"}
            
            onClick={() => { 
              handleIdentityUpload(idDetails, "pvc")
            }}
          >
            {
              msg.spinner?(
                <Spinner
                size="sm"
                />
              ):("Confirm")
            }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
