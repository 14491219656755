import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import "../../../styles/modals.scss";
export default function ActivatePOS(props) {
  const {
    msg, 
    toverify,
    setToverify, 
    showVeriSuccess,
    setShowVeriSuccess,  
  } = useContext(KycManager); 

//   const {msg} = useContext(variableManager)
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Activate POS</h2>
        <p>Please Enter your Terminal ID</p>

        <div className="input-area">
          <div className="label">
            <label htmlFor="enterEvn">Terminal ID</label>
          </div>
          <div className="input">
            <input
              type="text"
              id="enterBvn"
              placeholder="Enter Terminal ID" 
              onChange={(e) => {
                setShowVeriSuccess({ ...showVeriSuccess, error: "" });
                setToverify({ ...toverify, terminal_id: e.target.value });
              }}
            />
          </div>
        </div>
        <p className="text-danger text-center fw-bold">
          {showVeriSuccess.error ? showVeriSuccess.error : null}
        </p>

        <div className="buttons">
          <button
            className={toverify.terminal_id ? "btn-active" : "btn-inactive"}
            onClick={() => {
            //   handleVerifyBvn();

              // setModalShowBvn(false)
              // setModalShowNin(true)
            }}
          >
           {
                        msg.spinner ? (
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        ):"Submit"
                      }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
