import React, { useContext } from "react";
import "./App.css";
import "../src/styles/misc_general_styles.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"; 
import ResetAutResponse from "./components/login/ResetAutoResponse";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import FinishAccountSetup from "./pages/FinishAccountSetup";
import ForgotPassword from "./pages/ForgotPassword";
import AccountCreationSuccess from "./pages/AccountCreationSuccess";
import VerifyAccount from "./pages/VerifyAccount";
import UpdatePassword from "./pages/UpdatePassword";
import UploadImage from "./pages/UploadImage";
import KycHere from "./components/modals/KycHere"; 
import Dashboard from "./components/dashboard/Dashboard";
import KycContext from "./context-api/KycContext";
import Activites from "./pages/Activities";
import VariableContext, { variableManager } from "./context-api/VariableContex";
import PaymentSuccess from "./pages/PaymentSuccess";
import POS from "./pages/POS";
import Settings from "./pages/Settings";
import Notifications from "./pages/Notifications";
import AccountUpgrade from "./components/account_upgrade/AccountUpgrade";
import Support from "./pages/Support";
import TicketDetails from "./components/dashboard/support/TicketDetails";
import ProtectedRoute from "./context-api/ProtectedRoute";
import PrivateLoginRoute from "./utils/private_routes/PrivateLoginRoute";
import PrivateAccountRoutes from "./utils/private_routes/PrivateAccountRoutes";
import BillsPayment from "./pages/BillsPayment";
// import Tests from "./pages/Tests";
function App() {
  return (
    <BrowserRouter>
      <KycContext>
        <VariableContext>
          <Routes>
            <Route path="/kyc/alert" element={<KycHere />} />
            {/* <Route path='/voo-onoja-test-app' element={<Tests />} /> */}

            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup/uploads" element={<UploadImage />} />
            <Route path="/signup/verification" element={<VerifyAccount />} />
            <Route
              path="/signup/success"
              element={<AccountCreationSuccess />}
            />
            <Route
              path="/signup/finish-setup"
              element={<FinishAccountSetup />}
            />

            <Route path="/reset" element={<ResetAutResponse />} />

            <Route element={<PrivateLoginRoute />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/forgot-password/new-password"
              element={<UpdatePassword />}
            /> 
            <Route path="/payment/success" element={<PaymentSuccess />} />
            
            
            <Route element={<PrivateAccountRoutes />}>
              <Route
                path="/user/dashboard/activities"
                element={<Activites />}
              />
            </Route>
            
            <Route element={<ProtectedRoute />}>
              <Route
                path="/user/dashboard/upgrade"
                element={<AccountUpgrade />}
              />
              <Route
                path="/user/dashboard/notifications"
                element={<Notifications />}
              />
              <Route path="/user/dashboard" element={<Dashboard />} />
              <Route path="user/dashboard/pos" element={<POS />} />
              <Route path="user/dashboard/settings" element={<Settings />} />
              <Route path="user/dashboard/support" element={<Support />} />
              <Route path="user/dashboard/bills" element={<BillsPayment />} />
              <Route
                path="user/dashboard/support/ticket"
                element={<TicketDetails />}
              />
            </Route>
          </Routes>
        </VariableContext>
      </KycContext>
    </BrowserRouter>
  );
}

export default App;
