import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../context-api/KycContext";
import "../../styles/modals.scss";
import add_photo from "../../icons/add_photo.png";
import { variableManager } from "../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";

export default function KycNIN(props) {
  const {modalShowDriversLicense, setModalShowDriversLicense} = useContext(KycManager);
  const {msg, user, handleImageChange, image, setImage, handleIdentityUpload } = useContext(variableManager);
  const [nin, setNin] = useState("");
  const [idDetails, setIdDetails] = useState({ 
    expireAt: "",
    idNumber:""
  });
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onHide={()=>{setImage({...image, toDisplay:""})}} ></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>National ID</h2>
        <p className="small-p">
          Please Upload your NIN Here
        </p>

        <div className="input-area">
          <div className="label">
            <label htmlFor="enterEvn">ID Number</label>
          </div>

          <div className="input">
            <input
              type="text"
              id="enterBvn"
              placeholder="Enter Number"
              onChange={(e) => { 
                setIdDetails({
                  ...idDetails,
                  idNumber:e.target.value,
                //   firstName: user.firstName,
                //   lastName: user.surname,
                //  userId:user._id
                });
              }}
            />
          </div>

          <div className="upload-image-container upload-id-field ">
            <p className="mt-3 small-p">Upload photo of ID</p>

            {image.toDisplay ? (
              <div className="file-input-area">
                {image.toDisplay && (
                  <img
                    src={image.toDisplay}
                    alt="permanent voter's card"
                    className="img-flui"
                  />
                )}

                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="upload-image"
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                />
              </div>
            ) : (
              <div className="file-input-area">
                <label className="label" htmlFor="upload-image">
                  <p className="drag small-p">Upload</p>
                  <p className="small-p">or</p>
                  <p className="text-primary small-p">Browse files</p>
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="upload-image"
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                />
              </div>
            )}
            <p className="mt-3 small-p">Accepted format: jpeg, PNG</p>
          </div>
        </div>

        <div className="buttons">
          <button
                     disabled={msg.spinner || !idDetails.idNumber || !image.toDisplay?("{true}"):("")}

            className={image.toDisplay ? "btn-active" : "btn-inactive"}
            onClick={() => {
              // setModalShowPassport(false);
              // setModalShowAccount(true);
              handleIdentityUpload(idDetails, "nin")
            }}
          >
            {
              msg.spinner?(
                <Spinner
                size="sm"
                />
              ):("Confirm")
            }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
