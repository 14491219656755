 
import "../styles/dashboard.scss";  
import { useNavigate } from "react-router-dom"; 
 import Navigation from "../components/dashboard/Navigation";
import Sidebar from "../components/dashboard/Sidebar"; 
import { useContext } from "react";
import { KycManager } from "../context-api/KycContext";
import BillsList from "../components/dashboard/bills_payment/BillsList";


export default function BillsPayment() { 
  const navigate = useNavigate()
  const {showSidebar, setShowSidebar} = useContext(KycManager)
 
  return (
    <div className="dashboard-container">
      <div className="navigation-section">
        <Navigation />
      </div>
      <div className="body-section">
        <div className={showSidebar?"sidebar":"hide-sidebar"}>
          <Sidebar />
        </div>

        <div className="dashboard-body" onClick={()=>setShowSidebar(false)}>
          <BillsList /> 
        </div>
      </div>










      

      <div className="modals">

        
     
      </div>
    </div>
  );
}
