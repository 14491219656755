import { useContext} from "react"; 
import Modal from "react-bootstrap/Modal";
import { variableManager } from "../../../context-api/VariableContex";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";  
import export_error from "../../../icons/error.svg"
import { Spinner } from "react-bootstrap";
export default function ExportError(props) {
  const { msg, handleGenerateStatement, } = useContext(variableManager);

  const { exportStatement, setExportStatement } = useContext(KycManager); 

//  function handleRetryGenerateStatement(){
//     handleGenerateStatement()
//  }

  

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        onHide={() => {
          setExportStatement({
            ...exportStatement,
            request: false,
            success:false,
            failed:false,
          });
 
        }}
      ></Modal.Header>
      <Modal.Body className="modalContent"> 

       <center>
        <div>
            <img src={export_error} alt="account statement generated successfully" />
        </div>
        <h5>Error!</h5>
       {
        exportStatement.error?( <p>{exportStatement.error}</p>): <p>Error generating statement</p>
       }
     

        <div className="buttons w-50">
          <button
            className="btn-active bg-danger"
            disabled={msg.spinner ? "{true}" : ""}
            onClick={() => { 
                handleGenerateStatement(exportStatement.body) 
            }}
          >
           {
            msg.spinner?(
                <Spinner size="sm" variant="light"/>
            ):"Retry"
           }
          </button>
          
           {/* <button
            className="mo-skip-btn btn btn-light"
            disabled={msg.spinner ? "{true}" : ""}
            onClick={() => {
              setExportStatement({...exportStatement, failed:false})
            }}
          >
           Cancel
          </button> */}
        </div>
          </center>
      </Modal.Body>
    </Modal>
  );
}
