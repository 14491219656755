import { useContext, useEffect } from "react";
import { variableManager } from "../context-api/VariableContex";
import axios from "axios";
import axiosInstance from "../utils/axios_instance";

export default function AccountBalance() {
  const { user, setUser, name, url, handleInsights, handleTransactionHistory, handleNotifications } =
    useContext(variableManager);

  useEffect(() => {
    const timeOut = setInterval(() => {
      getBalance();
    }, 10000);

    return () => clearInterval(timeOut);
  }, [getBalance]);

  async function getBalance() {
    try { 
       const currentBalance = user.walletBalance;
      const response = await axiosInstance.get(`/user/single`);    
      const updatedBalance = response.data.data.walletBalance; 
      if (
        updatedBalance &&
        parseFloat(currentBalance) !== parseFloat(updatedBalance)
      ) { 
        setUser({ ...user, walletBalance: updatedBalance }); 
        handleInsights();
        handleTransactionHistory();
        handleNotifications();
      } 
    } catch (error) {
      console.log(error);
    }
  }
}
