import "../../../styles/settings.scss";
import { BsArrowLeft,  BsTrash } from "react-icons/bs";
import { useContext, useEffect } from "react";
import { KycManager } from "../../../context-api/KycContext"; 
import { variableManager } from "../../../context-api/VariableContex";


export default function LoginActivity() {
  const {  navselect, setNavselect, profile, setProfile } = useContext(KycManager);
  const {  activities } = useContext(variableManager);
 
 
  return (
    <div className="settings-container login-activity-container">
      <div className="list-body">
        <b
          className="mo-blue pointer"
          onClick={() =>
            setNavselect({
              ...navselect,
              profile: false,
              security: true,
              notification: false,
              loginActivity: false,
            })
          }
        >
          {" "}
          <BsArrowLeft /> go back
        </b>
        <div className="pt-4">
          <p className="fw-bold">Login Activity</p>

          {activities.length
            ? activities.map((e) => {
                return (
                  <div className="item" key={e.auid}>
                    <div className="parameter">
                      <b>{e.device}</b>
                      <p>
                        Last login{" "}
                        {e.loginAt
                          ? new Date(e.loginAt).toDateString() +
                            "," +
                            new Date(e.loginAt).toLocaleTimeString()
                          : ""}
                      </p> 
                    </div>

                    <div className="value">
                      <p className="mo-danger pointer"
                      onClick={()=>{
                        setProfile({...profile, 
                          deletePrompt:{...profile.deletePrompt, device:true, bank:false, deviceId:e.auid }}) 
                      }}



                      >
                        <BsTrash />
                      </p>
                    </div>
                  </div>
                );
              })
            : "No login activity"}

          
        </div>
      </div>

      <div className="modals"></div>
    </div>
  );
}
