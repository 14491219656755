import { useContext} from "react"; 
import Modal from "react-bootstrap/Modal";
import { variableManager } from "../../../context-api/VariableContex";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";  
import export_success from "../../../icons/export_success.svg"
export default function ExportSuccess(props) {
  const { msg,  } = useContext(variableManager);

  const { exportStatement, setExportStatement } = useContext(KycManager); 

 

  

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        onHide={() => {
          setExportStatement({
            ...exportStatement,
            request: false,
            success:false,
          });
 
        }}
      ></Modal.Header>
      <Modal.Body className="modalContent"> 

       <center>
        <div>
            <img src={export_success} alt="account statement generated successfully" />
        </div>
        <h5>Statement Generated Successfully</h5>
        <p>statement generated successfully. Kindly check your email for your statement</p>
     

        <div className="buttons w-50">
          <button
            className="btn-active"
            disabled={msg.spinner ? "{true}" : ""}
            onClick={() => {
              setExportStatement({...exportStatement, success:false})
            }}
          >
           Okay
          </button>
        </div>
          </center>
      </Modal.Body>
    </Modal>
  );
}
