import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { variableManager } from "./VariableContex";
import axiosInstance from "../utils/axios_instance";
export const KycManager = createContext();

export default function KycContext({ children }) {
  const [modalShowPrompt, setModalShowPrompt] = useState(false);
  const [modalShowBvn, setModalShowBvn] = useState(false);
  const [modalShowNin, setModalShowNin] = useState(false);
  const [modalShowDriversLicense, setModalShowDriversLicense] = useState(false);
  const [modalShowPvc, setModalShowPvc] = useState(false);
  const [modalShowPassport, setModalShowPassport] = useState(false);
  const [modalShowSelectMethod, setModalShowSelectMethod] = useState(false);
  const [modalShowAccount, setModalShowAccount] = useState(false);
  const [modalShowAccountType, setModalShowAccountType] = useState(false);
  const [modalShowCompletesetup, setModalShowCompletesetup] = useState(false);
  const [modalShowNoAccountPrompt, setModalShowNoAccountPrompt] =
    useState(false);

  //SIDEBAR TOGGLERS
  const [showSidebar, setShowSidebar] = useState(false);
  const [initSidebarItem] = useState({
    dashboard: false,
  });
  const [currentSidebarItem, setCurrentSidebarItem] = useState({
    ...initSidebarItem,
    dashboard: true,
  });

  // VERIFICATION AND VERIFICATION STATUS MODAL CONTROLLERS
  const [showVeriSuccess, setShowVeriSuccess] = useState({
    bvnSuccess: false,
    idDoc: false,
    indemnity: false,
    error: "",
  });

  const [toverify, setToverify] = useState({
    bvn: "",
    idDoc: "",
    terminal_id: "",
  });

  //TERMS, CONDITIONS, AND INDEMNITY
  const [showTerms, setShowTerms] = useState(false);
  const [showIndemnity, setShowIndemnity] = useState(false);

  //LOADING EFFECT CONTROLLER
  const [msg, setMsg] = useState({ spinner: false, error: "", success: "" });

  // INITIALIZE PAYMENT MODALS CONTROLLERS
  const [initPayModal, setInitPayModal] = useState({
    chooseMethod: false,
    toUsername: false,
    chooseContact: false,
    payNow: false,
    toBank: false,
    receiveMethod: false,
    fromBank: false,
    fromCard: { method: false, cardList: false },
    fromMongoro: false,
    mongoroType: false,
    paySuccess: false,
    transsaction: { id: "", ref: "", msg: "", network_err: "" },
    payFail: false,
    insufficient: false,
  });

  const [payModalShow, setPayModalShow] = useState({
    ...initPayModal,
  });

  //SUPPORT MODALS
  const [showSupport, setShowSupport] = useState({
    createTicket: false,
    ticketSuccess: false,
    ticketError: false,
  });
  //TICKETS
  const [ticket, setTicket] = useState({ detailsPage: false, listPage: true });
  //RAISE TICKET FROM TRANSACTION
  // const [raiseTicket, setRaiseTicket] = useState(true);

  const [ticketDetails, setTicketDetails] = useState({
    title: "",
    category: "",
    docs: "",
    option: "",
    amount: "",
    method: "",
    description: "",
  });

  //NOTIFICATION MODAL
  const [view, setView] = useState({ notifications: false, messages: false });

  //EXPORT ACCOUNT STATEMENT MODAL CONTROLLERS
  const [exportStatement, setExportStatement] = useState({
    request: false,
    success: false,
    failed: false,
    error: "",
    body: { startDate: "", endDate: "", email: "", userId: "" },
  });

  const [user, setUser] = useState({});

  const base_url = process.env.REACT_APP_BASE_URL;

  //PINS AND PASSWORDS CONTROLLERS
  const [showCreate, setShowCreate] = useState({
    pin: false,
    password: true,
    pos: false,
    enterPin: false,
    pinSuccess: false,
  });

  const [showPos, setshowPos] = useState({
    request: false,
    terms: false,
    terminal_id: false,
    enterPin: false,
    request_success: false,
    activate_success: false,
  });
  // console.log(user)

  const [states, setStates] = useState([]);

  //TOGGLERS
  const [navselect, setNavselect] = useState({
    profile: true,
    security: false,
    notification: false,
    loginActivity: false,
  });

  //SETTINGS CONTROLLERS
  const [profile, setProfile] = useState({
    bankAddSuccess: false,
    deletePrompt: {
      bank: false,
      device: false,
      beneficiary: false,
      bankId: "",
      deviceId: "",
      beneficiaryId: "",
    },
    imageUpload: false,
    verifyEmailPhone: false,
    changePin: false,
    resetPin: false,
    pinSuccess: false,
    changePassword: false,
    resetPassword: false,
    passwordSuccess: false,

    deleteAccount: {
      delete: false,
      success: false,
      enterPin: false,
      enterPassword: false,
      payload: { reason: "", description: "", pin: "", password: "" },
    },

    selected: {
      resetPassword: false,
      resetPin: false,
      changePassword: false,
      changePin: false,
    },

    payload: {
      id: "",
      pin: "",
      confirm_pin: "",
      new_pin: "",
    },
  });

  //USER DETAIL
  const [name, setName] = useState({ token: "" });

  const [rerender, setRerender] = useState(false);

  //LOGOUT PROMPT
  const [logoutPrompt, setLogoutPrompt] = useState(false);

  //BILLS PAYMENT CONTROLLERS
  const [showBill, setShowBill] = useState({
    actionFrom: { airtime: false, cableTv: false },

    global: {
      deletePrompt: false,
      chooseBeneficiary: false,
      enterPin: false,
      actionId:"",
    },

    status: {
      success: false,
      error: false,
    },

    airtime: {
      details: false,
      enterPin: false,
      paymentMethod: false,
      chooseBeneficiary: false,
      airtimeSuccess: false,
      dataSuccess: false,
    },

    cableTv: {
      details: false,
      enterPin: true,
      paymentMethod: false,
      chooseBeneficiary: true,
    },

    electricity: {
      details: false,
      paymentMethod: false,
      enterPin: false,
    },
  });

  const [selectedBillProvider, setSelectedBillProvider] = useState({
    cable: {
      dstv: true,
      gotv: false,
      startime: false,
      startimeOn: false,
      showmax: false,
    },

    airtime: {
      mtn: false,
      glo: false,
      airtel: true,
      nineMobile: false,
    },
  });

  const [billPayload, setBillPayload] = useState({
    airtimeData: {
      customer: "",
      amount: "",
      type: "",
      pin: "",
      saveBeneficiary:false,
      beneficiaryName:"",
      //for data
      code: "",
      itemCode: "",
    },
    cableTv: {
      customer: "",
      amount: "",
      type: "",
      pin: "",
      code: "",
      itemCode: "",
      validCustomer: "",
    },

    response: ""
  });

  //EXPORT DATA
  const exportData = {
    rerender,
    setRerender,

    //SIDEBAR
    showSidebar,
    setShowSidebar,
    initSidebarItem,
    currentSidebarItem,
    setCurrentSidebarItem,

    base_url,
    msg,
    setMsg,
    modalShowPrompt,
    modalShowCompletesetup,
    setModalShowCompletesetup,
    modalShowBvn,
    modalShowNin,
    modalShowAccount,
    modalShowAccountType,
    modalShowPassport,
    modalShowSelectMethod,
    setModalShowPassport,
    modalShowDriversLicense,
    setModalShowDriversLicense,
    modalShowPvc,
    setModalShowPvc,
    setModalShowPrompt,
    setModalShowBvn,
    setModalShowNin,
    setModalShowAccount,
    setModalShowAccountType,
    setModalShowSelectMethod,

    //PAY MODALS CONTROL
    initPayModal,
    setInitPayModal,
    payModalShow,
    setPayModalShow,

    //EXPORT STATEMENT MODALS
    exportStatement,
    setExportStatement,

    //DATA VERIFICATION
    toverify,
    setToverify,

    //VERIFICATION SUCCESS
    showVeriSuccess,
    setShowVeriSuccess,

    //ACCOUNT NUMBER
    handleGenerateAccount,
    // handleBankList,

    //PIN and PAssword
    showCreate,
    setShowCreate,

    //TERMS
    showPos,
    setshowPos,

    //NIGERIAN STATES
    states,
    setStates,

    //TOGGLERS
    navselect,
    setNavselect,

    //USER'S SETTINGS CONTROLLERS
    profile,
    setProfile,

    //TERMS, CONDITIONS AND INDEMNITY
    showTerms,
    setShowTerms,
    showIndemnity,
    setShowIndemnity,

    //SUPPORT TOGGLERS
    showSupport,
    setShowSupport,
    ticket,
    setTicket,
    ticketDetails,
    setTicketDetails,
    // raiseTicket,
    // setRaiseTicket,

    //LOGOUT
    logoutPrompt,
    setLogoutPrompt,

    //NOTIFICATION
    view,
    setView,

    //USER HAS NO ACCOUNT TO TRANSFER
    modalShowNoAccountPrompt,
    setModalShowNoAccountPrompt,

    //BILLS PAYMENT CONTROLLERS
    showBill,
    setShowBill,
    handleBillStatusChange,
    selectedBillProvider,
    setSelectedBillProvider,
    billPayload,
    setBillPayload,
  };

  useEffect(() => {
    const localUser = localStorage.getItem("mg-u-tkn");

    if (localUser) {
      handleStatesList();
    }
  }, []);

  async function handleStatesList() {
    try {
      await axiosInstance.get(`/state`).then((resp) => {
        setStates(resp.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function handleGenerateAccount() {
    console.log("generating account from kyc manager");
    // if (user) {
    setMsg({ ...msg, spinner: true });
    try {
      await axiosInstance.post(`/user/account`).then((response) => {
        console.log(response.data);
        setRerender(true);
        setMsg({ ...msg, spinner: false });
        setModalShowAccountType(false);

        // if (response.data.data.success) {
        //   setModalShowBvn(false);
        //   setShowVeriSuccess({ ...showVeriSuccess, bvnSuccess: true });
        // } else {
        //   setShowVeriSuccess({
        //     ...showVeriSuccess,
        //     error: response.data.message,
        //   });
        // }
      });
    } catch (error) {
      setMsg({ ...msg, spinner: false, error: "error.response.data.message" });
      console.log(error.response.data.message);
      setMsg({ ...msg, error: error.response.data.message });
      setShowVeriSuccess({
        ...showVeriSuccess,
        error:
          error.response.data.message ||
          "Invalid credentials. please contact support",
      });
    }
    // }
  }

  function handleBillStatusChange(objectKey, property) {
    const updatedObject = { ...showBill };

    if (objectKey !== "switchOff" && objectKey !== "resetAll") {
      Object.keys(updatedObject[objectKey]).forEach((key) => {
        updatedObject[objectKey][key] = false;
      });

      updatedObject[objectKey][property] = true;

      updatedObject.actionFrom[objectKey] = true;
    } else {
      if (objectKey === "switchOff") {
        Object.keys(updatedObject.global).forEach((key) => {
          updatedObject.global[key] = false;
        });
      }
      if (objectKey === "resetAll") {
        Object.keys(updatedObject).forEach((key) => {
          Object.keys(updatedObject[key]).forEach((innerkey) => {
            updatedObject[key][innerkey] = false;
          });
        });
      }
    }
  }

  return (
    <KycManager.Provider value={exportData}>{children}</KycManager.Provider>
  );
}
