import "../../styles/createAccount.scss";
import { Link, useNavigate } from "react-router-dom"; 
import { useContext, useState } from "react";
import { variableManager } from "../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";
import { validEmail } from "../regex/Regex";
import axiosInstance from "../../utils/axios_instance";
export default function ForgotPasswordForm() {
  const navigate = useNavigate();
  const { url, code, setCode, msg, setMsg, unknownUser, setUnknownUser } =
    useContext(variableManager);
  const [email, setEmail] = useState("");

  async function validateForgotPasswordEmail(email) {
    if (email) {
      if (validEmail.test(email)) {

        localStorage.setItem("unknown_user_email", email) 

        try {
          setMsg({ ...msg, error: "", spinner: true });
          setCode({
            ...code,
            email: "",
            sms: "",
          });

          await axiosInstance
            .post(`/auth/reset-otp`, { email: email })
            .then((resp) => { 
              setMsg({
                ...msg,
                error: "",
                spinner: false,
              });

              setCode({
                ...code,
                email: resp.data.data,
                sms: resp.data.data,
              });

              setUnknownUser({ ...unknownUser, status: true, email: email });

              navigate("/signup/verification");
            });
        } catch (error) {
          setMsg({
            ...msg,
            success: "",
            spinner: false,
            error: error.response.data.message,
          }); 
        }
      } else {
        setMsg({ ...msg, error: "Invalid Email format" });
        setTimeout(() => {
          setMsg({ ...msg, error: "" });
        }, 3000);
      }
    } else {
      setMsg({ ...msg, error: "", spinner: false });
      setMsg({ ...msg, error: "Email field cannot be empty" });
      setTimeout(() => {
        setMsg({ ...msg, error: "" });
      }, 3000);
    }
  }
 

  return (
    <div className="forgot-password-form-container create-account-container">
      <div className="form-area">
        <Link className="text-dark" to={"/signup"}>
          <span className="signup-redirect-link">
            New user? <b className="pointer"> &nbsp; Create an account</b>
          </span>
        </Link>
        <div className="content">
          <h2>Forgot your password</h2>

          <div className="input-area">
            <div className="label">
              <label htmlFor="username">Email Address</label>
            </div>
            <div className="input">
              <input
                type="email"
                id="username"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="website@mongoro.com"
              />
            </div>
          </div>
          {msg.error && <p className="error">{msg.error}</p>}
          {msg.success && <p className="success">{msg.success}</p>}

          <button
            className=""
            onClick={() => {
              validateForgotPasswordEmail(email);
              // navigate("/forgot-password/new-password")
            }}
          >
            {msg.spinner ? <Spinner size="sm" /> : "Reset password"}
          </button>

          <div className="forgot-password">
            <p className="pointer">
              Remember your password?{" "}
              <b className="pointer">
                <Link className="text-dark" to={"/login"}>
                  Log in
                </Link>
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
