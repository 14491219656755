// import React, { useContext } from "react";
// import { Navigate, Outlet } from "react-router-dom";
// import { variableManager } from "../../context-api/VariableContex";
// import { KycManager } from "../../context-api/KycContext";
 
// function PrivateAccountRoutes() {
//  const {user} = useContext(variableManager)
//  const {setModalShowNoAccountPrompt} = useContext(KycManager)
 
//  const localUser = localStorage.getItem("mg-u-tkn"); 
//  return !localUser ? Navigate({to:"/login", replace:true}) : user && !user.Accounts[0]? setModalShowNoAccountPrompt(true):<Outlet />;

// }

// export default PrivateAccountRoutes;




import React, { useContext, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { variableManager } from "../../context-api/VariableContex";
import { KycManager } from "../../context-api/KycContext";
 
function PrivateAccountRoutes() {
  const {user} = useContext(variableManager);
  const {setModalShowNoAccountPrompt} = useContext(KycManager);

  const localUser = localStorage.getItem("mg-u-tkn"); 
  const navigate = useNavigate()

  useEffect(() => {
    if (user && !user.Accounts[0]) {
      setModalShowNoAccountPrompt(true);
      navigate("/user/dashboard")
    }
  }, [user, setModalShowNoAccountPrompt]);

  return !localUser ? (
    <Navigate to="/login" replace />
  ) : (
    <Outlet />
  );
}

export default PrivateAccountRoutes;
