import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal"; 
import close from "../../../icons/close.svg";
import next from "../../../icons/next.svg"; 
import nga_flag from "../../../icons/nga_flag.svg";
import usa_flag from "../../../icons/usa_flag.svg";
import bank_icon from "../../../icons/bank_icon.svg";
import "../../../styles/getPaid.scss";
import { KycManager } from "../../../context-api/KycContext";
export default function MongoroType(props) {
  const [userName, setUsername] = useState("");
  const [modalShowPayNow, setModalShowPayNo] = useState(false);

  const { setModalShowAccount, payModalShow, setPayModalShow, initPayModal } =
    useContext(KycManager);
  return (
    <div className="select-mongoro-type-container">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>
              setPayModalShow({ ...payModalShow, mongoroType: false })
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <h2>Select Account</h2>

          <div className="input-are">
            <div className="section-info">
                This your unique Mongoro accounts, money sent here will be automatically credited to your wallet
               
  
            </div> 
            
            
             <div className="method-area"
             onClick={()=>{
                setModalShowAccount(true)
                setPayModalShow({ ...initPayModal, receiveMethod: false })
            }}
             >
              <div className="method">
                <div className="icon">
                  <img draggable="false" src={nga_flag} alt="bank transfer" />
                </div>
                <div className="text">
                    Nigerian Naira <br />
                    <span className="currency">NGN</span> 0171007665
                </div>
              </div>


              
              <div className="amount">
                
                 <p><b>₦</b> <span className="balance">0</span></p>
              </div>
            </div> 
            
             <div className="method-area">
              <div className="method">
                <div className="icon">
                  <img draggable="false" src={usa_flag} alt="bank transfer" />
                </div>
                <div className="text">
                    United States Dollar <br />
                    <span className="currency">USD</span> 0171007665
                </div>
              </div>


              
              <div className="amount">
                
                 <p><b>$</b> <span className="balance">0</span></p>
              </div>
            </div> 
 

            {/* <div
              className="tag pointer mogoro-user"
              onClick={() =>
                setPayModalShow({ ...initPayModal, toUsername: true })
              }
            >
              <img src={tag1} draggable="false" alt="send to mongoro user" />
            </div>
            <div
              className="tag pointer bank"
              onClick={() => setPayModalShow({ ...initPayModal, toBank: true })}
            >
              <img src={tag2} draggable="false" alt="send to bank account" />
            </div> */}


          </div>
        </Modal.Body>
      </Modal>

      <div className="other-modals">
        {/* <SendToUsername
                    show={payModalShow.toUsername}
                    onHide={()=>setPayModalShow({...payModalShow, toUsername:false})}
                    backdrop="static"
                /> */}

        {/* <SendToBank
                    show={payModalShow.toBank}
                    onHide={()=>setPayModalShow({...payModalShow, toBank:false})}
                    backdrop="static"
                /> */}
      </div>
    </div>
  );
}
