import { BiSearch } from "react-icons/bi";
import filter from "../../icons/filter.png";
import refresh from "../../icons/refresh.png";
// import refresh from "../../icons/refresh.svg";
// import export_img from "../../icons/export.png";
// import export_img from "../../icons/export.svg";
import export_img from "../../icons/export.png";
import send_payment_btn from "../../icons/send_payment_btn.svg";
import { useContext, useState } from "react";
import { variableManager } from "../../context-api/VariableContex";
import SendPayment from "../modals/pay/SendPayment";
import ChooseMethod from "../modals/pay/ChooseMethod";
import { KycManager } from "../../context-api/KycContext";
import SendToBank from "../modals/pay/SendToBank";
import SendToUsername from "../modals/pay/SendToUsername";
import PayNow from "../modals/pay/PayNow";
import ContactList from "../modals/pay/ContactList";
import SelectMethod from "../modals/get_paid/SelectMethod";
import MongoroType from "../modals/get_paid/MongoroType";
import AccountNumber from "../modals/AccountNumber";
import PaySuccess from "../modals/pay/PaySuccess";
import PayError from "../modals/pay/PayError";
import Insufficient from "../modals/pay/Insufficient";
import EnterPin from "../modals/security/EnterPin"; 
export default function ActivitiesNav() {
  const { btnPay, dashboard } = useContext(variableManager);
  const [pay, setPay] = useState({ method: false });

  const {
    modalShowAccount,
    setModalShowAccount,
    payModalShow,
    setPayModalShow,
    showCreate,
    setShowCreate,
    exportStatement, setExportStatement,
  } = useContext(KycManager);

 
  return (
    <div className="activities-nav-container">
      {btnPay ? <h5>Pay</h5> : <h5>Activities</h5>}
      {btnPay && (
        <div className="send-payment-btn">
         
          <div
            className="image"
           
          >
             <button
              onClick={() =>
                setPayModalShow({ ...payModalShow, chooseMethod: true })
              }
             >Send Payment</button>
            {/* <img src={send_payment_btn} draggable="false" alt="send payment" /> */}
          </div>
        </div>
      )}
      <nav>
        <div className="select-search-section">
          <div className="select-status">
            <select name="status" id="">
              <option value="">Status</option>
            </select>
          </div>
          <div className="select-Pay">
            <select name="pay" id="">
              <option value="">Pay</option>
            </select>
          </div>

          <div className="search-section">
            <div className="input">
              <input type="search" placeholder="Search user name here" />
              <BiSearch />
            </div>
          </div>
        </div>

        <div className="buttons">
          {!btnPay ? (
            <div className="export">
          {
            dashboard.history.length?(
             <div onClick={()=>setExportStatement({...exportStatement, request:true})}> 
              <img
                src={export_img}
                className="pointer"
                draggable="false"
                alt="export"
              />
               
             </div>
            ):( 
              <img
                src={export_img}
                className="pointer"
                draggable="false"
                alt="export"
              /> 
            )
          }
            </div>
          ) : null}
          <div className="filter">
            <img src={filter} alt="filter" />
          </div>
          <div className="refresh">
            <img src={refresh} alt="refresh" />
          </div>
        </div>
      </nav>

      <div className="modals">
        <SendPayment
          show={pay.method}
          onHide={() => setPay({ ...pay, method: false })}
        />
        <ChooseMethod
          show={payModalShow.chooseMethod}
          onHide={() =>
            setPayModalShow({ ...payModalShow, chooseMethod: false })
          }
        />
        <SendToBank
          show={payModalShow.toBank}
          onHide={() => setPayModalShow({ ...payModalShow, toBank: false })}
          backdrop="static"
        />
        <SendToUsername
          show={payModalShow.toUsername}
          onHide={() => setPayModalShow({ ...payModalShow, toUsername: false })}
          backdrop="static"
        />

        <PayNow
          show={payModalShow.payNow}
          onHide={() => setPayModalShow({ ...payModalShow, payNow: false })}
          backdrop="static"
        />
        <ContactList
          show={payModalShow.chooseContact}
          onHide={() =>
            setPayModalShow({ ...payModalShow, chooseContact: false })
          }
          backdrop="static"
        />

        {/* RECEIVE PAYMENTS */}

        <SelectMethod
          show={payModalShow.receiveMethod}
          onHide={() =>
            setPayModalShow({ ...payModalShow, receiveMethod: false })
          }
        />

        <MongoroType
          show={payModalShow.mongoroType}
          onHide={() =>
            setPayModalShow({ ...payModalShow, mongoroType: false })
          }
        />

        <AccountNumber
          show={modalShowAccount}
          onHide={() => setModalShowAccount(false)}
        />
        <PaySuccess
          show={payModalShow.paySuccess}
          onHide={() => setPayModalShow({ ...payModalShow, paySuccess: false })}
        />
        <PayError
          show={payModalShow.payFail}
          onHide={() => setPayModalShow({ ...payModalShow, payFail: false })}
        />
        <Insufficient
          show={payModalShow.insufficient}
          onHide={() =>
            setPayModalShow({ ...payModalShow, insufficient: false })
          }
        />

        <EnterPin
          show={showCreate.enterPin}
          onHide={() => setShowCreate({ ...showCreate, enterPin: false })}
          backdrop={"static"}
        />
      </div>
    </div>
  );
}
