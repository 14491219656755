import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/modals.scss";
import add_photo from "../../icons/add_photo.png";
import { KycManager } from "../../context-api/KycContext";
import { variableManager } from "../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";
export default function KycIndemnity(props) {
  const {
    showTerms,
    setShowTerms,
    setShowIndemnity,
    setShowVeriSuccess,
    showVeriSuccess,
  } = useContext(KycManager);
  const { msg, user, handleIdentityUpload } = useContext(variableManager);
const {showIndemnity} = useContext(KycManager)
  const [accept, setAccept] = useState("");
  const [error, setError] = useState("");
 

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <h5 className="terms-modal-header">Indemnity</h5>
      </Modal.Header>
      <Modal.Body className="modalContent terms-body">
        <div className="p-3">
          <div className="mongoro-terms-headings">
            <div className="terms-applied fs-6">
              Terms and Conditions Applied
            </div>
          </div>

          <div className="mongoro-terms-content terms-body">
            I,{" "}
            <b>{`${user && user.firstName ? user.firstName : ""} ${
              user && user.surname ? user.surname : ""
            }`}</b>{" "}
            the undersigned user of this account hereby agree to indemnify and
            hold harmless the Company (Reef Financial Solutions Limited), its
            subsidiaries, officers and directors, employees and its affiliates
            and their respective successors and assigns, and each other person,
            if any, who controls any thereof, against any loss, liability, claim
            damage, and expense whatsoever (including, but not limited to, any
            and all expenses whatsoever reasonably incurred in investigating,
            preparing or defending against any litigation commenced or
            threatened or any claim whatsoever) arising out of or based upon any
            loss of funds or charges or failure to get the requisite value of
            the transaction by the undersigned resulting from a technical glitch
            or breach of warranty from the Company or her affiliated technology
            partners or network infrastructure to comply with any agreement made
            by the undersigned herein or in any other document furnished by the
            undersigned to any of the foregoing in connection with all
            transactions within the threshold of this category.
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="modal-footer-area">
        <div className="check">
            <input type="checkbox" /> <span> &nbsp; I have read through the Policy and I agree with the Terms and Conditions applied</span>
        </div>
       <br />
           <div className="button">
           <button>Accept</button> 
           </div>
      </Modal.Footer> */}

      <Modal.Footer className="footer">
        <div className="footer-area">
          <label className="checkbox" htmlFor="check">
            <input
              type="checkbox"
              id="check"
              onChange={(e) => {
                setAccept(e.target.checked);
                setError("");
              }}
            />{" "}
            I have read through the Policy and I accept the Terms and Conditions
            Applied
          </label>

          {error && <p className="mo-danger">{error}</p>}
          <div className="button">
            <button
              onClick={() => {
                if (accept) {
                  setError(""); 
                  console.log(user._id)
                  handleIdentityUpload({id:user._id}, "indemnity")
                } else {
                  setError(
                    "You need to accept this terms to upgrade to Tier 3"
                  );
                }
              }}
            >
             {
             showIndemnity && msg.spinner?(
                <Spinner size="sm" variant="light" />
              ):"Ok"
             }
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
