import "../../../styles/settings.scss"; 
import { useContext } from "react";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex"; 

export default function SecuritySettings() {
  const { navselect, setNavselect, profile, setProfile } =
    useContext(KycManager);
  const { user } = useContext(variableManager);





  return (
    <div className="settings-container">
      <div className="list-body">
        <div className="pt-4">
          <h5 className="mo-info">Verified Information</h5>

          <div className="biometrics">
            <div className="item">
              <div className="parameter">
                <b>Phone Number:</b>
                <p>Account Phone Number</p>
              </div>

              <div className="value">
                <p className="mo-blue ">{user.phone ? user.phone : ""}</p>
              </div>
            </div>

            {/* <div className="item">
              <div className="parameter">
                <b>Bank Verification Number (BVN)</b>
                <p>Input BVN</p>
              </div>

              <div className="value">
                <p className="mo-blue ">22235******</p>
              </div>
            </div> */}

            {/* <div className="item">
              <div className="parameter">
                <b>National Identity Number (NIN)</b>
                <p>Means of Identification</p>
              </div>

              <div className="value">
                <p className="mo-blue ">NIN34242424242</p>
              </div>
            </div> */}

            <b className="mo-info">Password, PIN and Activity</b>

            <div className="item">
              <div className="parameter">
                <b>Password</b>
                <p>Update existing password</p>
              </div>

              <div className="value">
                <p
                  className="mo-blue pointer"
                  onClick={() =>
                    setProfile({
                      ...profile,
                      changePassword: true,
                      selected: {
                        ...profile.selected,
                        changePassword: true,
                        resetPassword: false,
                      },
                    })
                  }
                >
                  Change Password
                </p>
              </div>
            </div>

            <div className="item">
              <div className="parameter">
                <b>PIN</b>
                <p>Update existing PIN</p>
              </div>

              <div className="value">
                <p
                  className="mo-blue pointer"
                  onClick={() => setProfile({ ...profile, changePin: true })}
                >
                  Change PIN
                </p>
              </div>
            </div>

            <div className="item">
              <div className="parameter">
                <b>Devices & Sessions</b>
                <p>See the devices you are currently logged into</p>
              </div>

              <div className="value">
                <p
                  className="mo-blue pointer"
                  onClick={() =>
                    setNavselect({
                      ...navselect,
                      loginActivity: true,
                      profile: false,
                      security: false,
                      notification: false,
                    })
                  }
                >
                  See your Login Activity
                </p>
              </div>
            </div>

            <p className="mo-info fw-bold">Termination</p>

            {/* <p className="pointer mo-danger">Temporarily disable my account</p> */}
            <p
              className="pointer mo-danger"
              onClick={() =>
                setProfile({
                  ...profile,
                  deleteAccount: {
                    ...profile.deleteAccount,
                    delete: true, 
                  },
                })
              }
            >
              Delete my Account
            </p>
          </div>
        </div>
      </div>

      <div className="modals"></div>
    </div>
  );
}
