import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, {useEffect, useState} from 'react';
import KycPrompt from "./KycPrompt";
import KycBvn from './KycBvn';
import KycNIN from './KycNIN';
import AccountNumber from './AccountNumber';
import KycPassport from './KycPassport';

export default function KycHere (){
    const [modalShow, setModalShow] = useState(false);
    const [modalShowBvn, setModalShowBvn] = useState(false);
    const [modalShowNin, setModalShowNin] = useState(false);
    const [modalShowPassport, setModalShowPassport] = useState(true);
    const [modalShowAccount, setModalShowAccount] = useState(false);

    useEffect(()=>{
        setModalShow(true)
    },[])

return (
  <>
    <Button variant="primary" onClick={() => setModalShow(true)}>
      kyc prompt
    </Button> <br />
     <Button variant="primary mt-4" onClick={() => setModalShowBvn(true)}>
      kyc bvn
    </Button>
    <br />
      <Button variant="primary mt-4" onClick={() => setModalShowNin(true)}>
      kyc nin
    </Button>
        <br />
      <Button variant="primary mt-4" onClick={() => setModalShowAccount(true)}>
     Account number
    </Button>

    <KycPrompt
      show={modalShow}
      onHide={() => setModalShow(false)} 
      backdrop={"static"}
    />
    
       {/* <KycNIN
      show={modalShowNin}
      onHide={() => setModalShowNin(false)} 
      backdrop={"static"}
    /> */}

    <KycBvn
     show={modalShowBvn}
     onHide={() => setModalShowBvn(false)} 
     backdrop={"static"}
    />
     {/* <AccountNumber
     show={modalShowAccount}
     onHide={() => setModalShowAccount(false)} 
     backdrop={"static"}
    /> */}
     <KycPassport
     show={modalShowPassport}
     onHide={() => setModalShowPassport(false)} 
     backdrop={"static"}
    />
  </>
);
}

