 import Modal from "react-bootstrap/Modal";
 import "../../../styles/settings.scss"; 
 import VerifyAccountForm from "../../forms/VerifyAccountForm";

export default function VerifyEmailSms(props) {
    return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent w-10 hide-on-modal">
        <VerifyAccountForm />
       </Modal.Body>
    </Modal>
  );
}
