import dara from "../../icons/pos-dara.svg";
import amal from "../../icons/pos-amal.svg";
import sade from "../../icons/pos-sade.svg";
import "../../styles/pos.scss";
import { useContext, useState } from "react";
import POSTerms from "../modals/pos/POSTerms";
import { KycManager } from "../../context-api/KycContext";
import ActivatePOS from "../modals/pos/ActivatePOS";
import RequestSuccess from "../modals/pos/RequestSuccess";
import ActivationSuccess from "../modals/pos/ActivationSuccess";
import RequestPOS from "../modals/pos/RequestPOS";

export default function POSList() {
  const { showPos, setshowPos} =
    useContext(KycManager);

  const [navselect, setNavselect] = useState({
    terminals: true,
    manage: false,
    transactions: false,
  });

  const [initPos] = useState({
    dara: false,
    sade: false,
    amal: false,
  });

  const [posSelect, setPosselect] = useState({
    ...initPos,
    dara: true,
  });
  return (
    <div className="activities-container">
      <h5>POS</h5>

      <div className="list-body">
        <nav>
          <button
            className={navselect.terminals ? " active-nav-btn" : "nav-btn"}
            onClick={(e) => {
              setNavselect({
                ...navselect,
                terminals: true,
                manage: false,
                transactions: false,
              });
            }}
          >
            POS Terminals
          </button>
          <button
            className={navselect.manage ? " active-nav-btn" : "nav-btn"}
            onClick={(e) =>
              setNavselect({
                ...navselect,
                manage: true,
                terminals: false,
                transactions: false,
              })
            }
          >
            Manage Terminals
          </button>
          <button
            className={navselect.transactions ? " active-nav-btn" : "nav-btn"}
            onClick={(e) =>
              setNavselect({
                ...navselect,
                transactions: true,
                manage: false,
                terminals: false,
              })
            }
          >
            Transactions
          </button>
        </nav>

        <div className="pos-body">
          <div className="activate-btn d-flex justify-content-end ">
            <button className="mo-active-bt">Activate POS Terminal</button>
          </div>

          <h5 className="mb-3">Select POS</h5>

          <div className="list-row">
            <div
              className={posSelect.dara ? "active-pos" : "pos"}
              onClick={() => {
                setPosselect({ ...initPos, dara: true });
              }}
            >
              <div className="image">
                <img src={dara} alt="mongoro dara p.o.s" />
              </div>
              <div className="details">
                <b>Mongoro Dara</b>
                <p>
                  Collect Card payments using the Mongoro Lite POS Terminals
                </p>
              </div>

              <div className="button">
                <button
                  // onClick={() => {
                  //   setshowPos({ ...showPos, request: true });
                  // }}
                >
                  {/* Request */}
                  Coming Soon
                </button>
              </div>
            </div>

            <div
              className={posSelect.sade ? "active-pos" : "pos"}
              onClick={() => {
                setPosselect({ ...initPos, sade: true });
              }}
            >
              <div className="image">
                <img src={sade} alt="mongoro dara p.o.s" />
              </div>
              <div className="details">
                <b>Mongoro Sade</b>
                <p>
                  Collect Card payments using the Mongoro Lite POS Terminals
                </p>
              </div>

              <div className="button">
                <button
                  // onClick={() => {
                  //   setshowPos({ ...showPos, request: true });
                  // }}
                >
                   {/* Request */}
                   Coming Soon
                </button>
              </div>
            </div>

            <div
              className={posSelect.amal ? "active-pos" : "pos"}
              onClick={() => {
                setPosselect({ ...initPos, amal: true });
              }}
            >
              <div className="image">
                <img src={amal} alt="mongoro dara p.o.s" />
              </div>
              <div className="details">
                <b>Mongoro Amal</b>
                <p>
                  Collect Card payments using the Mongoro Lite POS Terminals
                </p>
              </div>

              <div className="button">
                <button
                  // onClick={() => {
                  //   setshowPos({ ...showPos, request: true });
                  // }}
                >
                   {/* Request */}
                   Coming Soon
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modals">
        <RequestPOS
          show={showPos.request}
          onHide={() => setshowPos({ ...showPos, request: false })}
          backdrop={"static"}
        />

        <POSTerms
          show={showPos.terms}
          onHide={() => setshowPos({ ...showPos, terms: false })}
          backdrop={"static"}
          // scrollable=true
        />
        <ActivatePOS
          show={showPos.terminal_id}
          onHide={() => setshowPos({ ...showPos, terminal_id: false })}
          backdrop={"static"}
          // scrollable=true
        />
        <RequestSuccess
          show={showPos.request_success}
          onHide={() => setshowPos({ ...showPos, request_success: false })}
          // backdrop={"static"}
          // scrollable=true
        />

        <ActivationSuccess
          show={showPos.activate_success}
          onHide={() => setshowPos({ ...showPos, activate_success: false })}
          // backdrop={"static"}
          // scrollable=true
        />
      </div>
    </div>
  );
}
