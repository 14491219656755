import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import pinsuccess from "../../../icons/pinsuccess.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";

export default function CreatePinSuccess(props) {
  const { setShowCreate, showCreate, profile, setProfile } = useContext(KycManager);

  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() => {setShowCreate({ ...showCreate, pinSuccess: false }); setProfile({...profile, pinSuccess:false, passwordSuccess:false})} }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <center>
            <div className="icon w-25">
              <img
                src={pinsuccess}
                alt="Pin Creation Successful"
                className="img-fluid"
              />
            </div>
            {profile.pinSuccess ? (
              <div className="detail">
                <h3> PIN Change Successful</h3>
                <p> PIN Successfully Changed</p>
              </div>
            ) : profile.passwordSuccess? (
              <div className="detail">
                <h3> Password Change Successful</h3>
                <p> Password Successfully Changed</p>
              </div>
            ): (
              <div className="detail">
                <h3>Transaction PIN Created</h3>
                <p>Transaction PIN Created Successfully</p>
              </div>
            )}
          </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}
