import UploadImageForm from "../components/forms/UploadImageForm";
import SideFrame from "../components/signup/SideFrame";
import signup_frame from "../images/signup-frame.png" 

export default function UploadImage(){
    return(
        <div className="create-account-container upload-image-containe">
        {/* <div className="background-area">
            <img src={signup_frame} alt="Best solutions" />
        </div>  */}

        {<SideFrame />}  
        {<UploadImageForm />}  

    </div>
    )
}