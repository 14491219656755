 import { Navigate, Outlet } from "react-router-dom"; 
 
function PrivateLoginRoute() { 
 
 const localUser = localStorage.getItem("mg-u-tkn"); 
 return !localUser ? <Outlet /> : Navigate({to:"/user/dashboard", replace:true});

}

export default PrivateLoginRoute;
