import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../../../context-api/KycContext";
import "../../../../styles/modals.scss";
import "../../../../styles/bills.scss";
import { variableManager } from "../../../../context-api/VariableContex";

// import dstv from "../../../../icons/dstv.svg";
import dstv from "../../../../icons/dstv.png";
import gotv from "../../../../icons/gotv.png";
// import gotv from "../../../../icons/gotv.svg";
// import startimes from "../../../../icons/startimes.svg";
import startimes from "../../../../icons/startimes.png";
import startimeOn from "../../../../icons/startimes_on.svg";
// import startimeOn from "../../../../icons/startimeOn.webp";
// import showmax from "../../../../icons/showmax.svg";
import showmax from "../../../../icons/showmax.png";
import select_contact from "../../../../icons/select_contact.svg";
import { RenameFile } from "../../../../utils/RenameFiles";
import { AiFillCheckCircle } from "react-icons/ai";
import { HandleSelected } from "../../../../utils/HandleSelected";

export default function ElectricityDetails(props) {
  const { toverify, handleBillStatusChange, selectedBillProvider, setSelectedBillProvider, } = useContext(KycManager);
  const { user, msg } = useContext(variableManager);
  const [saveBeneficiary, setSaveBeneficiary] = useState(false);

 
  // handleSelection("cable")

  function handleChange(e) {
    const value =
      e.target.type === "file"
        ? RenameFile(
            e.target.files[0],
            `${user.firstName.toLowerCase()}${user.surname.toLowerCase()}`
          )
        : e.target.value;
    // setTicketDetails({ ...ticketDetails, [e.target.name]: value });
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Electricity</h2>

        <div className="transaction-fee">+ ₦25 Transaction Fee</div>

        <div className="request-pos-container cableTv-container">
         
      
          <div className="input-area">
            <div className="label">
              <label htmlFor="option">Select Provider</label>
            </div>
            <div className="input">
              <select
                name="provider"
                id="option"
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <option value="aedc">Abuja Electricity</option>
                <option value="ladc">Lagos Electricity</option>
                <option value="data">GoTV-Yanga</option>
              </select>
            </div>
          </div>
          <div className="input-area">
              <div className="label">
                <label htmlFor="phoneNumber">Meter Number</label>
              </div>
              
              <div className="input d-flex gap-2">
                <input type="text" id="phoneNumber" placeholder="4345453232445543" />
                <div
                  className="select-contact"
                  onClick={() =>
                    handleBillStatusChange("global", "chooseBeneficiary")
                  }
                >
                  <img
                    src={select_contact}
                    draggable="false"
                    className="pointer"
                    alt="select contact"
                  />
                </div>
              </div>
              <p>
                {" "}
                <AiFillCheckCircle className="mo-success" /> John Doe
              </p>
            </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="option">Select Provider</label>
            </div>
            <div className="input">
              <select
                name="package"
                id="option"
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <option value="prepaid">Prepaid</option>
                <option value="postpaid">Postpaid</option> 
              </select>
            </div>
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="quantity">Amount</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="quantity" 
                name="amount"
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="3,500"
                
              />
            </div>
          </div>



          <div className="checkbox-area">
            <label htmlFor="saveBeneficiary">
              <input
                type="checkbox"
                name="saveBeneficiary"
                id="saveBeneficiary"
                onChange={(e) => {
                  handleChange(e);
                  setSaveBeneficiary(!saveBeneficiary);
                }}
              />{" "}
              Save Beneficiary
            </label>
          </div>
        </div>

        {saveBeneficiary && (
          <div className="input-area">
            <div className="label">
              <label htmlFor="beneficiary_name">Beneficiary Name</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="beneficiary_name"
                name="beneficiaryName"
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="my home meter"
              />
            </div>
          </div>
        )}

        <div className="buttons">
          <button
            className={toverify.bvn ? "btn-active" : "btn-active"}
            onClick={() => {
              // handleFieldValidation();
              handleBillStatusChange("electricity", "paymentMethod");
            }}
            disabled={msg.spinner ? "{true}" : ""}
          >
            {msg.spinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Pay"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
