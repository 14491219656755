import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../../context-api/KycContext";
import "../../../styles/modals.scss";
import { variableManager } from "../../../context-api/VariableContex";
export default function CreateTicket(props) {
  const { toverify, ticketDetails, setTicketDetails } =
    useContext(KycManager);
  const { user, msg, handleCreateTicket } = useContext(variableManager);
  const [empty, setEmpty] = useState(false); 

 
  function handleFieldValidation() {
    if (
      ticketDetails.title &&
      ticketDetails.category &&
      ticketDetails.description
    ) {
      handleCreateTicket(ticketDetails);
    } else {
      setEmpty(true);
    }
  }

  function handleChange(e) { 
    const value =
      e.target.type === "file"
        ? RenameFile(
            e.target.files[0],
            `${user.firstName.toLowerCase()}${user.surname.toLowerCase()}`
          )
        : e.target.value;
    setTicketDetails({ ...ticketDetails, [e.target.name]: value });
  }

  function RenameFile(file, userName) {
    const fileParts = file.name.split(".");
    const fileType = fileParts.pop();
    const newName = `${userName}_${fileParts.join(".")}.${fileType}`;
    return new File([file], newName, { type: file.type });
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Create New Ticket</h2>
        <div className="request-pos-container">
          <div className="input-area">
            <div className="label">
              <label htmlFor="businessName">Username</label>
            </div>
            <div className="input">
              <input
                type="text"
                disabled
                id="businessName"
                placeholder="Enter Business Name"
                value={user.walletTag}
                style={{ backgroundColor: "#EFF3BE" }}
              />
            </div>
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="address">Category</label>
            </div>
            <div className="input">
              <select
                name="category"
                id="" 
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <option value={ticketDetails.category || ""}>{ticketDetails.category || "Select Category"}</option>
                <option value="bug">Bug</option>
                <option value="deposit">Deposit</option>
                <option value="login">Login Issue</option>
                <option value="pos">POS</option>
                <option value="profile">Profile</option>
                <option value="security">Security</option>
                <option value="Transaction History">Transaction History</option>
                <option value="Utility Bills">Utility Bills</option>
                <option value="withdrawal">Withdrawal</option>
                <option value="withdrawal">Others</option>
              </select>
            </div>

            {empty && ticketDetails.category === "" ? (
              <div className="mo-danger">Ticket category is required</div>
            ) : null}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="title">Title</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="title"
                name="title"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            {empty && ticketDetails.title === "" ? (
              <div className="mo-danger">Ticket title is required</div>
            ) : null}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="state">Upload Document</label>
            </div>
            <div className="input">
              <input
                type="file"
                id="state"
                name="docs"
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder=""
              />
            </div>
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="quantity">Amount</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="quantity"
                name="amount"
                value={ticketDetails.amount}
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="000, 0000.00"
              />
            </div>
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="description">Description</label>
            </div>
            <div className="input">
              <textarea
                id="description"
                name="description"
                onChange={(e) => {
                  handleChange(e);
                }}
                cols="30"
                rows="5"
              ></textarea>
            </div>
          </div>
          {empty && ticketDetails.description === "" ? (
            <div className="mo-danger">Description is required</div>
          ) : null}
        </div>
 

        <div className="buttons">
          <button
            className={toverify.bvn ? "btn-active" : "btn-active"}
            onClick={() => {
              handleFieldValidation(); 
            }}
            disabled={msg.spinner ? "{true}" : ""}
          >
            {msg.spinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Create New Ticket"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
