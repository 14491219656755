import "../../styles/notifications.scss";
import bell from "../../icons/bigbell.svg";

export default function EmptyView() {
  return (
    <div className="notification-modal-container">
      <h5>Notifications</h5>

      {/* <nav>
        <button>All</button>
        <button>Activity</button>
        <button>Messages</button>
      </nav> */}

      <div className="body">
        <center className="empty-body">
          <div className="image">
            <img src={bell} alt="" />
          </div>

          <h5>Nothing to see here</h5>
          <p>Your update notification will appear here</p>
        </center>
      </div>
    </div>
  );
}
