import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/modals.scss";
import "../../../styles/bills.scss";
import { IoIosArrowBack } from "react-icons/io";
import { KycManager } from "../../../context-api/KycContext";
import { 
  recharge,
} from "../../../features/bills_payment/RechargeAirtime";
import { rechargeData } from "../../../features/bills_payment/RechargeData";
import { Spinner } from "react-bootstrap";
import { variableManager } from "../../../context-api/VariableContex";
import { rechargeCableTv } from "../../../features/bills_payment/RechargeCableTv";
import { saveBeneficiary } from "../../../service_workers/SaveBillBeneficiary";
export default function ConfirmPin(props) {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [verify, setVerify] = useState(new Array(6).fill(""));
  const { showBill, handleBillStatusChange, billPayload, setBillPayload } =
    useContext(KycManager);
  const { msg, setMsg, handleGetUser, } = useContext(variableManager);
  const handleChange = (element, index, toMap) => {
    if (isNaN(element.value)) return false;

    if (toMap === "email") {
      setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    } else if (toMap === "verify") {
      setVerify([
        ...verify.map((d, idx) => (idx === index ? element.value : d)),
      ]);
    }
    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const handleRecharge = async (obj) => {
    setMsg({ ...msg, spinner: true });
    const { data, error } = await recharge(obj);
    if (error) {
      handleBillStatusChange("resetAll");
      handleBillStatusChange("status", "error");
      setMsg({ ...msg, spinner: false });
    } else {
      setMsg({ ...msg, spinner: false });
      setBillPayload({ ...billPayload, response: data });
      handleBillStatusChange("resetAll");
      handleBillStatusChange("status", "success");

      if (obj.saveBeneficiary) {
        const { data, error } = await saveBeneficiary({
          digits: obj.customer,
          beneficiaryName: obj.beneficiaryName,
          type:"1"
        });
 
      }
      
      handleGetUser();
    }
  };
  const handleRechargeCableTv = async (obj) => {
    setMsg({ ...msg, spinner: true });
    const { data, error } = await rechargeCableTv(obj);
    if (error) {
      setMsg({ ...msg, spinner: false });
      handleBillStatusChange("resetAll");
      handleBillStatusChange("status", "error");
    } else {
      setMsg({ ...msg, spinner: false });
      setBillPayload({ ...billPayload, response: data });
      handleBillStatusChange("resetAll");
      handleBillStatusChange("status", "success");

      if (obj.saveBeneficiary) {
        const { data, error } = await saveBeneficiary({
          digits: obj.customer,
          beneficiaryName: obj.beneficiaryName,
          type:"2"
        });
 
      }

      setBillPayload({
        ...billPayload,
        cableTv: {
          ...billPayload.cableTv,
          code: "",
          itemCode: "",
          amount: "",
          type: "",
          validCustomer: "",
          customer: "",
          beneficiaryName:"",
          saveBeneficiary:false,
        },
      });

      handleGetUser();
    }
  };
  const handleRechargeData = async (obj) => {
    setMsg({ ...msg, spinner: true });
    const { data, error } = await rechargeData(obj);
    if (error) {
      setMsg({ ...msg, spinner: false });
      handleBillStatusChange("resetAll");
      handleBillStatusChange("status", "error");
    } else {
      setMsg({ ...msg, spinner: false });
      setBillPayload({ ...billPayload, response: data });
      handleBillStatusChange("resetAll");
      handleBillStatusChange("status", "success");

      if (obj.saveBeneficiary) {
        const { data, error } = await saveBeneficiary({
          digits: obj.customer,
          beneficiaryName: obj.beneficiaryName,
          type:"1"
        });
 
      }

      handleGetUser();
    }
  };
  
  useEffect(() => {
    setBillPayload((prevBillPayload) => ({
      ...prevBillPayload,
      airtimeData: { ...prevBillPayload.airtimeData, pin: otp.join("") },
      cableTv: { ...prevBillPayload.cableTv, pin: otp.join("") },
    }));
  }, [otp]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // scrollable="false"
    >
      <Modal.Header
        closeButton
        onHide={() => {
          setOtp(new Array(6).fill(""));
        }}
      ></Modal.Header>
      <Modal.Body className="modalContent">
        <h2 className="vertical-center pointer">
          {" "}
          <IoIosArrowBack
            onClick={() => {
              handleBillStatusChange("switchOff");
              setOtp(new Array(6).fill(""));
            }}
          />{" "}
          Enter your PIN to Confirm{" "}
        </h2>

        <div className="vertical-center">
          <div className="content verify-account-form-container w-100 pin-container">
            <div className="email-sms-otp">
              <div className="email-otp">
                {/* <p className="mt-3">Create PIN</p> */}
                <div className="otp-box mb-4">
                  {otp.map((data, index) => {
                    return (
                      <input
                        className="otp-field"
                        type="password"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={(e) => {
                          handleChange(e.target, index, "email");
                        }}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={(e) => {
                          if (e.keyCode === 8) {
                            // handleDelete(e, index)
                          } else if (e.key === "ArrowLeft") {
                            e.target.previousSibling.focus();
                          } else if (e.key === "ArrowRight") {
                            e.target.nextSibling.focus();
                          }
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            {otp.join("").length === 6 ? (
              <button
                className="mo-active-btn"
                onClick={() => {
                  if (showBill.actionFrom.airtime) {
                    if (billPayload.airtimeData.type === "Airtime") {
                      handleRecharge(billPayload.airtimeData);
                      setOtp(new Array(6).fill(""));
                    } else {
                      handleRechargeData(billPayload.airtimeData);
                      setOtp(new Array(6).fill(""));
                    }
                  } else if (showBill.actionFrom.cableTv) {
                    const { validCustomer, ...cablePayload } =
                      billPayload.cableTv;
                    handleRechargeCableTv(cablePayload);
                    setOtp(new Array(6).fill(""));
                  }
                }}
              >
                {msg.spinner ? (
                  <Spinner size="sm" variant="light" />
                ) : (
                  " Confirm"
                )}
              </button>
            ) : (
              <button className="mo-inactive-btn">Confirm</button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
