 
import React, { useContext, useState } from "react"
import signup_frame from "../images/signup-frame.svg"
import SignupForm from "../components/forms/SignupForm"
import MongoroTerms from "../components/forms/MongoroTerms"
import { KycManager } from "../context-api/KycContext"
import SideFrame from "../components/signup/SideFrame"

export default function Signup(){
    const {showTerms, setShowTerms} = useContext(KycManager)
    return (
        <div className="create-account-container">
         <SideFrame/>
 
        {<SignupForm />}  


        <MongoroTerms
        show={showTerms}
        onHide={()=>setShowTerms(false)}
        />
    </div>
    )
}