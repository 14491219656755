import Navigation from "./Navigation";
import Sidebar from "./Sidebar";
import "../../styles/dashboard.scss";
import "../../styles/sidebar.scss";
import VerificationStatus from "./VerificationStatus";
import AccountBalance from "./AccountBalance";
import TransactionHistory from "./TransactionHistory";
import Footer from "./Footer";
import PaymentsNavigation from "./PaymentsNavigation";
import { useContext, useEffect } from "react";
import KycPrompt from "../modals/KycPrompt";
import KycNIN from "../modals/KycNIN";
import KycBvn from "../modals/KycBvn";
import AccountNumber from "../modals/AccountNumber";
 import KycPassport from "../modals/KycPassport";
import SelectMeans from "../modals/SelectMeans";
import BvnSuccess from "../modals/BvnSuccess"; 
import CompleteSetup from "../modals/CompleteSetup";
import { variableManager } from "../../context-api/VariableContex";
import { KycManager } from "../../context-api/KycContext";
import AccountType from "../modals/accounts/AccountType";
import UploadImage from "../modals/settings/UploadImage";
import axiosInstance from "../../utils/axios_instance";
export default function Dashboard() {  
  const { 
    handleTransactionHistory, 
    handleInsights,
    handleNotifications,
    handleLoginActivity,
    handleBankList,
  } = useContext(variableManager);
  const {
    modalShowPrompt,
    modalShowCompletesetup,
    setModalShowCompletesetup,
    modalShowBvn,
    modalShowNin,
    modalShowPassport,
    modalShowAccount,
    modalShowAccountType,
    modalShowSelectMethod,
    setModalShowPrompt,
    setModalShowBvn,
    setModalShowNin,
    setModalShowAccount,
    setModalShowAccountType,
    setModalShowPassport,
    setModalShowSelectMethod,

    showVeriSuccess,
    setShowVeriSuccess,
 

    profile,
    setProfile,
    showSidebar,
    setShowSidebar,
    view,
    setView,
  } = useContext(KycManager);

  useEffect(() => {
    const localUser = localStorage.getItem("mg-u-tkn");
    axiosInstance.defaults.headers.common["x-token"] = localUser;
    handleInsights();
    handleNotifications();
    handleLoginActivity("get");
    handleTransactionHistory();
    handleBankList();
  }, []);

  return (
    <div
      className="dashboard-container"
      onClick={() => setView({ ...view, notifications: false })}
    >
      <div className="navigation-section">
        <Navigation />
      </div>
      <div className="body-section"> 
        <div className={showSidebar ? "sidebar" : "  hide-sidebar "}>
          <Sidebar />
        </div>
        <div
          className="dashboard-body"
          onClick={() => {
            setShowSidebar(false);
          }}
        >
          <VerificationStatus /> 

          <AccountBalance />
          <PaymentsNavigation />
          <TransactionHistory />
          <Footer />
        </div>
      </div>

      <div className="modals">
        <CompleteSetup
          show={modalShowCompletesetup}
          onHide={() => setModalShowCompletesetup(false)}
          backdrop={"static"}
        />

        <UploadImage
          show={profile.imageUpload}
          onHide={() => setProfile({ ...profile, imageUpload: false })}
        />

        <KycPrompt
          show={modalShowPrompt}
          onHide={() => setModalShowPrompt(false)}
          backdrop={"static"}
        />

        <KycNIN
          show={modalShowNin}
          onHide={() => setModalShowNin(false)}
          backdrop={"static"}
        />

        <KycBvn
          show={modalShowBvn}
          onHide={() => setModalShowBvn(false)}
          backdrop={"static"}
        />
        <AccountType
          show={modalShowAccountType}
          onHide={() => setModalShowAccountType(false)}
          backdrop={"static"}
        />

        <AccountNumber
          show={modalShowAccount}
          onHide={() => setModalShowAccount(false)}
          backdrop={"static"}
        />

        <KycPassport
          show={modalShowPassport}
          onHide={() => setModalShowPassport(false)}
          backdrop={"static"}
        />

        <SelectMeans
          show={modalShowSelectMethod}
          onHide={() => setModalShowSelectMethod(false)}
          backdrop={"static"}
        />

        {/* // VERIFICATIONS */}

        <BvnSuccess
          show={showVeriSuccess.bvnSuccess}
          onHide={() =>
            setShowVeriSuccess({ ...showVeriSuccess, bvnSuccess: false })
          }
          backdrop={"static"}
        />

        
      </div>
    </div>
  );
}
