import { useContext } from "react";
import Navigation from "../components/dashboard/Navigation";
import Sidebar from "../components/dashboard/Sidebar";
import TicketsList from "../components/dashboard/support/TicketsList";
import { KycManager } from "../context-api/KycContext";
import "../styles/support.scss"
import TicketStatusSummary from "../components/dashboard/support/TicketStatusSummary";

export default function Support(){
    const {showSidebar} = useContext(KycManager) 
    return (
        <div className="dashboard-container">
          <div className="navigation-section">
            <Navigation />
          </div>
          <div className="body-section">
            <div className={showSidebar?"sidebar":"hide-sidebar"}>
              <Sidebar />
            </div>
    
            <div className="dashboard-body">
            <h5>Tickets</h5>
           
           <TicketStatusSummary/>
           <TicketsList/>
            </div>
          </div>
    
    
    
    
    
    
    
    
    
    
          
    
          <div className="modals">
    
            
         
          </div>
        </div>
      );
}