import axiosInstance from "../utils/axios_instance";

export async function saveBeneficiary(payload){
 
    let data = "";
    let error = "";
  
    try {
      await axiosInstance.post("/user/beneficiary/bills", payload).then((resp) => {
        console.log(resp)
        data = resp.data.data; 

      });
    } catch (err) {
        console.log(error)
      error= err;
    }
  
    return {data,error}
}