 
import "../styles/dashboard.scss"  
import Footer from "../components/dashboard/Footer";
import Sidebar from "../components/dashboard/Sidebar";
import Navigation from "../components/dashboard/Navigation";
import ActivitiesNav from "../components/dashboard/ActivitiesNav";
import Activities from "../components/dashboard/Activities";
import { useContext } from "react";
import { KycManager } from "../context-api/KycContext";
export default function Activites() {
    const {showSidebar, setShowSidebar} = useContext(KycManager)
     
    return (
        <div className="dashboard-container">
            <div className="navigation-section">
                <Navigation />
            </div>

            <div className="body-section">
                <div className={showSidebar?"sidebar":"hide-sidebar"}>
                    <Sidebar />
                </div>
                <div className="dashboard-body" onClick={()=>setShowSidebar(false)}>
                    <ActivitiesNav />
                    <Activities />  
                    <Footer />

                </div>

            </div>


            <div className="modals">
                {/* <KycPrompt
                    show={modalShowPrompt}
                    onHide={() => setModalShowPrompt(false)}
                    backdrop={"static"}
                />

                <KycNIN
                    show={modalShowNin}
                    onHide={() => setModalShowNin(false)}
                    backdrop={"static"}
                />

                <KycBvn
                    show={modalShowBvn}
                    onHide={() => setModalShowBvn(false)}
                    backdrop={"static"}
                />
                <AccountNumber
                    show={modalShowAccount}
                    onHide={() => setModalShowAccount(false)}
                    backdrop={"static"}
                /> */}
            </div>
        </div>
    )
}