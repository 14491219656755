// import { useContext, useState } from "react";
// import Modal from "react-bootstrap/Modal";
// import success from "../../../icons/success.svg";
// import close from "../../../icons/close.svg";
// import "../../../styles/modals.scss";
// import { KycManager } from "../../../context-api/KycContext";
// export default function BillSuccess(props) {
//   const { showBill, setShowBill,  } =
//     useContext(KycManager);
//   return (
//     <div className="sendpayment">
//       <Modal
//         {...props}
//         size="md"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header>
//           <div
//             className="modal-header pointer"
//             onClick={() =>
//                 setShowBill({
//                     ...showBill,
//                     status: { ...showBill.status, success: false },
//                   })
//             }
//           >
//             <img draggable="false" src={close} alt="close" />
//           </div>
//         </Modal.Header>
//         <Modal.Body className="modalContent">
//           <center>
//             <div className="icon">
//               <img src={success} alt="transfer successful" />
//             </div>
//             <div className="detail">
//                 <h3>Successful Transaction</h3>
//                 {/* <p>Transaction Reference: {payModalShow.transaction ?payModalShow.transaction.ref:null}</p>
//                 <p>Transaction ID: {payModalShow.transaction? payModalShow.transaction.id:null}</p> */}
//             </div>
//           </center>
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// }


import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import processed_by from "../../../icons/processed_by_btn.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import "../../../styles/bills.scss";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import { nameCase } from "../../../utils/formatName";
import { formatAmount } from "../../../utils/formatAmount";
export default function BillSuccess(props) {
  const { showBill, setShowBill, handleBillStatusChange, billPayload } =
    useContext(KycManager);
  const { user } = useContext(variableManager);
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() => handleBillStatusChange("resetAll")}
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent success-container">
          <div className="banner-section">
            <div className="amount">
            NGN {billPayload.response && billPayload.response.amount && formatAmount(billPayload.response.amount.toString())}
            </div>
            <div className="status">Success</div>
          </div>

          <div className="success-body">
            <div className="item reference">
              <div className="title ">Reference</div>
              <div className="value">{billPayload.response.reference}</div>
            </div>

            <div className="item ">
              <div className="title ">Channel</div>
              <div className="value">Wallet</div>
            </div>

            <div className="item ">
              <div className="title ">Receiver Detail</div>
              <div className="value reference">Mongoro</div>
            </div>

            <div className="item ">
              <div className="title ">Phone Number</div>
              <div className="value">{billPayload.response.customer}</div>
            </div>

            <div className="item ">
              <div className="title ">Amount</div>
              <div className="value">
              NGN {billPayload.response && billPayload.response.amount && formatAmount(billPayload.response.amount.toString())}
              </div>
            </div>

            <div className="item ">
              <div className="title ">Paid At</div>
              <div className="value">
                {billPayload.response.updatedAt
                  ? new Date(billPayload.response.updatedAt).toLocaleString()
                  : ""}
              </div>
            </div>

            <div className="item ">
              <div className="title ">Purpose of Payment</div>
              <div className="value reference">AIRTIME</div>
            </div>

            <hr className="mt-5 mb-3" />
            <div className="item">
              <div className="sender-profile">
                <div className="icon">
                  <img src={user.image} alt="user avatar" />
                </div>

                <div className="details">
                  <b>
                    {nameCase(user.firstName)} {nameCase(user.surname)}
                  </b>
                  <div className="email">{user.email}</div>
                </div>
              </div>
              <div className="debited-account">
                <b>{user.Accounts ? user.Accounts[0].accountNumber : ""}</b>{" "}
                Mongoro
              </div>
            </div>
            <hr className="mt-3 mb-3" />
          </div>

          <center>
            {/* <button className="download-btn">Download Receipt</button> */}

            <div className="processed-by-btn mt-3 mb-1">
              <img src={processed_by} draggable="false" alt="processed by Flutterwave" />
            </div>

            <div className="powered-by-mongoro">Powered By Mongoro</div>
          </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}

