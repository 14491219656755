import "../styles/dashboard.scss";
import Sidebar from "../components/dashboard/Sidebar";
import Navigation from "../components/dashboard/Navigation";
import AllNotifications from "../components/notification/AllNotifications";
import { useContext } from "react";
import { KycManager } from "../context-api/KycContext";

export default function Notifications() {
  const {showSidebar} = useContext(KycManager)
  return (
    <div className="dashboard-container">
      <div className="navigation-section">
        <Navigation />
      </div>

      <div className="body-section">
        <div className={showSidebar?"sidebar":"hide-sidebar"}>
          <Sidebar />
        </div>
        <div className=" dashboard-body other-body">
         
         
          <AllNotifications />
        </div>
      </div>

      <div className="modals"></div>
    </div>
  );
}
