import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../context-api/KycContext";
import "../../styles/modals.scss";
import add_photo from "../../icons/add_photo.png";
export default function SelectMeans(props) {
  const {
    setModalShowNin,
    setModalShowPvc,
    setModalShowPassport,
    setModalShowSelectMethod,
    setModalShowDriversLicense,
  } = useContext(KycManager);
  const [means, setMeans] = useState("");
  // {nin:"", passport:"", driver:"", voter:""}

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onHide={() => setMeans("")}></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Select Means of Identification</h2>
        <p className="small-p">Kindly select a means of identification</p>

        <div className="input-area">
          <div className="label">
            <label htmlFor="id_type">I.D Type</label>
          </div>
          <div className="input">
            <select
              name="id_type"
              id="id_type"
              onChange={(e) => setMeans(e.target.value)}
            >
              <option value="">Select I.D Type</option>
              <option value="passport">International Passport</option>
              <option value="nin">National ID (NIN)</option>
              <option value="driversLicense">Driver's license </option>
              <option value="pvc">Voter's card (PVC)</option>
            </select>
          </div>
        </div>

        <div className="buttons">
          <button
            className={means ? "btn-active" : "btn-inactive"}
            onClick={() => {
              if (means === "nin") {
                setModalShowSelectMethod(false);
                setModalShowNin(true);
                setMeans("");
              } else if (means === "passport") {
                setModalShowSelectMethod(false);
                setModalShowPassport(true);
                setMeans("");
              } else if (means === "driversLicense") {
                setModalShowSelectMethod(false);
                setModalShowDriversLicense(true);
                setMeans("");
              } else if (means === "pvc") {
                setModalShowSelectMethod(false);
                setModalShowPvc(true);
                setMeans("");
              }
            }}
          >
            Confirm
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
