import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { variableManager } from "../../context-api/VariableContex";
import account_success from "../../images/account_success.png";
export default function CreationSuccess() {
  const navigate = useNavigate();

  const {user, handleLoginActivity} = useContext(variableManager)

  useEffect(() => {
    // handleLoginActivity(localStorage.getItem("mongoro_user"), "add")
    setTimeout(() => {
      // if (!user) {
      //   navigate("/login");
      // } else {
        navigate("/user/dashboard");
      // }
    }, 3000);
  }, []);
  return (
    <div className="account-success-container">
      <center className="content">
        <div className="image">
          <img src={account_success} alt="Account ccreation success" />
        </div>
        <div className="text">
          <h2>Account successfully created</h2>
          <p>
            Your Mongoro Pin is a fast, secure and easy way to sign in to your
            account and to authorize all your transactions
          </p>
        </div>
      </center>
    </div>
  );
}
