import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { variableManager } from "../../../context-api/VariableContex";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext"; 
import { validEmail } from "../../regex/Regex";
export default function ExportStatement(props) {
  const { msg, user, handleGenerateStatement } = useContext(variableManager);

  const { exportStatement, setExportStatement } = useContext(KycManager);
  const [empty, setEmpty] = useState(false);
  const [formatError, setFormatError] = useState("");

 

  function handleValidation() {
    if (
      exportStatement.body.startDate &&
      exportStatement.body.endDate &&
      exportStatement.body.email
    ) {
      if (!validEmail.test(exportStatement.body.email)) { 
        setFormatError("Invalid email address");
      } else { 
        handleGenerateStatement(exportStatement.body);
      }
    } else {
      setEmpty(true);
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        onHide={() => {
          setExportStatement({
            ...exportStatement,
            request: false,
            body: {
              ...exportStatement.body,
              endDate: "",
              startDate: "",
              email: "", 
            },
          });

          setFormatError("");
          setEmpty(false);
        }}
      ></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Export Statement</h2>

        <div className="input-area">
          <div className="label">
            <label htmlFor="startDate">
              Start Date<sup>*</sup>
            </label>
          </div>
          <div className="input">
            <input
              type="date"
              id="startDate"
              onChange={(e) =>
                setExportStatement({
                  ...exportStatement,
                  body: {
                    ...exportStatement.body,
                    startDate:new Date(e.target.value).getTime(), 
                    email:user.email
                  },
                })
              }
            />
            {empty && exportStatement.body.startDate === "" ? (
              <p className="mo-danger">This field is required</p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="input-area">
          <div className="label">
            <label htmlFor="endDate">
              End Date<sup>*</sup>
            </label>
          </div>
          <div className="input">
            <input
              type="date"
              id="endDate"
              onChange={(e) =>
                setExportStatement({
                  ...exportStatement,
                  body: {
                    ...exportStatement.body,
                    endDate: new Date(e.target.value).getTime(), 
                    email:user.email
                  },
                })
              }
            />
            {empty && exportStatement.body.endDate === "" ? (
              <p className="mo-danger">This field is required</p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="input-area">
          <div className="label">
            <label htmlFor="email">
              Email<sup>*</sup>
            </label>
          </div>
          <div className="input">
            <input
              type="email"
              id="email"
              placeholder="user@mail.com"
              onChange={(e) => {
                setExportStatement({
                  ...exportStatement,
                  body: {
                    ...exportStatement.body,
                    email: e.target.value.trim(), 
                  },
                });

                setFormatError("")
              }}
            />
            {empty && exportStatement.body.email === "" ? (
              <p className="mo-danger">This field is required</p>
            ) : (
              ""
            )}
          </div>
        </div>
        {formatError && <p className="error">{formatError}</p>}
        <div className="buttons">
          <button
            className="btn-active"
            disabled={msg.spinner ? "{true}" : ""}
            onClick={() => {
              handleValidation();
            }}
          >
            {msg.spinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Generate"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
