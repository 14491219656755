// export const validEmail = new RegExp(
//     '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
//  ); v1
export const validEmail = new RegExp(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

//  export const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');
//  export const validPassword = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"); //v1
export const validPassword = new RegExp(
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])(?!.*.{17}).{6,16}"
); //v2

// export const validUsername = new RegExp("^[a-zA-Z0-9@]{4,16}$");
export const validUsername = new RegExp('^.{3,16}$');

export const validName = new RegExp(
  "^[a-zA-Z\\u00C0-\\u00D6\\u00D8-\\u00F6\\u00F8-\\u01FF]+([ \\-']{0,1}[a-zA-Z\\u00C0-\\u00D6\\u00D8-\\u00F6\\u00F8-\\u01FF]+){0,2}[.]{0,1}$"
);

//  console.log(validPassword.test("@victoronoja1D"))^[a-zA-Z0-9]{4,8}$
