import Modal from "react-bootstrap/Modal";
import "../../../styles/modals.scss";
import eye from "../../../icons/eye.svg";
import { useContext, useState } from "react";
import { variableManager } from "../../../context-api/VariableContex";
import { KycManager } from "../../../context-api/KycContext";
import axiosInstance from "../../../utils/axios_instance";

export default function EnterPassword(props) {
  const { profile, setProfile } = useContext(KycManager);
  const { showPassword, setShowPassword, handleLogout } =
    useContext(variableManager);

  const [error, setError] = useState("");

  async function handleDeleteAccount(payload) { 
    if (payload.password) {
      try {
        await axiosInstance.post("/user/delete", payload).then(() => {
          setProfile({
            ...profile,
            deleteAccount: {
              ...profile.deleteAccount,
              success: true,
              enterPassword: false,
            },
          });
          setTimeout(() => {
            handleLogout();
          }, 1000);
        });
      } catch (error) {
        setError(error.response.message)
      }
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Enter your password to Confirm</h2>
        <p>Enter your Mongoro password to confirm action</p>

        <div className="">
          <div className="content verify-account-form-container w-100 pin-container">
            <div className="input-area">
              <label htmlFor="password">Password</label>
              <input
                type={showPassword.password ? "text" : "password"}
                onChange={(e) => {
                  {
                    setError("")
                    setProfile({
                      ...profile,
                      deleteAccount: {
                        ...profile.deleteAccount,
                        payload: {
                          ...profile.deleteAccount.payload,
                          password: e.target.value,
                        },
                      },
                    });

                  }
                }}
              />
              <img
                draggable="false"
                src={eye}
                className=" eye pointer"
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    password: !showPassword.password,
                  })
                }
                alt="view password"
              />
            </div>

            <b className="mo-danger">{error}</b>

            <button
              className="mo-active-btn"
              onClick={() => {
                if (profile.deleteAccount.payload.password) {
                  handleDeleteAccount(profile.deleteAccount.payload);
                } else {
                  setError("Please enter your password to delete account");
                }
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
