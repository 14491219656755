import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import insufficient from "../../../icons/insufficient.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";
export default function Insufficient(props) {
  const { payModalShow, setPayModalShow, initPayModal,  } =
    useContext(KycManager);
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>
              setPayModalShow({ ...payModalShow, insufficient: false })
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <center>
            <div className="icon" style={{width:"80px"}}>
              <img src={insufficient} alt="insufficient balance" className="img-fluid" />
            </div>
            <div className="detail">
                <h3>Insufficient Funds</h3>
                <p>Kindly fund wallet before transactions</p>
             </div>
          </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}
