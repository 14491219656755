import phone from "../../../icons/phone.svg";
import close from "../../../icons/close.svg";
import naira_sign from "../../../icons/naira_sign.svg";
import { useContext, useState } from "react";
import { variableManager } from "../../../context-api/VariableContex";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import "../../../styles/dashboard.scss";
import axiosInstance from "../../../utils/axios_instance";
import { KycManager } from "../../../context-api/KycContext";

export default function TicketDetails() {
  const { user, nameCase, tickets, handleFormatAmount, handleCloseTicket } =
    useContext(variableManager);
  const { ticket, setTicket } = useContext(KycManager);

  const [showBalance, setShowBalance] = useState(false);
  async function handleToggleShowBalance() {
    setShowBalance(!showBalance);

    try {
      await axiosInstance.post(`/user/balance`, {
        balance: !showBalance,
      });
    } catch (error) {
      setShowBalance(showBalance);
    }
  }

  return (
    <div className="ticket-details-container">
      <div className="header">
        <div className="id">
          ID Payment
          <p>{tickets.single.ticketId}</p>
        </div>
        <div className="contact">
          <div className="icon">
            <img src={phone} alt="phone" />
          </div>
          <div className="details">
            <p className="faint">PHONE</p>
            <b>+2348154799404</b>
          </div>
        </div>

        {tickets.single.status.toLowerCase() !== "closed" ? (
          <div className="ticket-status ">
            <div className="closed">
              {/* <input
                type="radio"
                checked={false}
                onChange={() => {
                  handleCloseTicket(tickets.single, tickets.single.tiid);
                }}
                id="closed"
              />
              <label className="pointer" htmlFor="closed">
                Close
              </label> */}
              <span className="close">
                <sup>
                  <img
                    draggable="false"
                    src={close}
                    alt="close"
                    className="pointer"
                    onClick={() =>
                      setTicket({
                        ...ticket,
                        detailsPage: false,
                        listPage: true,
                      })
                    }
                  />
                </sup>
              </span>
            </div>
          </div>
        ) : (
          <div className="ticket-status ">
            <div className="closed">
              <input
                type="radio"
                disabled
                checked
                id="closed"
                onChange={() => {
                  return true;
                }}
              />
              <label className="" htmlFor="closed">
                Closed
              </label>
              <span className="close">
                <sup>
                  <img
                    draggable="false"
                    src={close}
                    alt="close"
                    className="pointer"
                    onClick={() =>
                      setTicket({
                        ...ticket,
                        detailsPage: false,
                        listPage: true,
                      })
                    }
                  />
                </sup>
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="ticket-details">
        <div className="author">
          <div className="avatar">
            <img src={tickets.single.image} alt="user" />
          </div>
          <div className="name ">
            <div className="rank amount fw-bold">User</div>
            <div className="first-name ">{tickets.single.name}</div>
            <p className="faint">{tickets.single.email}</p>
          </div>
        </div>

        <div className="about-amount-section">
          <div className="about-ticket">
            {/* <div className="item">
            <p className="faint">Payment Date</p>
            <b>April 29, 2022</b>
          </div> */}

            <div className="item">
              <p className="faint">Created</p>
              <b>{new Date(tickets.single.createdAt).toDateString()}</b>
            </div>

            <div className="item">
              <p className="faint">Updated</p>
              <b>{new Date(tickets.single.updatedAt).toDateString()}</b>
            </div>

            <div className="item">
              <p className="faint">Category</p>
              <b>{tickets.single.category}</b>
            </div>

            <div className="item">
              <p className="faint">Ticket Type</p>
              <b>{tickets.single.status}</b>
            </div>
          </div>

          {tickets.single.amount && (
            <div className="amount-section">
              <div className="icon">
                <img src={naira_sign} alt="naira" />
              </div>

              <div className="dispute-amount">
                Amount
                <div className="value">
                ₦ {handleFormatAmount(tickets.single.amount)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="ticket-body">
        <div className="description">
          <h5>Description</h5>

          <div className="body">{tickets.single.description}</div>

          <h5>Admin</h5>
          <p>Mason Kayne</p>
        </div>
        <div className="account">
          <p>Account Status: REGULAR NGN</p>
          {user && user.Accounts[0] ? (
            <p className="w-75 text-center accountNumber">
              {user.Accounts[0].accountNumber} |{" "}
              {nameCase(user.Accounts[0].bankName)}
            </p>
          ) : (
            "**********"
          )}
          {user && user.Accounts[0] ? (
            <div className="h-100">
              <div className="d-flex align-items-center gap-2">
                {showBalance ? (
                  <MdOutlineVisibility
                    className="pointer"
                    onClick={() => handleToggleShowBalance()}
                  />
                ) : (
                  <MdOutlineVisibilityOff
                    className="pointer"
                    onClick={() => handleToggleShowBalance()}
                  />
                )}
                {showBalance ? (
                  <h4>₦{parseInt(user.walletBalance).toLocaleString()}</h4>
                ) : (
                  <h4>*****</h4>
                )}
              </div>
              <p className="last">
                Last Transaction :{" "}
                {new Date(user.walletUpdatedAt).toDateString()}{" "}
              </p>
            </div>
          ) : (
            <div className="h-100">
              <h4>₦ ***, ***, ***</h4>
              <p className="last">Last Transaction */**/**</p>
            </div>
          )}
        </div>
      </div>

      <div className="ticket-footer"></div>
    </div>
  );
}
