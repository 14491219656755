import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import success from "../../../icons/success.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";
export default function PaySuccess(props) {
  const { payModalShow, setPayModalShow, initPayModal,  } =
    useContext(KycManager);
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>
              setPayModalShow({ ...payModalShow, paySuccess: false, transaction:{...payModalShow.transaction, id:"", msg:"", network_err:"", ref:""} })
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <center>
            <div className="icon">
              <img src={success} alt="transfer successful" />
            </div>
            <div className="detail">
                <h3>Successful Transaction</h3>
                <p>Transaction Reference: {payModalShow.transaction ?payModalShow.transaction.ref:null}</p>
                <p>Transaction ID: {payModalShow.transaction? payModalShow.transaction.id:null}</p>
            </div>
          </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}
