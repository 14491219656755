import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import pending from "../../icons/success_vector.svg";
import success from "../../icons/success.svg";
import failed from "../../icons/fail_vector.svg";
import close from "../../icons/close.svg";
import "../../styles/modals.scss";
import { KycManager } from "../../context-api/KycContext";
export default function KycStatus(props) {
  const {
    payModalShow,
    setPayModalShow,
    initPayModal,
    showVeriSuccess,
    setShowVeriSuccess,
    setModalShowAccount,
    setModalShowSelectMethod,
  } = useContext(KycManager);
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>
              setShowVeriSuccess({ ...showVeriSuccess, idDoc: false })
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">

{
  showVeriSuccess.error?(
    <center>
            <div className="icon">
              <img src={failed} alt="transfer successful" />
            </div>
            <div className="detail">
              <h5 className="mo-info">KYC Failed</h5>
              <p className="mantra">
                Your identity verification failed. Kindly check your network and try again. If issues persist, please contact support.
              </p>
            </div> 
          </center>
  ): showVeriSuccess.indemnity?( 
    <center>

      {
        showVeriSuccess.indemnity?(
          <div className="icon">
          <img src={success} alt="transfer successful" />
        </div>
        ):(
          <div className="icon">
              <img src={pending} alt="upgrade successful" />
            </div>
        )
      }
            
            <div className="detail">
              <h5 className="mo-info">KYC Successful</h5>
              <p className="mantra">
                Your KYC has been approved and your have been upgraded to Tier 3
              </p>
            </div> 
          </center>
  ):(
    <center>

  
        <div className="icon">
            <img src={pending} alt="upgrade successful" />
          </div>
       
          <div className="detail">
            <h5 className="mo-info">KYC Pending</h5>
            <p className="mantra">
              Your KYC has been received and will be reviewed. If approved your
              account will upgraded.
            </p>
          </div> 
        </center>
  )
}


      




        </Modal.Body>
      </Modal>
    </div>
  );
}
