export function formatAmount(value) { 
    if (!value) return false; //empty value would break the app so we dont proceed if value is empty
value = value.toString()
    // Split the value into integer and decimal parts
    const [integerPart, decimalPart] = value.split(".");

    // Format the integer part with commas
    const formattedIntegerPart = integerPart
      .replace(/\D/g, "") //this removes all non-number characters
      .replace(/\B(?=(\d{3})+(?!\d))/g, ","); //this add the commas in appropriate places

    // Combine the integer and decimal parts to create the formatted value
    let formattedValue = formattedIntegerPart;
    if (decimalPart !== undefined) {
      formattedValue += "." + decimalPart.slice(0, 2); //this is checking whether there's a decimal part, it then joins them together and also approximate to 2 decimal places
    }

    return formattedValue; //whenever you call this function and pass in a parameter(the number you want to format),
    //it returns the formatted number. e.g handleFormatAmount(43213.08) would return 43,213.08
  }