 
import "../styles/dashboard.scss"   
import Sidebar from "../components/dashboard/Sidebar";
import Navigation from "../components/dashboard/Navigation"; 
import POSList from "../components/dashboard/POSList";
import { useContext } from "react";
import { KycManager } from "../context-api/KycContext";
export default function POS() {
     const {showSidebar, setShowSidebar} = useContext(KycManager)
    return (
        <div className="dashboard-container">
            <div className="navigation-section">
                <Navigation />
            </div>

            <div className="body-section">
                <div className={showSidebar?"sidebar":"hide-sidebar"}>
                    <Sidebar />
                </div>
                <div className=" dashboard-body other-body" onClick={()=>setShowSidebar(false)}>
                  <POSList/>

                </div>

            </div>


            <div className="modals">
            </div>
        </div>
    )
}