import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../context-api/KycContext"; 
import "../../styles/modals.scss";
import FinishAccountSetupForm from "../forms/FinishAccountSetupForm";
export default function CompleteSetup(props) {
  const {
    msg, 
    toverify, 
    showVeriSuccess,  
    showPos, setshowPos,
  } = useContext(KycManager); 
  
  const [requestDetails, setRequestDetails] = useState({
    "businessName": "Reef",
    "owner_name": "Feyi",
    "phone": "08120963057",
    "quantity": "100",
    "address": "wuse 2",
    "state": "Abuja",
    "city": "Zone 4",
    "country": "Nigeria"
})
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent">
        <div className="complete_setup_modal_container"> 
         <FinishAccountSetupForm className="w-100 bg-danger"/>
        </div>
      </Modal.Body>
    </Modal>
  );
}
