import signup_frame from "../images/signup-frame.png" 
import VerifyAccountForm from "../components/forms/VerifyAccountForm"
import SideFrame from "../components/signup/SideFrame"

export default function VerifyAccount() { 

    return (
        <div className="create-account-container">
            {/* <div className="background-area">
                <img src={signup_frame} alt="Best solutions" />
            </div>  */}
   
            {<SideFrame />}  
            {<VerifyAccountForm />}  

        </div>
    )
}