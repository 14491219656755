import { useContext, useEffect, useState } from "react";
import "../../styles/notifications.scss";
import "../../styles/pos.scss";
import new_notification from "../../icons/new_notification.svg";
import { variableManager } from "../../context-api/VariableContex";
import axios from "axios";
import SingleNotification from "./SingleNotification";
import { BsTrash } from "react-icons/bs";
import { Spinner } from "react-bootstrap";
export default function AllNotifications() {
  const [initial, setInitial] = useState({
    all: false,
    read: false,
    unread: false,
  });
  const [navselect, setNavselect] = useState({
    ...initial,
    all: true,
  });

  const [clicked, setClicked] = useState("");

  const {
    url,
    msg,
    user,
    handleReadNotification,handleGetUnreadNotifications,handleGetReadNotifications,
    handleDeleteNotification,
    notifications,
    readNotification,
    setReadNotification,
    notificationStatus,
  } = useContext(variableManager);

  useEffect(() => {
 if(user){
  // handleGetReadNotifications();
  // handleGetUnreadNotifications();
 }
  }, [user]);

  return (
    <div>
      <div className="activities-containe notification-container">
        <h5>Notification</h5>

        <div className="list-bod">
          <nav>
            <button
              className={navselect.all ? "active-nav-btn" : "nav-btn"}
              onClick={(e) => {
                setNavselect({
                  ...initial,
                  all: true,
                });
              }}
            >
              All
            </button>
            <button
              className={navselect.unread ? " active-nav-btn" : "nav-btn"}
              onClick={(e) => {
                setNavselect({
                  ...initial,
                  unread: true,
                });
              }}
            >
              Unread
            </button>
            <button
              className={navselect.read ? " active-nav-btn" : "nav-btn"}
              onClick={(e) =>
                setNavselect({
                  ...initial,
                  read: true,
                })
              }
            >
              Read
            </button>
          </nav>

          {navselect.all && (
            <div className="body">
              {notifications.notifications &&
              notifications.notifications.length ? (
                notifications.notifications.map((e, i) => {
                  return (
                    <div className="item" key={i}>
                      <div className="date">
                        <b>{new Date(e.date).toDateString()}</b>
                      </div>
                      {e.notifications
                        ? e.notifications.map((each, j) => {
                            return (
                              <div className={each.status ? " notification read" : " notification unread"} key={j}
                              onClick={()=>{
                                each.status = 1
                                handleReadNotification(each.nid)
                              }}
                              >
                                <div className="icon">
                                  <img
                                    src={new_notification}
                                    alt="notification"
                                  />
                                </div>
                                <div className="detail"> 
                                  <b className="title">{each.title}</b>
                                  <p className="message">{each.body}</p>
                                </div>

                                {/* <div
                                  className={each.status ? "viewed" : "view"}
                                >
                                  <button
                                    onClick={() => {
                                      handleReadNotification(each.nid);
                                      setReadNotification(true);
                                    }}
                                  >
                                    View
                                  </button>
                                  {msg.spinner && clicked === each._id ? (
                            <Spinner size="sm" variant="danger" />
                          ) : (
                            <BsTrash
                              className="mo-danger pointer"
                              onClick={() => {
                                setClicked(each._id)
                                handleDeleteNotification(each._id);
                              }}
                            />
                          )}
                                </div> */}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  );
                })
              ) : (
                <p>No notifications</p>
              )}
            </div>
          )}

          {navselect.unread && (
            <div className="body">
              {notificationStatus.unseen && notificationStatus.unseen.length ? (
                notificationStatus.unseen.map((e, i) => {
                  return (
                    <div className="item" key={i}>
                      <div className={e.status ? "read notification " : "unread notification "}
                      onClick={()=>{
                      e.status = 1
                        handleReadNotification(e.nid);
                        // setReadNotification(true);
                      }}
                      >
                        <div className="icon">
                          <img src={new_notification} alt="notification" />
                        </div>
                        <div className="detail">
                          <b className="title">{e.title}</b>
                          <p className="message">{e.body}</p>
                          <div className="date">
                            {new Date(e.createdAt).toDateString()}
                          </div>
                        </div>
 
                        {/* <div className={e.status ? "viewed" : "view"}>
                          <button
                            onClick={() => {
                              handleReadNotification(e.nid);
                              setReadNotification(true);
                            }}
                          >
                            View
                          </button>
                          {msg.spinner && clicked === e._id ? (
                            <Spinner size="sm" variant="danger" />
                          ) : (
                            <BsTrash
                              className="mo-danger pointer"
                              onClick={() => {
                                setClicked(e._id)
                                handleDeleteNotification(e._id);
                              }}
                            />
                          )}
                        </div> */}
                      </div>
                      {/* <div className="date">
                        <b>{new Date(e.date).toDateString()}</b>
                      </div>  */}
                    </div>
                  );
                })
              ) : (
                <p>No unread notifications</p>
              )}
            </div>
          )}

          {navselect.read && (
            <div className="body">
              {notificationStatus.seen && notificationStatus.seen.length ? (
                notificationStatus.seen.map((e, i) => {
                  return (
                    <div className="item" key={i}>
                      <div className={e.status ? "read notification" : "unread notification"}>
                        <div className="icon">
                          <img src={new_notification} alt="notification" />
                        </div>
                        <div className="detail">
                          <b className="title">{e.title}</b>
                          <p className="message">{e.body}</p>
                          <div className="date">
                            {new Date(e.createdAt).toDateString()}
                          </div>
                        </div>

                        {/* <div className={e.status ? "viewed" : "view"}>
                          <button
                            onClick={() => {
                              handleReadNotification(e.nid);
                              setReadNotification(true);
                            }}
                          >
                            View
                          </button>
                          {msg.spinner && clicked === e._id ? (
                            <Spinner size="sm" variant="danger" />
                          ) : (
                            <BsTrash
                              className="mo-danger pointer"
                              onClick={() => {
                                setClicked(e._id)
                                handleDeleteNotification(e._id);
                              }}
                            />
                          )}
                        </div> */}
                      </div>
                      {/* <div className="date">
                        <b>{new Date(e.date).toDateString()}</b>
                      </div>  */}
                    </div>
                  );
                })
              ) : (
                <p>No read notifications</p>
              )}
            </div>
          )}
        </div>

        <div className="modals"></div>

        {/* <SingleNotification
          show={readNotification}
          onHide={() => setReadNotification(false)}
        /> */}
      </div>
    </div>
  );
}
