// import { useContext } from "react";
// import { Outlet, useNavigate } from "react-router-dom";
// import { variableManager } from "./VariableContex";

// export default function ProtectedRoute({ prop }) {
//   const navigate = useNavigate();
//   const { isLoggedIn } = useContext(variableManager); 
//   console.log(isLoggedIn)
//   prop = isLoggedIn;
//   return prop ? <Outlet /> : navigate("/login") ;
// }


import { useContext, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom"; 
import { variableManager } from "./VariableContex";

export default function ProtectedRoute() {
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(variableManager); 
  const localUser = localStorage.getItem("mg-u-tkn"); 
  return localUser ? <Outlet /> : Navigate({to:"/login", replace:true});
 
}
