import total_tickets from "../../../icons/total_tickets.svg";
import archived_tickets from "../../../icons/archived_tickets.svg";
import opened_tickets from "../../../icons/open_tickets.svg";
import closed_tickets from "../../../icons/closed_tickets.svg";
import { useContext, useEffect, useState } from "react";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";

export default function TicketStatusSummary() {
  const { showSupport, setShowSupport, setTicket, ticket } =
    useContext(KycManager);
  const { tickets, setTickets } = useContext(variableManager);
  const [allTickets, setAllTickets] = useState([]);
  const [singleTicket, setSingleTicket] = useState({});
  const [ticketCount, setTicketCount] = useState({ all: [], single: {} });

  useEffect(() => {
    if (!allTickets.length && tickets.all.length) {
      setAllTickets(tickets.all);
      setSingleTicket(tickets.single);
      setTicketCount({
        ...ticketCount,
        all: tickets.all,
        single: tickets.single,
      });
    } else if (
      allTickets.length &&
      tickets.single.status !== singleTicket.status
    ) {
      setAllTickets(tickets.all);
      setSingleTicket(tickets.single);
      setTicketCount({
        ...ticketCount,
        all: tickets.all,
        single: tickets.single,
      });
    }
  }, [tickets.all, tickets]);

  function handleFilterTickets(query, array) {
    if (!query || !array) return false;
    if (query.toLowerCase() === "all") return array;
    const result = [];

    for (let i = 0; i < array.length; i++) {
      const each = array[i];

      if (each.status.toLowerCase() === query.toLowerCase()) {
        result.push(each);
      }
    }

    return result.length ? result : "";
  }

  return (
    <div className="ticket-summary-container">
      <div className="create-tictket-button d-flex justify-content-end">
        <button
          onClick={() => setShowSupport({ ...showSupport, createTicket: true })}
        >
          Create New Ticket
        </button>
      </div>
      <div className="cards-section">
        <div
          className="support-card"
          onClick={() => {
            setTickets({
              ...tickets,
              all: handleFilterTickets("all", allTickets),
            });

            setTicket({
              ...ticket,
              detailsPage: false,
              listPage: true,
            });
          }}
        >
          <div className="text">
            <p>Total Tickets</p>
            <b>
              {allTickets.length > tickets.all.length
                ? allTickets.length
                : tickets.all.length}
            </b>
          </div>
          <div className="icon">
            <img
              draggable="false"
              src={total_tickets}
              alt="total support tickets"
            />
          </div>
        </div>

        <div
          className="support-card  "
          onClick={() => {
            setTickets({
              ...tickets,
              all: handleFilterTickets("open", allTickets),
            });

            setTicket({
              ...ticket,
              detailsPage: false,
              listPage: true,
            });
          }}
        >
          <div className="text">
            <p>Opened Tickets</p>
            <b>{handleFilterTickets("open", allTickets).length}</b>
          </div>
          <div className="icon">
            <img
              draggable="false"
              src={opened_tickets}
              alt="opened support tickets"
            />
          </div>
        </div>

        <div
          className="support-card "
          onClick={() => {
            setTickets({
              ...tickets,
              all: handleFilterTickets("archived", allTickets),
            });

            setTicket({
              ...ticket,
              detailsPage: false,
              listPage: true,
            });
          }}
        >
          <div className="text">
            <p>Archived Tickets</p>
            <b>{handleFilterTickets("archived", allTickets).length}</b>
          </div>
          <div className="icon">
            <img
              draggable="false"
              src={archived_tickets}
              alt="archived support tickets"
            />
          </div>
        </div>

        <div
          className="support-card "
          onClick={() => {
            setTickets({
              ...tickets,
              all: handleFilterTickets("closed", allTickets),
            });

            setTicket({
              ...ticket,
              detailsPage: false,
              listPage: true,
            });
          }}
        >
          <div className="text">
            <p>Closed Tickets</p>
            <b>{handleFilterTickets("closed", allTickets).length}</b>
          </div>
          <div className="icon">
            <img
              draggable="false"
              src={closed_tickets}
              alt="closed support tickets"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
