import { useContext } from "react"; 
import open_ticket from "../../../icons/open_ticket.svg"; 
import { KycManager } from "../../../context-api/KycContext";

export default function DisputeTransaction({ amount, category }) {
  const { setShowSupport,
    showSupport, ticketDetails, setTicketDetails } = useContext(KycManager);

  return (
    <div className="close-ticket-container close-ticket-prompt">
      <div
        className="ticket-prompt"
        onClick={() => {
          setTicketDetails({
            ...ticketDetails, amount, category,
          })
          setShowSupport({ ...showSupport, createTicket: true })
        }}
      >
        <div className="icon">
          <img src={open_ticket} alt="close ticket" />
        </div>
        <p> Raise Ticket</p>
      </div> 
    </div>
  );
}
