// import logo from "../../icons/logo-md.png"
import logo from "../../icons/logo-colored.png";
import mobile_menu from "../../icons/mobile-menu.svg";
import "../../styles/navigation.scss";
// import search from "../../icons/search.png";
import alert from "../../icons/bell.png";
import my_profile_icon from "../../icons/my_profile_icon.svg";
import my_logout_icon from "../../icons/my_logout_icon.svg";
import comment from "../../icons/comment.png";
import { AiFillCaretDown } from "react-icons/ai";
import { useState, useContext } from "react";
import { variableManager } from "../../context-api/VariableContex";
import { useNavigate } from "react-router-dom";
import LogoutPrompt from "../modals/settings/LogoutPrompt";
import { KycManager } from "../../context-api/KycContext";
import { Toast } from "react-bootstrap";
import EmptyView from "../notification/EmptyView";
import SummaryView from "../notification/SummaryView";
import DeleteAccount from "../modals/delete_account/DeleteAccount";
import DeleteSuccess from "../modals/delete_account/DeleteSuccess";
import EnterPin from "../modals/delete_account/EnterPin";
import EnterPassword from "../modals/delete_account/EnterPassword";
import SingleNotification from "../notification/SingleNotification";
import DeletePrompt from "../modals/settings/DeletePrompt";
import ExportStatement from "../modals/account_statement/ExportStatement";
import ExportSuccess from "../modals/account_statement/ExportSuccess";
import ExportError from "../modals/account_statement/ExportError";
import CardDeposit from "../modals/get_paid/CardDeposit";
import CardList from "../modals/get_paid/CardList";
import AccountBalance from "../../service_workers/AccountBalance";
import CreateTicket from "../modals/support/CreateTicket";
import CreateTicketSuccess from "../modals/support/CreateTicketSuccess";
import CreatePin from "../modals/security/CreatePin";
import CreatePinSuccess from "../modals/security/CreatePinSuccess";
import NoAccountPrompt from "../modals/accounts/NoAccountPrompt";
import RechargeDetails from "../modals/bills_payment/airtime/RechargeDetails";
import PaymentMethod from "../modals/bills_payment/airtime/PaymentMethod";
import ConfirmPin from "../modals/bills_payment/ConfirmPin";
import Beneficiaries from "../modals/bills_payment/Beneficiaries";
 import BillSuccess from "../modals/bills_payment/BillSuccess";
import BillError from "../modals/bills_payment/BillError";
import CableDetails from "../modals/bills_payment/cable_tv/CableDetails";
import CablePaymentMethod from "../modals/bills_payment/cable_tv/PaymentMethod";
import ElectricityDetails from "../modals/bills_payment/electricity/ElectricityDetails";
import ElectricityPaymentMethod from "../modals/bills_payment/electricity/PaymentMethod";
import AirtimeSuccess from "../modals/bills_payment/airtime/AirtimeSuccess";
import BillsPrompt from "../modals/bills_payment/BillsPrompt";

export default function Navigation() {
  const {
    logoutPrompt,
    setLogoutPrompt,
    profile,
    setProfile,
    exportStatement,
    setExportStatement,
    payModalShow,
    setPayModalShow,
    showSidebar,
    setShowSidebar,
    showSupport,
    setShowSupport,
    showCreate,
    setShowCreate,

    view,
    setView,
    modalShowNoAccountPrompt,
    setModalShowNoAccountPrompt,

    //BILLS PAYMENT
    showBill,
    setShowBill,
    handleBillStatusChange,
  } = useContext(KycManager);
  const {
    user,
    nameCase,
    notifications,
    readNotification,
    setReadNotification,
  } = useContext(variableManager);
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div
      className="navigation-container"
      onClick={() => setShowDropdown(false)}
    >
      <nav className="navigation">
        <div className="logo-search-section">
          <div className="logo-section">
            <div className="logo pointer">
              <img
                src={logo}
                alt="Mongoro-logo"
                onClick={() => navigate("/user/dashboard")}
              />
            </div>
            <div className="mobile-menu">
              <img
                src={mobile_menu}
                alt="mobile sidebar"
                className="pointer"
                onClick={() => toggleSidebar()}
              />
            </div>
          </div>

          <div className="search-section">
            <div className="input">
              <div className="image">
                {/* <img src={search} alt="search" /> */}
              </div>
              <input type="hidden" placeholder="Search here" />
            </div>
          </div>
        </div>

        <div className="icons-profile-section">
          <div className="icons-section">
            <div
              className="content pointer"
              onClick={(e) => {
                e.stopPropagation();
                setView({ ...view, notifications: !view.notifications });
              }}
            >
              <div className="counter bell">
                {user ? notifications.totalUnread : 0}
              </div>
              <div className="icon">
                <img src={alert} alt="notification" />
              </div>
            </div>

            {/* <div className="content">
              <div className="counter mail">15</div>
              <div className="icon">
                <img src={mail} alt="messages" />
              </div>
            </div> */}

            <div className="content">
              <div className="counter comment">0</div>
              <div className="icon">
                <img src={comment} alt="comments" />
              </div>
            </div>
          </div>
          <div className="profile-section">
            <div className="user-rank">
              {user.firstName ? (
                <span>
                  {user.firstName.substring(0, 1).toUpperCase()}
                  {user.surname.substring(0, 1).toUpperCase()}
                </span>
              ) : null}
            </div>
            <div
              className=" pointer name"
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown();
              }}
            >
              <h5>
                {user.firstName ? (
                  <span>
                    {nameCase(user.firstName)} {nameCase(user.surname)}
                  </span>
                ) : (
                  "User"
                )}
              </h5>
              <p>user</p>
            </div>
            <div
              className="more pointer"
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown();
              }}
            >
              <AiFillCaretDown className="" />
            </div>
          </div>
        </div>
      </nav>

      <Toast show={showDropdown}>
        <div className="dropdown">
          <div
            className="item"
            onClick={() => navigate("/user/dashboard/settings")}
          >
            <div className="icon">
              <img src={my_profile_icon} alt="icon" />
            </div>
            My Profile
          </div>

          <hr />
          <div className="item" onClick={() => setLogoutPrompt(true)}>
            <div className="icon">
              <img src={my_logout_icon} alt="icon" />
            </div>
            Logout
          </div>
        </div>
      </Toast>

      <div className="notification-dropdown">
        {view.notifications && !notifications.total ? (
          <EmptyView />
        ) : view.notifications && notifications.total ? (
          <SummaryView />
        ) : null}
      </div>

      {/* ACCOUNT BALANCE SERVICE WORKER */}
      <AccountBalance />

      <div className="modals">
        {
          // <Sidebar/>
        }
        <SingleNotification
          show={readNotification}
          onHide={() => setReadNotification(false)}
        />
        <DeleteAccount
          show={profile.deleteAccount.delete}
          onHide={() =>
            setProfile({
              ...profile,
              deleteAccount: { ...profile.DeleteAccount, delete: false },
            })
          }
        />
        <DeleteSuccess
          show={profile.deleteAccount.success}
          onHide={() =>
            setProfile({
              ...profile,
              deleteAccount: { ...profile.DeleteAccount, success: false },
            })
          }
        />
        <EnterPin
          show={profile.deleteAccount.enterPin}
          onHide={() =>
            setProfile({
              ...profile,
              deleteAccount: { ...profile.DeleteAccount, enterPin: false },
            })
          }
        />
        <EnterPassword
          show={profile.deleteAccount.enterPassword}
          onHide={() =>
            setProfile({
              ...profile,
              deleteAccount: { ...profile.DeleteAccount, enterPassword: false },
            })
          }
        />
        <LogoutPrompt
          show={logoutPrompt}
          onHide={() => setLogoutPrompt(false)}
        />
        <DeletePrompt show={profile.deletePrompt.beneficiary} />
        {/* EXPORT ACCOUNT STATEMENT */}
        <ExportStatement
          show={exportStatement.request}
          onHide={() =>
            setExportStatement({ ...exportStatement, request: false })
          }
        />
        <ExportSuccess
          show={exportStatement.success}
          onHide={() =>
            setExportStatement({ ...exportStatement, success: false })
          }
        />{" "}
        <ExportError
          show={exportStatement.failed}
          onHide={() =>
            setExportStatement({ ...exportStatement, failed: false })
          }
        />
        {/* DEPOSITS AND PAYMENTS */}
        <CardDeposit
          show={payModalShow.fromCard.method}
          onHide={() => {
            setPayModalShow({
              ...payModalShow,
              fromCard: { ...payModalShow.fromCard, method: false },
            });
          }}
        />
        <CardList
          show={payModalShow.fromCard.cardList}
          onHide={() => {
            setPayModalShow({
              ...payModalShow,
              fromCard: { ...payModalShow.fromCard, cardList: false },
            });
          }}
        />
        {/* SUPPORT MODALS */}
        <CreateTicket
          show={showSupport.createTicket}
          onHide={() => {
            setShowSupport({
              ...showSupport,
              createTicket: false,
            });
          }}
        />
        <CreateTicketSuccess
          show={showSupport.ticketSuccess}
          onHide={() => {
            setShowSupport({
              ...showSupport,
              ticketSuccess: false,
            });
          }}
        />
        {/* PINS AND PASSWORDS */}
        <CreatePin
          show={showCreate.pin}
          onHide={() => setShowCreate({ ...showCreate, pin: false })}
          backdrop={"static"}
        />
        <CreatePinSuccess
          show={showCreate.pinSuccess}
          onHide={() => setShowCreate({ ...showCreate, pinSuccess: false })}
        />
        <EnterPin
          show={showCreate.enterPin}
          onHide={() => setShowCreate({ ...showCreate, enterPin: false })}
          backdrop={"static"}
        />
        {/* USER HAS NO ACCOUNT TO PERFORM TRANSACTIONS */}
        <NoAccountPrompt
          show={modalShowNoAccountPrompt}
          onHide={() => setModalShowNoAccountPrompt(false)}
          backdrop={"static"}
        />
        {/* BILLS PAYMENT (AIRTIME)*/}
        <RechargeDetails
          show={showBill.airtime.details}
          onHide={() =>
            // setShowBill({
            //   ...showBill,
            //   airtime: { ...showBill.airtime, details: false },
            // })
            handleBillStatusChange("resetAll")
          }
          backdrop={"static"}
        />
        <PaymentMethod
          show={showBill.airtime.paymentMethod}
          onHide={() =>
            setShowBill({
              ...showBill,
              airtime: { ...showBill.airtime, paymentMethod: false },
            })
          }
          backdrop={"static"}
        />
        {/* <AirtimeSuccess
          show={showBill.airtime.airtimeSuccess}
          onHide={() =>
            setShowBill({
              ...showBill,
              airtime: { ...showBill.airtime, airtimeSuccess: false },
            })
          }
          backdrop={"static"}
        /> */}
        {/* GLOBAL */}
        <ConfirmPin
          show={showBill.global.enterPin}
          onHide={() =>
            setShowBill({
              ...showBill,
              global: { ...showBill.global, enterPin: false },
            })
          }
          backdrop={"static"}
        />
        <Beneficiaries
          show={showBill.global.chooseBeneficiary}
          onHide={() =>
            setShowBill({
              ...showBill,
              global: { ...showBill.global, chooseBeneficiary: false },
            })
          }
          backdrop={"static"}
        />
        <BillsPrompt
          show={showBill.global.deletePrompt}
          onHide={() =>
            setShowBill({
              ...showBill,
              global: { ...showBill.global, deletePrompt: false },
            })
          }
          backdrop={"static"}
        />
        <BillSuccess
          show={showBill.status.success}
          onHide={() =>
            setShowBill({
              ...showBill,
              status: { ...showBill.status, success: false },
            })
          }
          backdrop={"static"}
        />
        <BillError
          show={showBill.status.error}
          onHide={() =>
            setShowBill({
              ...showBill,
              status: { ...showBill.status, error: false },
            })
          }
          backdrop={"static"}
        />
        {/* BILLS PAYMENT (CABLE TV) */}
        <CableDetails
          show={showBill.cableTv.details}
          onHide={() => handleBillStatusChange("resetAll")}
          backdrop={"static"}
        />
        <CablePaymentMethod
          show={showBill.cableTv.paymentMethod}
          onHide={() => handleBillStatusChange("resetAll")}
          backdrop={"static"}
        />
        {/* BILLS PAYMENT (ELECTRICITY) */}
        <ElectricityDetails
          show={showBill.electricity.details}
          onHide={() => handleBillStatusChange("resetAll")}
          backdrop={"static"}
        />
        <ElectricityPaymentMethod
          show={showBill.electricity.paymentMethod}
          onHide={() => handleBillStatusChange("resetAll")}
          backdrop={"static"}
        />
      </div>
    </div>
  );
}
