import { useContext } from "react";
// import close_ticket from "../../../icons/close_ticket.svg";
import open_ticket from "../../../icons/open_ticket.svg"; 
import { variableManager } from "../../../context-api/VariableContex";

export default function TicketAction({element, id}) {
const {handleCloseTicket} = useContext(variableManager)
 
  return (
    <div className="close-ticket-container close-ticket-prompt">
      <div className="ticket-prompt">
        <div className="icon">
          <img src={open_ticket} alt="close ticket" />
        </div>
        <p> View Ticket</p>
      </div>
      {/* <div
        className="ticket-prompt text-danger"
        onClick={(e) => {
          e.stopPropagation();
          handleCloseTicket(element, id)
        }}
      >
        <div className="icon">
          <img src={close_ticket} alt="close ticket" />
        </div>
        <p>Close Ticket</p>
      </div> */}
    </div>
  );
}
