import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import avatar from "../../../icons/avatar.jpg";
import close from "../../../icons/close.svg";

import { BiSearch } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import "../../../styles/modals.scss";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";
import axiosInstance from "../../../utils/axios_instance";
export default function Beneficiaries(props) {
  const [userName, setUsername] = useState("");
  const [modalShowPayNow, setModalShowPayNow] = useState(false);
  const {
    setRerender,
    showBill,
    setShowBill,
    handleBillStatusChange,
    initPayModal,
    profile,
    setProfile,
    billPayload,
    setBillPayload,
  } = useContext(KycManager);
  const { url, msg, setMsg, user, nameCase,  } =
    useContext(variableManager);

  const [mobilesBeneficiary, setMobilesBeneficiary] = useState([]);
  const [cablesBeneficiary, setCablesBeneficiary] = useState([]);
  const [clicked, setClicked] = useState({
    id: "",
    search: "",
  });

  const [searchResult, setSearchResult] = useState("");

  useEffect(() => {
    handleGetBeneficiaries();
  }, [user]);

  async function handleGetBeneficiaries() {
    try {
      const list = await axiosInstance.get(`/user/beneficiary/user`);
      const data = list.data.data;
      setMobilesBeneficiary(data.mobilesBeneficiary);
      setCablesBeneficiary(data.cablesBeneficiary);
    } catch (error) {
      console.log(error);
    }
  }

  function searchData(data, query) {
    let result;
    if (showBill.airtime) {
      result = data.filter((a) => {
        if (
          a.beneficiaryName.toLowerCase().includes(query) ||
          a.digits.includes(query)
        ) {
          return a;
        } else {
          return false;
        }
      });
    } else {
      result = data.filter((a) => {
        if (
          a.beneficiaryName.toLowerCase().includes(query) ||
          a.digits.includes(query)
        ) {
          return a;
        } else {
          return false;
        }
      });
    }

    if (result.length) {
      return result;
    } else {
      return [{ beneficiaryName: "No results found" }];
    }
  }
 

  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        {/* <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() => {
              handleBillStatusChange("switchOff");
            }}
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header> */}

        <Modal.Body className="modalContent" style={{ height: "100vh" }}>
          <div className="input-area">
            <div className="choose-contact">
              <div className="head mb-4">
                <BsChevronLeft
                  className="pointer"
                  onClick={() => {
                    handleBillStatusChange("switchOff");

                    // if(showBill.actionFrom.airtime){
                    //   handleBillStatusChange("airtime", "details")
                    // }else if (showBill.actionFrom.cableTv){
                    //   handleBillStatusChange("cableTv", "details")
                    // }
                  }}
                />
                <h5>Choose a Beneficiary</h5>
                <div className="search">
                  <BiSearch
                    onClick={() => setClicked({ ...clicked, search: true })}
                  />
                  {clicked.search && (
                    <div className="box">
                      <AiOutlineClose
                        className="mo-danger pointer"
                        onClick={() => {
                          setClicked({ ...clicked, search: false });
                          setSearchResult("");
                        }}
                      />
                      <input
                        type="search"
                        autoFocus
                        placeholder="find beneficiary"
                        onChange={(e) => {
                          if (showBill.airtime.details) {
                            setSearchResult(
                              searchData(mobilesBeneficiary, e.target.value)
                            );
                          } else {
                            setSearchResult(
                              searchData(cablesBeneficiary, e.target.value)
                            );
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>





              {showBill.airtime.details &&
              !searchResult &&
              mobilesBeneficiary.length ? (
                mobilesBeneficiary.map((e, i) => {
                  return (
                    <div
                      className="contact"
                      onClick={() => {
                        setBillPayload({
                          ...billPayload,
                          airtimeData: { ...billPayload.airtimeData, customer:e.digits },
                        });
                        handleBillStatusChange("switchOff"); 
                      }}
                      key={i}
                    >
                      <div className="details pointer">
                        <div className="image">
                          {e.image && (
                            <img src={e.image} alt={e.beneficiaryName} />
                          )}
                        </div>
                        <div className="names">
                          <p className="first">{nameCase(e.beneficiaryName)}</p>
                          <p>{e.digits}</p>
                        </div>
                      </div>

                      <div
                        className="right"
                        onClick={(event) => {
                          setClicked({ ...clicked, id: e.beid });
                          event.stopPropagation();
                           setShowBill({
                            ...showBill,
                            global: { ...showBill.global, deletePrompt: true, actionId:e.beid },
                          }) 
                        }}
                      >
                        {/* <BsChevronRight /> */}

                        {msg.spinner && e.beid === clicked.id ? (
                          <Spinner size="sm" variant="warning" />
                        ) : (
                          <AiOutlineClose className="mo-danger pointer" />
                        )}
                      </div>
                    </div>
                  );
                })
              ) : showBill.airtime.details &&
                searchResult &&
                searchResult.length ? (
                searchResult.map((e, i) => {
                  return (
                    <div
                      className="contact"
                      onClick={() => {
                        setBillPayload({
                          ...billPayload,
                          airtimeData: { ...billPayload.airtimeData, customer:e.digits },
                        });
                        handleBillStatusChange("switchOff"); 
                      }}
                      key={i}
                    >
                      <div className="details pointer">
                        <div className="image">
                          {e.image && (
                            <img src={e.image} alt={e.beneficiaryName} />
                          )}
                        </div>
                        <div className="names">
                          <p className="first">{nameCase(e.beneficiaryName)}</p>
                          <p>{e.digits}</p>
                        </div>
                      </div>

                      <div
                        className="right"
                        onClick={(event) => {
                          setClicked({ ...clicked, id: e.beid });
                          event.stopPropagation();
                          setShowBill({
                            ...showBill,
                            global: { ...showBill.global, deletePrompt: true, actionId:e.beid },
                          })
                        }}
                      >
                        {/* <BsChevronRight /> */}
                        {msg.spinner && e.beid === clicked.id ? (
                          <Spinner size="sm" variant="warning" />
                        ) : (
                          e.beid && (
                            <AiOutlineClose className="mo-danger pointer" />
                          )
                        )}
                      </div>
                    </div>
                  );
                })
              ) : showBill.airtime.details &&
                !searchResult &&
                !mobilesBeneficiary.length ? (
                <p>
                  You have no saved beneficiaries yet, transact now to save
                  beneficiaries 
                </p>
              ) : null}



              {!showBill.airtime.details ? (
                showBill.cableTv.details &&
                !searchResult &&
                cablesBeneficiary.length ? (
                  cablesBeneficiary.map((e, i) => {
                    return (
                      <div className="contact" key={i}>
                        <div
                          className="details pointer"
                         onClick={() => {
                        setBillPayload({
                          ...billPayload,
                          cableTv: { ...billPayload.cableTv, customer:e.digits, validCustomer:e.beneficiaryName },
                        });
                        handleBillStatusChange("switchOff"); 
                      }}
                        >
                          {/* <div className="image">
                            <img src={avatar} alt="contact" />
                          </div> */}
                          <div className="names">
                            <p className="first">
                              {nameCase(e.beneficiaryName)}
                            </p>
                            <p>{e.bankName ? e.bankName : null}</p>
                            <p>{e.digits}</p>
                            {/* <p>{e.digits}</p> */}
                          </div>
                        </div>

                        <div
                          className="right"
                          onClick={(event) => {
                            // event.stopPropagation(); 
                            setClicked({ ...clicked, id: e.beid }); 
                            setShowBill({
                              ...showBill,
                              global: { ...showBill.global, deletePrompt: true, actionId:e.beid },
                            })
                              // handleBillStatusChange("global", "deletePrompt") 
                          }}
                        >
                          {/* <BsChevronRight /> */}
                          {msg.spinner && e.beid === clicked.id ? (
                            <Spinner size="sm" variant="warning" />
                          ) : (
                            <AiOutlineClose className="mo-danger pointer" />
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : showBill.cableTv.details &&
                  searchResult &&
                  searchResult.length ? (
                  searchResult.map((e, i) => {
                    return (
                      <div className="contact" key={i}>
                        <div
                          className="details pointer"
                          onClick={() => {
                            setBillPayload({
                              ...billPayload,
                              cableTv: { ...billPayload.cableTv, customer:e.digits, validCustomer:e.beneficiaryName },
                            });
                            handleBillStatusChange("switchOff"); 
                          }}
                        >
                          {/* <div className="image">
                            <img src={avatar} alt="contact" />
                          </div> */}
                          <div className="names">
                            <p className="first">
                              {nameCase(e.beneficiaryName)}
                            </p>
                            <p>{e.digits}</p>
                            {/* <p>{e.digits}</p> */}
                          </div>
                        </div>

                        <div
                          className="right"
                          onClick={(event) => {
                            setClicked({ ...clicked, id: e.beid });
                            event.stopPropagation();
                            setShowBill({
                              ...showBill,
                              global: { ...showBill.global, deletePrompt: true, actionId:e.beid },
                            })
                          }}
                        >
                          {/* <BsChevronRight /> */}
                          {msg.spinner && e.beid === clicked.id ? (
                            <Spinner size="sm" variant="warning" />
                          ) : (
                            <AiOutlineClose className="mo-danger pointer" />
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : showBill.cableTv.details &&
                  !searchResult &&
                  !cablesBeneficiary.length ? (
                  <p>
                    You have no saved beneficiaries yet, transact now to save
                    beneficiaries 
                  </p>
                ) : null
              ) : null}

              {showBill.electricity.details &&
              !searchResult &&
              cablesBeneficiary.length ? (
                cablesBeneficiary.map((e, i) => {
                  return (
                    <div
                      className="contact"
                      onClick={() => {
                        setShowBill({
                          ...initPayModal,
                          airtime: true,
                        });
                      }}
                      key={i}
                    >
                      <div className="details pointer">
                        <div className="image">
                          {e.image && (
                            <img src={e.image} alt={e.beneficiaryName} />
                          )}
                        </div>
                        <div className="names">
                          <p className="first">{nameCase(e.beneficiaryName)}</p>
                          <p>{e.digits}</p>
                        </div>
                      </div>

                      <div
                        className="right"
                        onClick={(event) => {
                          setClicked({ ...clicked, id: e.beid });
                          event.stopPropagation();
                          setProfile({
                            ...profile,
                            deletePrompt: {
                              ...profile.deletePrompt,
                              beneficiary: true,
                              beneficiaryId: e.beid,
                            },
                          });
                        }}
                      >
                        {/* <BsChevronRight /> */}

                        {msg.spinner && e.beid === clicked.id ? (
                          <Spinner size="sm" variant="warning" />
                        ) : (
                          <AiOutlineClose className="mo-danger pointer" />
                        )}
                      </div>
                    </div>
                  );
                })
              ) : showBill.electricity.details &&
                searchResult &&
                searchResult.length ? (
                searchResult.map((e, i) => {
                  return (
                    <div className="contact" onClick={() => {}} key={i}>
                      <div className="details pointer">
                        <div className="image">
                          {e.image && (
                            <img src={e.image} alt={e.beneficiaryName} />
                          )}
                        </div>
                        <div className="names">
                          <p className="first">{nameCase(e.beneficiaryName)}</p>
                          <p>{e.digits}</p>
                        </div>
                      </div>

                      <div
                        className="right"
                        onClick={(event) => {
                          setClicked({ ...clicked, id: e.beid });
                          event.stopPropagation();
                        }}
                      >
                        {/* <BsChevronRight /> */}
                        {msg.spinner && e.beid === clicked.id ? (
                          <Spinner size="sm" variant="warning" />
                        ) : (
                          e._id && (
                            <AiOutlineClose className="mo-danger pointer" />
                          )
                        )}
                      </div>
                    </div>
                  );
                })
              ) : showBill.electricity.details &&
                !searchResult &&
                !cablesBeneficiary.length ? (
                <p>
                  You have no saved beneficiaries yet, transact now to save
                  beneficiaries
                  <br />
                  <span
                    onClick={() =>
                      handleBillStatusChange("global", "deletePrompt")
                    }
                    className="text-danger fw-bolder pointer"
                  >
                    DELETE Electricity BENEFICIARY
                  </span>
                </p>
              ) : null}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <div className="modal">
                <PayNow
                show={showBill.payNow}
                onHide={()=> setShowBill({...initPayModal, payNow:false})}
                />
            </div> */}
    </div>
  );
}
