import { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import close from "../../../icons/close.svg";
import oops from "../../../icons/oops.svg"; 
import "../../../styles/getPaid.scss";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex"; 

export default function NoAccountPrompt(props) {
  const {
    msg,
    setMsg,
    setModalShowNoAccountPrompt,
    setModalShowPrompt,
    setModalShowBvn,
    setModalShowAccountType,
  } = useContext(KycManager);

  const { user } = useContext(variableManager);

  return (
    <div className="select-mongoro-type-container">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h5 className="d-flex justify-content-start w-75 fw-bold">Account Required!</h5>
          <div
            className="modal-header pointer"
            onClick={() => {
              setModalShowNoAccountPrompt(false);
              setMsg({ ...msg, error: "" });
            }}
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <center>
            <div className="oops" >
                <img src={oops}  alt="error" />
            </div>
            <p className="m-2">
              You need an account to perform this operation. Complete your KYC
              and Create your account now.
            </p>

            <button
              className="mo-active-btn w-50 mt-2"
              onClick={(e) => {
                e.preventDefault();
                if (!user.setupComplete) {
                  setModalShowPrompt(true);
                  
                } else if (!user.verificationBvn) {
                  setModalShowBvn(true);
                } else { 
                  setModalShowAccountType(true);
                }
                setModalShowNoAccountPrompt(false)
              }}
            >
              Create Account
            </button>
          </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}
