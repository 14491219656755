import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import success from "../../../icons/completed.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";
export default function DeleteSuccess(props) {
  const { setProfile, profile } = useContext(KycManager);
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>
              setProfile({
                ...profile,
                deleteAccount: { ...profile.DeleteAccount, success: false },
              })
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <center>
            <div className="icon">
              <img src={success} alt="transfer successful" />
            </div>
            <div className="detail">
              <h5>Account Successfully Deleted</h5>
              <p className="mantra">
                Your Mongoro account as been deleted. You can no longer use the
                account.
              </p>
              <p> </p>
            </div>
          </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}
