import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import error from "../../../icons/error.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
export default function PayError(props) {
  const { payModalShow, setPayModalShow, initPayModal } =
    useContext(KycManager);

    const {setMongoroReceiver,
      mongoroReceiver} = useContext(variableManager)
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>{
              setPayModalShow({
                ...payModalShow,
                payFail: false,
                transaction: {
                  ...payModalShow.transaction,
                  msg: "",
                  network_err: "",
                  ref: "",
                },
              } 
              )
              setMongoroReceiver({ ...mongoroReceiver, walletTag: "", pin:"", amount:"", narration:"" })
            }
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <center>
            <div className="icon">
              <img src={error} alt="transfer failed" />
            </div>
            <div className="detail">
              <h3>Failed Transaction</h3>
              <p>
                {payModalShow.transaction ? payModalShow.transaction.msg : null}
              </p>
              <p className="fw-bold mo-danger">
                {payModalShow.transaction &&
                payModalShow.transaction.network_err
                  ? payModalShow.transaction.network_err
                  : null}
              </p>
              {payModalShow.transaction && payModalShow.transaction.ref ? (
                <p>Transaction Reference:{payModalShow.transaction.ref} </p>
              ) : null}

              {/* <p>Transaction Reference: {payModalShow.transaction ?payModalShow.transaction.ref:null}</p> */}
            </div>
          </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}
