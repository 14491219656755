 import axiosInstance from "../../utils/axios_instance";
 
export async function recharge(payload) { 
  
  let data = "";
  let error = "";

  try {
    await axiosInstance.post("/user/bill/payment", payload).then((resp) => {
       data = resp.data.data; 
    });
  } catch (err) {
    error= err;
  }

  return {data,error}
}


  