import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import "../../../styles/modals.scss";
import { AiOutlineLeft } from "react-icons/ai";
export default function CardDeposit(props) {
  const { payModalShow, setPayModalShow, initPayModal, } = useContext(KycManager);

  const { msg } = useContext(variableManager);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent">
        <h2 className="d-flex align-items-center gap-4">
          {" "}
          <span className="back pointer" onClick={()=> setPayModalShow({...initPayModal, receiveMethod:true}) }>
            <AiOutlineLeft />{" "}
          </span>{" "}
          Deposit via Cards
        </h2>

        <div className="input-area">
          <div className="label">
            <label htmlFor="enterEvn">Amount</label>
          </div>
          <div className="input">
            <input
              type="text"
              id="enterBvn"
              placeholder="000000000"
              onChange={(e) => {}}
            />
          </div>
        </div>
        <div className="input-area">
          <div className="label">
            <label htmlFor="enterEvn">Select Card</label>
          </div>
          <div className="input">
            <select name="" id="" onClick={()=> setPayModalShow({...payModalShow, fromCard:{...initPayModal.fromCard, cardList:true}}) }>
              <option value="">Select card</option>
            </select>
          </div>
        </div>

        <div className="buttons">
          <button
            className={ "btn-active"}
            onClick={() => {}}
          >
            {msg.spinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Add Money"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
