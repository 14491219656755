import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import pinsuccess from "../../../icons/pinsuccess.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";

export default function BankAddSuccess(props) { 

  const { setProfile, profile } = useContext(KycManager);

  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() => setProfile({ ...profile, bankAddSuccess: false })}
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <center>
            <div className="icon w-25">
              <img
                src={pinsuccess}
                alt="Pin Creation Successful"
                className="img-fluid"
              />
            </div>
            <div className="detail">
              <h5>Bank Detatil Successfully Added</h5>
              <p>Bank Detail added successfully</p>
            </div>
          </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}
