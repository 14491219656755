import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import pinsuccess from "../../../icons/pinsuccess.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";
import axios from "axios";
import { variableManager } from "../../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";

export default function LogoutPrompt(props) {
  const { setLogoutPrompt } = useContext(KycManager);
  const { 
    handleLogout, 
  } = useContext(variableManager);

  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header></Modal.Header>
        <Modal.Body className="modalContent">
          <div className="detail mb-5">
            <h5>Logout from Mongoro?</h5>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <button
              className="mo-active-btn w-50"
              onClick={() => {
                handleLogout();
                setLogoutPrompt(false)
              }}
            >
              Yes, Log out
            </button>

            <button
              className="btn btn-light"
              onClick={() => setLogoutPrompt(false)}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
