import CreationSuccess from "../components/signup/CreationSuccess";
import signup_frame from "../images/signup-frame.png"

export default function AccountCreationSuccess(){
    return(
        <div className="create-account-container">
        <div className="background-area">
            <img src={signup_frame} alt="Best solutions" />
        </div>  
        
          {<CreationSuccess />} 

    </div>
    )
}