import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import "../../../styles/modals.scss";
import "../../../styles/getPaid.scss";
import { AiOutlineLeft } from "react-icons/ai";
import mastercard from "../../../icons/mastercard.png";
import gtb from "../../../icons/gtb_logo.png";
import plus from "../../../icons/plus.svg";
export default function CardList(props) {
  const {setPayModalShow, initPayModal, } = useContext(KycManager);

  const { msg } = useContext(variableManager);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent">
        <h2 className="d-flex align-items-center gap-4">
          {" "}
          <span className="back pointer" onClick={()=> setPayModalShow({...initPayModal, fromCard:{...initPayModal.fromCard, method:true}}) }>
            <AiOutlineLeft />{" "}
          </span>{" "}
          Select Card
        </h2>

        <div className="added-cards">
          <div className="each-card pointer">
            <div className="bank-logo">
              <img src={gtb} alt="gtb" />
            </div>
            <div className="card-details">
              <p>5124 **** **** 7553</p>
              <p>ADEPOJU DEBORAH</p>
            </div>
            <div className="card-type">
              <img src={mastercard} draggable="false" alt="mastercard" />
            </div>
          </div>


          <div className="each-card pointer">
            <div className="bank-logo">
              <img src={gtb} draggable="false" alt="gtb" />
            </div>
            <div className="card-details">
              <p>5124 **** **** 7553</p>
              <p>ADEPOJU DEBORAH</p>
            </div>
            <div className="card-type">
              <img src={mastercard} draggable="false" alt="mastercard" />
            </div>
          </div>

          <div className="add-card pointer">
          <span className="plus"><img src={plus} draggable="false" alt="add card" /></span> Add Card Details
          </div>



        </div>
      </Modal.Body>
    </Modal>
  );
}
