import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import avatar from "../../../icons/avatar.jpg";
import wallet from "../../../icons/wallet_color.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";
import axios from "axios";
export default function PayNow(props) {
  const [userName, setUsername] = useState("");
  const {
    payModalShow,
    setPayModalShow,
    initPayModal,
    showCreate,
    setShowCreate,
  } = useContext(KycManager);
  const {
    url,
    msg,
    setMsg,
    user,
    name,
    nameCase,
    mongoroReceiver,
    setMongoroReceiver,
    handleSendtoUser,
    handleFormatAmount,
    handleValidateBalance,
  } = useContext(variableManager);

  // console.log(name)

  const [initAmount, setInitAmount] = useState({
    manual: false,
    m10k: false,
    m50k: false,
    m100k: false,
    m500k: false,
  });
  const [amount, setAmount] = useState({
    picked: "",
    ...initAmount,
  });
  const [error, setError] = useState("");

  return (
    <div className="pay-now-container">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() => {
              setPayModalShow({ ...initPayModal, payNow: false });
              setMongoroReceiver({
                ...mongoroReceiver,
                narration: "",
                amount: "",
                walletTag: "",
              });
              setMsg({ ...msg, error: "", success: "" });
              setAmount({ ...initAmount, manual: false });
            }}
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <div className="profile input-area username">
            <div className="image">
              <img src={user.image ? user.image : ""} alt={user.firstName} />
            </div>
            {/* <img src={`https://server-apis.herokuapp.com/uploads/${user.image}`} alt="profile picture" /> */}
            <center>
              <b>
                {" "}
                {nameCase(user.firstName)} {nameCase(user.middleName)}{" "}
                {nameCase(user.surname)}
              </b>
              <br />
              {user.walletTag}
            </center>
          </div>

          <center className="select-amount">
            <button
              value={10000}
              className={amount.m10k ? "active-amount" : null}
              onClick={(e) => {
                setAmount(
                  {
                    ...amount,
                    manual: false,
                    picked: e.target.value,
                    ...initAmount,
                    m10k: true,
                  },
                  setMongoroReceiver({
                    ...mongoroReceiver,
                    amount: e.target.value,
                  })
                );
                setError("");
                setMsg({ ...msg, error: "", success: "" });
              }}
            >
              ₦10, 000
            </button>
            <button
              value={50000}
              className={amount.m50k ? "active-amount" : null}
              onClick={(e) => {
                setAmount(
                  {
                    ...amount,
                    manual: false,
                    picked: e.target.value,
                    ...initAmount,
                    m50k: true,
                  },
                  setMongoroReceiver({
                    ...mongoroReceiver,
                    amount: e.target.value,
                  })
                );

                setError("");
                setMsg({ ...msg, error: "", success: "" });
              }}
            >
              ₦50, 000
            </button>
            <button
              value={100000}
              className={amount.m100k ? "active-amount" : null}
              onClick={(e) => {
                setAmount(
                  {
                    ...amount,
                    manual: false,
                    picked: e.target.value,
                    ...initAmount,
                    m100k: true,
                  },
                  setMongoroReceiver({
                    ...mongoroReceiver,
                    amount: e.target.value,
                  })
                );
                setError("");
                setMsg({ ...msg, error: "", success: "" });
              }}
            >
              ₦100, 000
            </button>
            <button
              value={500000}
              className={amount.m500k ? "active-amount" : null}
              onClick={(e) => {
                setAmount(
                  {
                    ...amount,
                    manual: false,
                    picked: e.target.value,
                    ...initAmount,
                    m500k: true,
                  },
                  setMongoroReceiver({
                    ...mongoroReceiver,
                    amount: e.target.value,
                  })
                );
                setError("");
                setMsg({ ...msg, error: "", success: "" });
              }}
            >
              ₦500, 000
            </button>
            {!amount.manual ? (
              <button
                value={""}
                onClick={() =>
                  setAmount(
                    { ...initAmount, manual: true },
                    setMongoroReceiver({ ...mongoroReceiver, amount: "" })
                  )
                }
              >
                Different Amount
              </button>
            ) : (
              <input
                type="text"
                className="amount"
                autoFocus
                placeholder="enter amount"
                value={handleFormatAmount(mongoroReceiver.amount)}
                onChange={(e) => {
                  setAmount({
                    ...amount,
                    picked: e.target.value.replace(/,/g, ""),
                  });
                  setMongoroReceiver({
                    ...mongoroReceiver,
                    amount: e.target.value.replace(/,/g, ""),
                  });

                  setError("");
                  setMsg({ ...msg, error: "", success: "" });
                }}
              />
            )}
          </center>

          <center className="wallet-section">
            <img src={wallet} alt="wallet" />{" "}
            <span className="wallet">
              Wallet: <span className="balance">{user.walletBalance}</span>
            </span>
          </center>

          <div className="input-area">
            <div className=" ">
              <label htmlFor="username">Payment Description </label>
              <input
                type="text"
                id="username"
                placeholder=" "
                onChange={(e) => {
                  setError("")
                  setMongoroReceiver({
                    ...mongoroReceiver,
                    narration: e.target.value,
                  });
                  setMsg({ ...msg, error: "", success: "" });
                }}
              />
            </div>
            <div className="checkbox">
              <label htmlFor="saveBeneficiary" className="pointer">
                <input
                  type="checkbox"
                  id="saveBeneficiary"
                  onChange={(e) => {
                    setMongoroReceiver({
                      ...mongoroReceiver,
                      saveBeneficiary: e.target.checked,
                    });
                  }}
                />{" "}
                Save Beneficiary
              </label>
            </div>

            {payModalShow.payNow && msg.success ? (
              <p className="mo-danger fw-bold mt-2">{msg.success.msg}</p>
            ) : null}

            {error && <p className="mo-danger mt-3 fw-bold">{error}</p>}
            {payModalShow.payNow && msg.error && (
              <p className="mo-danger mt-3 fw-bold">{msg.error}</p>
            )}

            <div className="button-section">
              <button
                className={
                  mongoroReceiver.amount && mongoroReceiver.narration
                    ? "btn-active"
                    : "btn-inactive"
                }
                disabled={msg.spinner ? "{true}" : ""}
                onClick={() => {
                  if (
                    handleValidateBalance(mongoroReceiver.amount, user) &&
                    mongoroReceiver.amount &&
                    mongoroReceiver.narration
                  ) { 
                    setShowCreate({ ...showCreate, enterPin: true });
                  } else {
                    if(!mongoroReceiver.amount){
                      setError(
                        "Enter an amount to send"
                      );
                    }else if(!mongoroReceiver.narration){
                      setError(
                        "Enter transaction description"
                      );
                    }else{
                      setError(
                        "Insufficient funds. Kindly fund your account or send a lower amount"
                      );
                    }
                   
                  }
                  // handleSendtoUser()
                }}
              >
                {msg.spinner ? <Spinner size="sm" /> : "Pay Now"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
