import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import kyc from "../../../icons/kyc.png";
import "../../../styles/modals.scss";

export default function EnterPin(props) {
  const [modalShow, setModalShow] = useState(false);
  const { user, name, url, accountDetails, setAccountDetails, handleGetUser } =
    useContext(variableManager);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [verify, setVerify] = useState(new Array(6).fill(""));
  const [error, setError] = useState("");

  const { profile, setProfile } = useContext(KycManager);

  const handleChange = (element, index, toMap) => {
    if (isNaN(element.value)) return false;

    if (toMap === "email") {
      setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    } else if (toMap === "verify") {
      setVerify([
        ...verify.map((d, idx) => (idx === index ? element.value : d)),
      ]);
    }
    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  useEffect(() => {
    return () => {
      // setOtp(new Array(6).fill(""))
    };
  }, [otp]);

  // console.log(user)
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        onHide={() => {
          setOtp(new Array(6).fill(""));
        }}
      ></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Enter your PIN to Confirm</h2>
        <p>Enter your Mongoro PIN to confirm action</p>

        <div className="">
          <div className="content verify-account-form-container w-100 pin-container">
            <div className="email-sms-otp">
              <div className="email-otp">
                {/* <p className="mt-3">Create PIN</p> */}
                <div className="otp-box mb-4">
                  {otp.map((data, index) => {
                    return (
                      <input
                        className="otp-field"
                        type="password"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={(e) => {
                          handleChange(e.target, index, "email");
                        }}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={(e) => {
                          if (e.keyCode === 8) {
                            // handleDelete(e, index)
                          } else if (e.key === "ArrowLeft") {
                            e.target.previousSibling.focus();
                          } else if (e.key === "ArrowRight") {
                            e.target.nextSibling.focus();
                          }
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            {otp.join("").length === 6 ? (
              <button
                className="mo-active-btn"
                onClick={() => {
                  setProfile({
                    ...profile,
                    deleteAccount: {
                      ...profile.deleteAccount,
                      enterPassword: true,
                      enterPin: false,
                      payload: {
                        ...profile.deleteAccount.payload,
                        pin: otp.join(""),
                      },
                    },
                  });

                  setOtp(new Array(6).fill(""));
                }}
              >
                Confirm
              </button>
            ) : (
              <button className="mo-inactive-btn">Confirm PIN</button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
