import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import kyc from "../../../icons/kyc.png";
import "../../../styles/modals.scss";

export default function EnterPin(props) {
  const [modalShow, setModalShow] = useState(false);
  const { user, name, url, accountDetails, setAccountDetails, handleGetUser } =
    useContext(variableManager);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [verify, setVerify] = useState(new Array(6).fill(""));
  const [error, setError] = useState("");

  const {
    code,
    handleRegister,
    success,
    setSuccess,
    handleSendtoUser,
    handleSendtoBank,
    setMongoroReceiver,
    mongoroReceiver,
  } = useContext(variableManager);

  const { setShowCreate, showCreate, payModalShow, setPayModalShow } =
    useContext(KycManager);

  const navigate = useNavigate();

  const handleChange = (element, index, toMap) => {
    if (isNaN(element.value)) return false;

    if (toMap === "email") {
      setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    } else if (toMap === "verify") {
      setVerify([
        ...verify.map((d, idx) => (idx === index ? element.value : d)),
      ]);
    }
    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  async function handleCreatePin() {
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzZjM5OGE3MTAyNmRjZDA2N2U1MDBiOCIsImlzdmVyaWZpZWQiOmZhbHNlLCJpYXQiOjE2NzY5MDg3NDEsImV4cCI6MTY3NjkxOTU0MX0.noi0DCgq_qKUJdLzXz2SC_xo-2tRf2_wbFrKLQT-dR4";

    const header = {
      headers: { token: `Bearer ${name.token}` },
    };
    try {
      await axios
        .post(
          `${url}/user/create_pin`,
          { id: user._id, pin: otp.join("").toString() },
          header
        )
        .then((resp) => { 
          if (resp.data.user) {
            handleGetUser(
              localStorage.getItem("mongoro_user"),
              localStorage.getItem("mg-u-tkn")
            );

            //  localStorage.removeItem("mongoro_user")
            //  localStorage.setItem("mongoro_user", JSON.stringify(resp.data.user))
            setShowCreate({ ...showCreate, pin: false });
            navigate("/user/dashboard");
          }
        });
    } catch (error) { 
    }
  }

  useEffect(() => {
    setAccountDetails({ ...accountDetails, pin: otp.join("") });
    setMongoroReceiver({ ...mongoroReceiver, pin: otp.join("") });
  }, [otp]);
 
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // scrollable="false"
    >
      <Modal.Header closeButton
      onHide={()=>{
        setOtp(new Array(6).fill(""))
      }}
      ></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Enter your PIN to </h2>

        <div className="">
          <div className="content verify-account-form-container w-100 pin-container">
            <div className="email-sms-otp">
              <div className="email-otp">
                {/* <p className="mt-3">Create PIN</p> */}
                <div className="otp-box mb-4">
                  {otp.map((data, index) => {
                    return (
                      <input
                        className="otp-field"
                        type="password"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={(e) => {
                          handleChange(e.target, index, "email");
                        }}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={(e) => {
                          if (e.keyCode === 8) {
                            // handleDelete(e, index)
                          } else if (e.key === "ArrowLeft") {
                            e.target.previousSibling.focus();
                          } else if (e.key === "ArrowRight") {
                            e.target.nextSibling.focus();
                          }
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            {otp.join("").length === 6 ? (
              <button
                className="mo-active-btn"
                onClick={() => {
                  //  setAccountDetails({...accountDetails, pin:otp.join("")})
                  // setPayModalShow({...initPayModal, payNow:false})
                  if (payModalShow.toBank) {
                    handleSendtoBank();
                    setShowCreate({ ...showCreate, enterPin: false });
                    setOtp(new Array(6).fill(""));
                  } else if (payModalShow.payNow) {
                    handleSendtoUser();
                    setShowCreate({ ...showCreate, enterPin: false });
                    // setOtp(new Array(6).fill(""))
                  }
                }}
              >
                Confirm
              </button>
            ) : (
              <button className="mo-inactive-btn">Confirm PIN</button>
            )}

            {success.message && <p className="error">{success.message}</p>}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
