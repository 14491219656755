import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import success from "../../icons/success_vector.svg";
import close from "../../icons/close.svg";
import "../../styles/modals.scss";
import { KycManager } from "../../context-api/KycContext";
export default function BvnSuccess(props) {
  const {
    payModalShow,
    setPayModalShow,
    initPayModal,
    showVeriSuccess,
    setShowVeriSuccess,
    setModalShowAccount,setModalShowAccountType,
    setModalShowSelectMethod,
  } = useContext(KycManager);
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>
              setShowVeriSuccess({ ...showVeriSuccess, bvnSuccess: false })
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <center>
            <div className="icon">
              <img src={success} alt="transfer successful" />
            </div>
            <div className="detail">
              <h5 className="mo-info">BVN Added Successfully</h5>
              <p>Your BVN has been received and verified</p>
            </div>

            <div className="button-section">
              <button
                className="mo-active-btn mb-1"
                onClick={() => {
                  setModalShowSelectMethod(true);
                  setShowVeriSuccess({ ...showVeriSuccess, bvnSuccess: false });
                }}
              >
                Continue Verification
              </button>

              <button
                className="mo-skip-btn"
                onClick={() => {
                  setShowVeriSuccess({ ...showVeriSuccess, bvnSuccess: false });
                  setModalShowAccountType(true);
                }}
              >
                Skip
              </button>
            </div>
          </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}
