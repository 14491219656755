import LoginForm from "../components/forms/LoginForm"; 
import SideFrame from "../components/signup/SideFrame";
import signup_frame from "../images/signup-frame.svg"

// import "../styles/login.scss"
export default function Login (){
    return(
        <div className="create-account-container">
        {/* <div className="background-area">
            <img src={signup_frame} alt="Best solutions" />
        </div> 
  */}
  <SideFrame/>
        {<LoginForm />}  

    </div>
    )
}