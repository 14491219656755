import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../../context-api/KycContext";
import "../../../styles/settings.scss"; 
import "../../../styles/uploadImage.scss";  

import UploadImageForm from "../../forms/UploadImageForm";

export default function UploadImage(props) {
    return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent hide-on-modal">
        <UploadImageForm/>
       </Modal.Body>
    </Modal>
  );
}
