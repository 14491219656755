import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import success from "../../../icons/ticket_success.svg";
// import success from "../../../icons/success.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";
export default function CreateTicketSuccess(props) {
  const { setShowSupport,
    showSupport, } =
    useContext(KycManager);
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>
                
                setShowSupport({
                    ...showSupport,
                    ticketSuccess:false,
                  })
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <center>
            <div className="icon">
              <img src={success} alt="transfer successful" />
            </div>
            <div className="detail">
                <h5>Ticket Successfully Created</h5>
                <p>Your request has been logged and ticket generated.</p>
                
            </div>
            <button className="mo-active-btn mt-3"
            onClick={()=>{
                setShowSupport({
                    ...showSupport,
                    ticketSuccess:false,
                  })
            }}
            >Okay</button>
          </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}
