import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../context-api/KycContext";
import "../../styles/modals.scss";
import add_photo from "../../icons/add_photo.png";
import { variableManager } from "../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";
export default function KycPassport(props) {
  const { setModalShowAccount, setModalShowPassport } = useContext(KycManager);
  const {
    msg,
    handleImageChange,
    image,
    setImage,
    handleIdentityUpload,
    user,
  } = useContext(variableManager);
  const [nin, setNin] = useState("");
  const [error, setError] = useState("");
  const [idDetails, setIdDetails] = useState({
    expireAt: "",
    idNumber: "",
  });

  function handleChange(event) {
    setError("");
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.type === "file"
        ? event.target.files[0]
        : event.target.value;
    setIdDetails({
      ...idDetails,
      [event.target.name]: value,
    });
  }

  function handleCardValidity(expiry_date) {
    const today = new Date().getTime();
    expiry_date = new Date(expiry_date).getTime();

    if (today > expiry_date) {
      setError(
        "Your Identity document has expired. Kindly provide a valid document"
      );
    } else {
      //post data
      handleIdentityUpload(idDetails, "passport");
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        onHide={() => {
          setImage({ ...image, toDisplay: "" });
          setError("");
        }}
      ></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>International Passport</h2>
        <p className="small-p">
          Please Upload your International Passport Here
        </p>

        <div className="input-area">
          <div className="label">
            <label htmlFor="enterEvn">ID Number</label>
          </div>

          <div className="input">
            <input
              type="text"
              id="enterBvn"
              name="idNumber"
              placeholder="Enter Number"
              onChange={(e) => {
                handleChange(e);
                // setIdDetails({
                //   ...idDetails,
                //   idNumber: e.target.value,
                //   // firstName: user.firstName,
                //   // lastName: user.surname,
                //   // userId: user._id,
                // });
              }}
            />
          </div>

          <div className="label">
            <label htmlFor="enterEvn">Expiry Date</label>
          </div>

          <div className="input">
            <input
              type="date"
              id="enterBvn"
              name="expireAt"
              placeholder="mm/yyyy"
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="upload-image-container upload-id-field ">
            <p className="mt-3 small-p">Upload photo of ID</p>

            {image.toDisplay ? (
              <div className="file-input-area">
                {image.toDisplay && (
                  <img
                    src={image.toDisplay}
                    alt="international passport"
                    className="img-flui"
                  />
                )}

                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="upload-image"
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                />
              </div>
            ) : (
              <div className="file-input-area">
                <label className="label" htmlFor="upload-image">
                  <p className="drag small-p">Upload</p>
                  <p className="small-p">or</p>
                  <p className="text-primary small-p">Browse files</p>
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="upload-image"
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                />
              </div>
            )}
            <p className="mt-3 small-p">Accepted format: jpeg, PNG</p>
          </div>
        </div>
        {error && <p className="error">{error}</p>}

        <div className="buttons">
          <button
            disabled={
              msg.spinner ||
              !idDetails.idNumber ||
              !image.toDisplay ||
              !idDetails.expireAt
                ? "{true}"
                : ""
            }
            className={
              image.toDisplay && idDetails.expireAt
                ? "btn-active"
                : "btn-inactive"
            }
            onClick={() => {
              handleCardValidity(idDetails.expireAt);
            }}
          >
            {msg.spinner ? <Spinner size="sm" /> : "Confirm"}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
