import "../../styles/notifications.scss";
import new_notification from "../../icons/new_notification.svg";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { variableManager } from "../../context-api/VariableContex";

export default function SummaryView() {
  const navigate = useNavigate();
  const { notifications, handleReadNotification, setReadNotification } =
    useContext(variableManager);
  return (
    <div className="notification-modal-container">
      <h5>Notifications</h5>

      {/* <nav>
        <button>All</button>
        <button>Activity</button>
        <button>Messages</button>
      </nav> */}

      <div className="body">
        {notifications && notifications.notifications.length
          ? notifications.notifications.map((e, i) => {
              if (i <= 5) {
                return (
                  <div className="item" key={i}>
                    <div className="date">
                      <b>{new Date(e.date).toDateString()}</b>
                    </div>
                    {e.notifications.map((each, i) => {
                      if (i <= 4) {
                        return (
                          <div
                            className={
                              each.status
                                ? "read notification "
                                : "unread notification "
                            }
                            key={i}
                            onClick={()=>{handleReadNotification(each.nid); each.status=1}}
                          >
                            <div className="icon">
                              <img src={new_notification} alt="notification" />
                            </div>
                            <div className="detail">
                              <b className="title">{each.title}</b>
                              <p className="message">{each.body}</p>
                            </div>

                            {/* <div className={each.status?"viewed":"view"}>
                              <button
                                onClick={() => {
                                  handleReadNotification(each._id);
                                  setReadNotification(true);
                                }}
                              >
                                View
                              </button>
                            </div> */}
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              }
            })
          : null}

        {/* <div className="item">
          <div className="date">
            <b>Yesterday</b>
          </div>
          <div className="notification">
            <div className="icon">
                <img src={new_notification} alt="notification" />
            </div>
            <div className="detail">
                <b className="title">Account Upgrade Failed</b>
                <p className="message">We are unable to verify your KYC documents provided</p>
            </div>

            <div className="view">
                <button>View</button>
            </div>
          </div>

          <div className="notification">
            <div className="icon">
                <img src={new_notification} alt="notification" />
            </div>
            <div className="detail">
                <b className="title">Transfer</b>
                <p className="message">Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo, dignissimos.</p>
            </div>

            <div className="view">
                <button>View</button>
            </div>
          </div>

        </div>



        <div className="item">
          <div className="date">
            <b>Oct 3, 2022</b>
          </div>
          <div className="notification">
            <div className="icon">
                <img src={new_notification} alt="notification" />
            </div>
            <div className="detail">
                <b className="title">Account Upgrade Failed</b>
                <p className="message">We are unable to verify your KYC documents provided</p>
            </div>

            <div className="view">
                <button>View</button>
            </div>
          </div>

        </div> */}
      </div>

      <footer>
        <center>
          <b
            className="pointer"
            onClick={() => navigate("/user/dashboard/notifications")}
          >
            View all
          </b>
        </center>
      </footer>
    </div>
  );
}
