import "../../styles/createAccount.scss";
import { Link, useNavigate } from "react-router-dom"; 
import { useContext,  useState } from "react";
import { variableManager } from "../../context-api/VariableContex";
import {
  validEmail,
  validName,
  validPassword,
  validUsername,
} from "../regex/Regex";
import { Spinner } from "react-bootstrap";
import eye from "../../icons/eye.svg";
import no_eye from "../../icons/no_eye.svg";
import { KycManager } from "../../context-api/KycContext";
import axiosInstance from "../../utils/axios_instance";

export default function SignupForm() {
  const navigate = useNavigate();
  const { setShowTerms } = useContext(KycManager);
  const [empty, setEmpty] = useState(false); 
  const { 
    msg,
    setMsg,
    showPassword,
    setShowPassword,
    userDetails,
    setUserDetails,
    code,
    setCode,
    usernameStatus,
    setUsernameStatus,
  } = useContext(variableManager); 

  //updated with axiosInstance
  function handleVerification() {
    setMsg({ ...msg, spinner: true, error: "", success: "" });
    try {
      axiosInstance
        .post(`/auth/verify`, {
          email: userDetails.email,
          phone: userDetails.phone,
        })
        .then((resp) => { 
          setMsg({ ...msg, spinner: false, error: "", success: "" });

          if (resp.status === 200) {
            setCode({
              ...code,
              email: resp.data.data.code,
              sms: resp.data.data.code,
            });
            navigate("/signup/verification");
          } else {
            setMsg({ ...msg, spinner: false, error: "", success: "" }); 
          }
        });
    } catch (error) {
      setMsg({ ...msg, spinner: false, error: "", success: "" }); 
    }
  }

  function handleValidation() {
    if (
      userDetails.agreement === false ||
      userDetails.firstName === "" ||
      userDetails.surname === "" ||
      userDetails.phone === "" ||
      userDetails.passwordRepeat === "" ||
      userDetails.password === "" ||
      userDetails.walletTag === ""
    ) {
      setEmpty(true);
    } else {
      setEmpty(false);

      if (!validEmail.test(userDetails.email)) {
        setEmpty("Email is not valid");
      } else if (!validPassword.test(userDetails.password)) {
        setEmpty(
          "Both Password must be at least 8 digits, at most 16 digits, and contain at least 1 Uppercase letter, 1 lowercase letter, 1 number and a special character"
        );
      } else if (
        !validName.test(userDetails.firstName) ||
        !validName.test(userDetails.surname) ||
        (userDetails.middleName && !validName.test(userDetails.middleName))
      ) {
        setEmpty("invalid name format");
      } else if (userDetails.phone.length < 14) {
        setEmpty("Phone number cannot be less than 10 digits");
      } else if (!usernameStatus.status) {
        setEmpty("Username taken. Try a different username or try adding numbers ");
      } else if (!usernameStatus.status) {
        handleValidateUsername(userDetails.walletTag);
      } else if (userDetails.password === userDetails.passwordRepeat) {
        handleVerification();
      } else {
        setEmpty("Passwords do not match");
      }
    }
  }

  async function handleValidateUsername(username) {
    if (username !== "" && username !== null) {
      // if (username.charAt(0) === "@") {
      //   username = username;
      //   // username = username.substr(1)
      // } else {
      //   username = "@" + username;
      // }
      if (!validUsername.test(userDetails.walletTag)) {
        return setUsernameStatus({
          ...usernameStatus,
          error:
            "username cannot be less than four digits or more than 16 digits",
        });
      }

      try {
        setUsernameStatus({ ...usernameStatus, checking: true });
        await axiosInstance
          .post(`/auth/verifyTag`, { username: username })
          .then((resp) => {
            setUsernameStatus({
              ...usernameStatus,
              checking: false,
              error: "",
              success: resp.data.message,
              status: true,
            });
          });
      } catch (error) {
        setUsernameStatus({
          ...usernameStatus,
          checking: false,
          error: error.response.data.message,
          success: "",
          status: false,
        });
      }
    } else {
      setUsernameStatus({
        ...usernameStatus,
        error: "username cannot be empty",
      });
    }
  }

  function handleChange(event) {
    setEmpty(false);
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setUserDetails({
      ...userDetails,
      [event.target.name]: value,
    });
  }

  return (
    <div className="signup-form-container ">
      <div className="login-redirect-link d-flex justify-content-end w-75 mt-3">
        Already have an account?{" "}
        <b className="pointer">
          {" "}
          &nbsp;{" "}
          <Link className="text-dark" to={"/login"}>
            Log in
          </Link>
        </b>
      </div>
      <div className="form-area">
        <div className="content">
          <h2>Welcome to Mongoro</h2>
          <p className="mantra">Your world of payment options.</p>
          <div className="input-area">
            <div className="label">
              <label htmlFor="firstName">First Name</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="Olajide "
                value={userDetails.firstName}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {empty && userDetails.firstName === "" ? (
              <div className="mo-danger">First name is required</div>
            ) : null}
          </div>
          <div className="input-area">
            <div className="label">
              <label htmlFor="last_name">Middle Name</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="middleName"
                name="middleName"
                placeholder="Abubakar"
                value={userDetails.middleName}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="input-area">
            <div className="label">
              <label htmlFor="last_name">Last Name</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="last_name"
                name="surname"
                placeholder=" Chibuike"
                value={userDetails.surname}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {empty && userDetails.surname === "" ? (
              <div className="mo-danger">Last name is required</div>
            ) : null}
          </div>
          <div className="input-area">
            <div className="label">
              <label htmlFor="phone">Phone Number</label>
            </div>
            <div className="input phone">
              <select
                name="country-code"
                id="country-code"
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    countryCode: e.target.value,
                  })
                }
                // onChange={(e) => setNumber({ ...number, code: e.target.value })}
              >
                <option value="+234">+234</option>
                <option value="+44">+44</option>
                <option value="+65">+65</option>
                <option value="+84">+84</option>
              </select>
              <input
                type="tel"
                id="phone"
                name="phone"
                pattern={[0 - 9]}
                placeholder="8143626356"
                maxLength="10"
                minLength="10"
                value={userDetails.phone ? userDetails.phone.substring(4) : ""}
                //  onChange={(e) => handleChange(e)}
                onChange={(e) => { 

                  if (
                    e.target.value.charAt(0) === "0" ||
                    e.target.value.charAt(0) === 0
                  ) {
                    e.target.value = e.target.value.substring(1);
                  }
 

                  setUserDetails({
                    ...userDetails,
                    countryCode: userDetails.countryCode,
                    phone: `${userDetails.countryCode}${e.target.value}`,
                  });
                }}
              />
            </div>
            {(empty && userDetails.phone === 0) || userDetails.phone === "" ? (
              <div className="mo-danger">Phone number is required</div>
            ) : null}
          </div>
          <div className="input-area">
            <div className="label">
              <label htmlFor="email">Email Address</label>
            </div>
            <div className="input">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="website@mongoro.com"
                value={userDetails.email}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {empty && userDetails.email === "" ? (
              <div className="mo-danger">Email is required</div>
            ) : null}
            {/* { (empty && empty !==true) && (<p className="mo-danger">{empty}</p>)} */}
          </div>
          <div className="input-area">
            <div className="label">
              <label htmlFor="walletTag">Username</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="walletTag"
                name="walletTag"
                placeholder="olajide283"
                onKeyDown={(e) => {
                  if (e.keyCode === 32) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  handleChange(e);
                  setUsernameStatus({
                    ...usernameStatus,
                    status: false,
                    success: "",
                    error: "",
                  });
                }}
                value={userDetails.walletTag}
                onBlur={(e) => handleValidateUsername(e.target.value)}
              />
            </div>
            {usernameStatus.checking ? (
              <Spinner size="sm" animation="grow" />
            ) : usernameStatus.success ? (
              <div className="mo-success"> {usernameStatus.success}</div>
            ) : usernameStatus.error ? (
              <div className="mo-danger">{usernameStatus.error}</div>
            ) : null}

            {empty && userDetails.walletTag === "" ? (
              <div className="mo-danger">Username is required</div>
            ) : null}
          </div>
          <div className="input-area">
            <div className="label">
              <label htmlFor="password">Choose a Password</label>
            </div>
            <div className="input">
              <input
                type={showPassword.password ? "text" : "password"}
                id="password"
                name="password"
                placeholder="***************"
                value={userDetails.password}
                onChange={(e) => handleChange(e)}
              />

              {showPassword.password ? (
                <img
                  className="eye pointer"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      password: !showPassword.password,
                    })
                  }
                  draggable="false"
                  src={no_eye}
                  alt="view password"
                />
              ) : (
                <img
                  className="eye pointer"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      password: !showPassword.password,
                    })
                  }
                  draggable="false"
                  src={eye}
                  alt="view password"
                />
              )}
            </div>
            {empty && userDetails.password === "" ? (
              <div className="mo-danger">Password is required</div>
            ) : null}
          </div>
          <div className="input-area">
            <div className="label">
              <label htmlFor="confirm-password">Retype Password</label>
            </div>
            <div className="input">
              <input
                type={showPassword.confirm ? "text" : "password"}
                id="confirm-password"
                name="passwordRepeat"
                placeholder="****************"
                value={userDetails.passwordRepeat}
                onChange={(e) => handleChange(e)}
              />
              {showPassword.confirm ? (
                <img
                  className="eye pointer"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      confirm: !showPassword.confirm,
                    })
                  }
                  draggable="false"
                  src={no_eye}
                  alt="view password"
                />
              ) : (
                <img
                  className="eye pointer"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      confirm: !showPassword.confirm,
                    })
                  }
                  draggable="false"
                  src={eye}
                  alt="view password"
                />
              )}
            </div>
            {empty && userDetails.passwordRepeat === "" ? (
              <div className="mo-danger">Field is required</div>
            ) : null}
            {empty && empty !== true && <p className="mo-danger">{empty}</p>}
          </div>
          <div className="terms-agreement-area">
            <div className="checkbok">
              <input
                type="checkbox"
                name="agreement"
                id="agreement"
                value={userDetails.agreement}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <p className="pointer" style={{ userSelect: "none" }}>
              <label htmlFor="agreement"> I agree to</label>{" "}
              <span className="link" onClick={() => setShowTerms(true)}>
                Mongoro's Terms
              </span>{" "}
              <label htmlFor="agreement">of Use and content to </label>
              <span className="link" onClick={() => setShowTerms(true)}>
                {" "}
                Mongoro's Privacy Statement
              </span>{" "}
            </p>
          </div>
          {empty && userDetails.agreement === false ? (
            <div className="mo-danger" style={{ fontSize: "12px" }}>
              You need to agree to our Terms and Policy to signup
            </div>
          ) : null}{" "}
          <br />
          {msg.error && <p className="error">{msg.error}</p>}
          <button
            disabled={msg.spinner ? "{true}" : ""}
            onClick={() => {
              handleValidation();
            }}
          >
            {msg.spinner ? <Spinner size="sm" /> : "Create account"}
          </button>
        </div>
      </div>
    </div>
  );
}
