import { useContext } from "react";
import { KycManager } from "../../context-api/KycContext";
import Navigation from "../dashboard/Navigation";
import Sidebar from "../dashboard/Sidebar";
import VerificationStatus from "../dashboard/VerificationStatus";

import Tiers from "./Tiers";

export default function AccountUpgrade() {
const {showSidebar} = useContext(KycManager)

  return (
    <div className="dashboard-container">
      <div className="navigation-section">
        <Navigation />
      </div>
      <div className="body-section">
        <div className={showSidebar?"sidebar":"hide-sidebar"}>
          <Sidebar />
        </div>
        <div className="dashboard-body">
          <VerificationStatus />

          <Tiers />
        </div>
      </div>

      <div className="modals">
   
      </div>
    </div>
  );
}
