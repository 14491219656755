import "../../styles/account_upgrade.scss";
import { FaChevronRight } from "react-icons/fa";
import { useContext } from "react";
import { variableManager } from "../../context-api/VariableContex";
import { KycManager } from "../../context-api/KycContext";
import KycBvn from "../modals/KycBvn";
import KycNIN from "../modals/KycNIN";
import KycPassport from "../modals/KycPassport";
import KycDriversLicense from "../modals/KycDriversLicense";
import SelectMeans from "../modals/SelectMeans";
import KycStatus from "../modals/KycStatus";
import KycPVC from "../modals/KycPVC";
import KycIndemnity from "../modals/KycIndemnity";
import { useNavigate } from "react-router-dom";

export default function Tiers() {
  const { user } = useContext(variableManager);
  const {
    setModalShowPrompt,
    modalShowNin,
    setModalShowNin,
    modalShowBvn,
    setModalShowBvn,
    modalShowPassport,
    setModalShowPassport,
    modalShowDriversLicense,
    setModalShowDriversLicense,
    modalShowPvc,
    setModalShowPvc,
    modalShowSelectMethod,
    setModalShowSelectMethod,
    showIndemnity,
    setShowIndemnity,
    showVeriSuccess,
    setShowVeriSuccess,
  } = useContext(KycManager);
  const navigate = useNavigate();
  return (
    <div className="tiers-container mt-3 mt-b">
      <h5>Account Upgrade</h5>

      <div className="tiers-section">
        {/* TIER 1 */}
        <div className="tier">
          {user && user.verificationBvn ? (
            <p className="kyc-status kyc-success">
              <span>Verified</span>
            </p>
          ) : (
            <p className="kyc-status kyc-review">Unverified</p>
          )}
          <div className="icon">
            <svg
              width="96"
              height="96"
              viewBox="0 0 96 96"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_4105_167394)">
                <circle cx="48" cy="48" r="28" fill="white" />
              </g>
              <path
                d="M39 60C39 57.5752 39.9725 55.2496 41.7036 53.535C43.4347 51.8204 45.7826 50.8571 48.2308 50.8571C50.6789 50.8571 53.0268 51.8204 54.7579 53.535C56.489 55.2496 57.4615 57.5752 57.4615 60H55.1538C55.1538 58.1814 54.4245 56.4372 53.1261 55.1513C51.8278 53.8653 50.0669 53.1429 48.2308 53.1429C46.3947 53.1429 44.6337 53.8653 43.3354 55.1513C42.0371 56.4372 41.3077 58.1814 41.3077 60H39ZM48.2308 49.7143C44.4058 49.7143 41.3077 46.6457 41.3077 42.8571C41.3077 39.0686 44.4058 36 48.2308 36C52.0558 36 55.1538 39.0686 55.1538 42.8571C55.1538 46.6457 52.0558 49.7143 48.2308 49.7143ZM48.2308 47.4286C50.7808 47.4286 52.8462 45.3829 52.8462 42.8571C52.8462 40.3314 50.7808 38.2857 48.2308 38.2857C45.6808 38.2857 43.6154 40.3314 43.6154 42.8571C43.6154 45.3829 45.6808 47.4286 48.2308 47.4286Z"
                fill="#292929"
              />
              <defs>
                <filter
                  id="filter0_d_4105_167394"
                  x="0"
                  y="0"
                  width="96"
                  height="96"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="10" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.7875 0 0 0 0 0.7875 0 0 0 0 0.7875 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4105_167394"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_4105_167394"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>{" "}
          </div>
          <p className="level">Tier 1</p>

          <div className="details">
            <div className="item">
              <p className="title">Account Setup</p>
              <p className="status">
                {" "}
                {user && user.setupComplete ? (
                  "Done"
                ) : (
                  <span className="pending">Pending</span>
                )}
              </p>
            </div>

            <div className="item">
              <p className="title">BVN Verification</p>
              <p className="status">
                {user && user.verificationBvn ? (
                  "Done"
                ) : (
                  <span className="pending">Pending</span>
                )}
              </p>
            </div>

            <div className="item">
              <p className="title">Single Transaction Limit</p>
              <p className="limit">NGN20, 000</p>
            </div>

            <div className="item">
              <p className="title">Daily Cummulative Limit</p>
              <p className="limit">NGN100, 000</p>
            </div>
          </div>

          {user && (!user.verificationBvn || !user.setupComplete) ? (
            <div
              className="to-do pointer"
              onClick={(e) => {
                e.preventDefault();
                if (!user.setupComplete) {
                  setModalShowPrompt(true);
                  navigate("/user/dashboard");
                } else if (!user.verificationBvn) {
                  setModalShowBvn(true);
                  navigate("/user/dashboard");
                }
              }}
            >
              To Do <FaChevronRight className="right-arrow" />{" "}
            </div>
          ) : null}
        </div>

        {/* TIER 2 */}
        <div
          className={
            user &&
            user.verificationBvn &&
            user.idDoc !== 0 &&
            user.idDoc !== 1
              ? "tier"
              : "inactive-kyc"
          }
        > 
          {user && user.idDoc === 2 ? (
            <p className="kyc-status kyc-success">
              <span>Verified</span>
            </p>
          ) : (
            <p className=" kyc-status kyc-review">
              <span>Unverified</span>
            </p>
          )} 
          <div className="icon">
            <svg
              width="96"
              height="96"
              viewBox="0 0 96 96"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_4105_167394)">
                <circle cx="48" cy="48" r="28" fill="white" />
              </g>
              <path
                d="M39 60C39 57.5752 39.9725 55.2496 41.7036 53.535C43.4347 51.8204 45.7826 50.8571 48.2308 50.8571C50.6789 50.8571 53.0268 51.8204 54.7579 53.535C56.489 55.2496 57.4615 57.5752 57.4615 60H55.1538C55.1538 58.1814 54.4245 56.4372 53.1261 55.1513C51.8278 53.8653 50.0669 53.1429 48.2308 53.1429C46.3947 53.1429 44.6337 53.8653 43.3354 55.1513C42.0371 56.4372 41.3077 58.1814 41.3077 60H39ZM48.2308 49.7143C44.4058 49.7143 41.3077 46.6457 41.3077 42.8571C41.3077 39.0686 44.4058 36 48.2308 36C52.0558 36 55.1538 39.0686 55.1538 42.8571C55.1538 46.6457 52.0558 49.7143 48.2308 49.7143ZM48.2308 47.4286C50.7808 47.4286 52.8462 45.3829 52.8462 42.8571C52.8462 40.3314 50.7808 38.2857 48.2308 38.2857C45.6808 38.2857 43.6154 40.3314 43.6154 42.8571C43.6154 45.3829 45.6808 47.4286 48.2308 47.4286Z"
                fill="#292929"
              />
              <defs>
                <filter
                  id="filter0_d_4105_167394"
                  x="0"
                  y="0"
                  width="96"
                  height="96"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="10" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.7875 0 0 0 0 0.7875 0 0 0 0 0.7875 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4105_167394"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_4105_167394"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>{" "}
          </div>
          <p className="level">Tier 2</p>
          <div className="details">
            <div className="item">
              <p className="title">Account Setup</p>
              <p className="status">
                {user && user.setupComplete ? (
                  "Done"
                ) : (
                  <span className="pending">Pending</span>
                )}
              </p>
            </div>

            <div className="item">
              <p className="title">BVN Verification</p>
              <p className="status">
                {user && user.verificationBvn ? (
                  "Done"
                ) : (
                  <span className="pending">Pending</span>
                )}
              </p>
            </div>
            <div className="item">
              <p className="title">ID Verification</p>
              <p className="status">
                {user && user.idDoc === 2 ? (
                  "Done"
                ) : user && user.idDoc === 1 ? (
                  "In review"
                ) : (
                  <span className="pending">Pending</span>
                )}
              </p>
            </div>

            <div className="item">
              <p className="title">Single Transaction Limit</p>
              <p className="limit">NGN1, 000, 000</p>
            </div>

            <div className="item">
              <p className="title">Daily Cummulative Limit</p>
              <p className="limit">NGN5, 000, 000</p>
            </div>
            <div className="item">
              <p className="title"> </p>
              <p className="limit"> </p>
            </div>
          </div>

          {user &&
          user.verificationBvn &&
          user.idDoc !== 2 &&
          user.idDoc !== 1 ? (
            <div
              className="to-do pointer"
              onClick={() => setModalShowSelectMethod(true)}
            >
              To Do <FaChevronRight className="right-arrow" />{" "}
            </div>
          ) : null}
        </div>







        {/* TIER 3 */}
        <div className={user && user.indemnity ? "tier" : "inactive-kyc"}>
        {user && user.indemnity ? (
            <p className="kyc-status kyc-success">
              <span>Verified</span>
            </p>
          ) : (
            <p className=" kyc-status kyc-review">
              <span>Unverified</span>
            </p>
          )}
          <div className="icon">
            <svg
              width="96"
              height="96"
              viewBox="0 0 96 96"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_4105_167394)">
                <circle cx="48" cy="48" r="28" fill="white" />
              </g>
              <path
                d="M39 60C39 57.5752 39.9725 55.2496 41.7036 53.535C43.4347 51.8204 45.7826 50.8571 48.2308 50.8571C50.6789 50.8571 53.0268 51.8204 54.7579 53.535C56.489 55.2496 57.4615 57.5752 57.4615 60H55.1538C55.1538 58.1814 54.4245 56.4372 53.1261 55.1513C51.8278 53.8653 50.0669 53.1429 48.2308 53.1429C46.3947 53.1429 44.6337 53.8653 43.3354 55.1513C42.0371 56.4372 41.3077 58.1814 41.3077 60H39ZM48.2308 49.7143C44.4058 49.7143 41.3077 46.6457 41.3077 42.8571C41.3077 39.0686 44.4058 36 48.2308 36C52.0558 36 55.1538 39.0686 55.1538 42.8571C55.1538 46.6457 52.0558 49.7143 48.2308 49.7143ZM48.2308 47.4286C50.7808 47.4286 52.8462 45.3829 52.8462 42.8571C52.8462 40.3314 50.7808 38.2857 48.2308 38.2857C45.6808 38.2857 43.6154 40.3314 43.6154 42.8571C43.6154 45.3829 45.6808 47.4286 48.2308 47.4286Z"
                fill="#292929"
              />
              <defs>
                <filter
                  id="filter0_d_4105_167394"
                  x="0"
                  y="0"
                  width="96"
                  height="96"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="10" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.7875 0 0 0 0 0.7875 0 0 0 0 0.7875 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4105_167394"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_4105_167394"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          <p className="level">Tier 3</p>

          <div className="details">
            <div className="item">
              <p className="title">Account Setup</p>

              <p className="status">
                {user && user.setupComplete ? (
                  "Done"
                ) : (
                  <span className="pending">Pending</span>
                )}
              </p>
            </div>

            <div className="item">
              <p className="title">BVN Verification</p>
              <p className="status">
                {user && user.verificationBvn ? (
                  "Done"
                ) : (
                  <span className="pending">Pending</span>
                )}
              </p>
            </div>
            <div className="item">
              <p className="title">ID Verification</p>
              <p className="status">
                {user && user.idDoc === 2 ? (
                  "Done"
                ) : user && user.idDoc === 1 ? (
                  "In review"
                ) : (
                  <span className="pending">Pending</span>
                )}
              </p>
            </div>
            <div className="item">
              <p className="title">Indemnity</p>
              <p className="status">
                {user && user.indemnity ? (
                  "Done"
                ) : (
                  <span className="pending">Pending</span>
                )}
              </p>
            </div>

            <div className="item">
              <p className="title">Single Transaction Limit</p>
              <p className="limit">NGN10, 000, 000</p>
            </div>

            <div className="item">
              <p className="title">Daily Cummulative Limit</p>
              <p className="limit">NGN10, 000, 000</p>
            </div>
          </div>

          {user && user.idDoc === 2 && !user.indemnity? (
            <div
              className="to-do mo-danger pointer"
              onClick={() => setShowIndemnity(true)}
            >
              To Do <FaChevronRight className="right-arrow" />{" "}
            </div>
          ) : null}
        </div>
      </div>

      <div className="modals">
        <KycBvn
          show={modalShowBvn}
          onHide={() => setModalShowBvn(false)}
          backdrop={"static"}
        />

        <KycNIN
          show={modalShowNin}
          onHide={() => setModalShowNin(false)}
          backdrop={"static"}
        />

        <KycPassport
          show={modalShowPassport}
          onHide={() => setModalShowPassport(false)}
          backdrop={"static"}
        />

        <KycDriversLicense
          show={modalShowDriversLicense}
          onHide={() => setModalShowDriversLicense(false)}
          backdrop={"static"}
        />
        <KycPVC
          show={modalShowPvc}
          onHide={() => setModalShowPvc(false)}
          backdrop={"static"}
        />

        <KycStatus
          show={showVeriSuccess.idDoc}
          onHide={() =>
            setShowVeriSuccess({ ...showVeriSuccess, idDoc: false })
          }
          // backdrop={""}
        />

        <SelectMeans
          show={modalShowSelectMethod}
          onHide={() => setModalShowSelectMethod(false)}
          backdrop={"static"}
        />
        <KycIndemnity
          show={showIndemnity}
          onHide={() => setShowIndemnity(false)}
          backdrop={"static"}
        />
      </div>
    </div>
  );
}
