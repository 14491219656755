import "../../../styles/settings.scss";
import { useContext, useEffect } from "react";
import { KycManager } from "../../../context-api/KycContext";
import ProfileSettings from "./ProfileSettings";
import SecuritySettings from "./SecuritySettings";
import NotificationSettings from "./NotificationSettings";
import LoginActivity from "./LoginActivity";
import BankAddSuccess from "../../modals/settings/BankAddSuccess";
import DeletePrompt from "../../modals/settings/DeletePrompt";
import UploadImage from "../../modals/settings/UploadImage";
import VerifyEmailSms from "../../modals/settings/VerifyEmailSms";
import CreatePin from "../../modals/security/CreatePin";
import CreatePinSuccess from "../../modals/security/CreatePinSuccess";
import ChangePassword from "../../modals/settings/ChangePassword";

export default function SettingsComponent() {
  const { navselect, setNavselect, profile, setProfile } =
    useContext(KycManager);

  useEffect(() => {
    return () => {
      setNavselect({
        ...navselect,
        profile: true,
        security: false,
        notification: false,
      });
    };
  }, []);

  return (
    <div className="settings-container">
      <h5>Settings</h5>

      <div className="list-body">
        <nav>
          <button
            className={navselect.profile ? " active-nav-btn" : "nav-btn"}
            onClick={(e) => {
              setNavselect({
                ...navselect,
                profile: true,
                security: false,
                notification: false,
              });
            }}
          >
            Profile
          </button>
          <button
            className={navselect.security ? " active-nav-btn" : "nav-btn"}
            onClick={(e) =>
              setNavselect({
                ...navselect,
                security: true,
                profile: false,
                notification: false,
              })
            }
          >
            Security
          </button>
          <button
            className={navselect.notification ? " active-nav-btn" : "nav-btn"}
            onClick={(e) =>
              setNavselect({
                ...navselect,
                notification: true,
                security: false,
                profile: false,
              })
            }
          >
            Notification
          </button>
        </nav>

        {navselect.profile ? (
          <ProfileSettings />
        ) : navselect.security ? (
          <SecuritySettings />
        ) : navselect.notification ? (
          <NotificationSettings />
        ) : navselect.loginActivity ? (
          <LoginActivity />
        ) : null}
      </div>

      <div className="modals"></div>

      <BankAddSuccess
        show={profile.bankAddSuccess}
        onHide={() => setProfile({ ...profile, bankAddSuccess: false })}
      />

      <DeletePrompt
        show={profile.deletePrompt.bank}
        onHide={() =>
          setProfile({
            ...profile,
            deletePrompt: { ...profile.deletePrompt, bank: false },
          })
        }
      />

      <DeletePrompt
        show={profile.deletePrompt.device}
        onHide={() =>
          setProfile({
            ...profile,
            deletePrompt: { ...profile.deletePrompt, device: false },
          })
        }
      />

      <UploadImage
        show={profile.imageUpload}
        onHide={() => setProfile({ ...profile, imageUpload: false })}
      />

      <VerifyEmailSms
        show={profile.verifyEmailPhone}
        onHide={() => setProfile({ ...profile, verifyEmailPhone: false })}
      />

      <CreatePin
        show={profile.changePin || profile.resetPin}
        onHide={() =>
          setProfile({ ...profile, changePin: false, resetPin: false })
        }
      />

      <CreatePinSuccess
        show={profile.pinSuccess || profile.passwordSuccess}
        onHide={() =>
          setProfile({ ...profile, pinSuccess: false, passwordSuccess: false })
        }
      />

      <ChangePassword
        show={profile.changePassword || profile.resetPassword}
        onHide={() =>
          setProfile({
            ...profile,
            changePassword: false,
            resetPassword: false,
          })
        }
      />
    </div>
  );
}
