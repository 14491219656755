import { useContext} from "react";
// import { BsThreeDotsVertical } from "react-icons/bs";
import { variableManager } from "../../context-api/VariableContex";
// import add from "../../icons/add.png";
export default function TransactionHistory() {
  const { dashboard, nameCase } = useContext(variableManager); 

  

  function handleTruncateHistory(arr) {
    const short = [];
    for (let i = 0; i <= 4; i++) {
      if (short.includes(arr[i])) {
        return;
      } else {
        if (arr[i]) {
          short.push(arr[i]);
        }
      }
    }
    return short.length;
  }

  return (
    <div className="transaction-history-container">
      <h5>Transaction History</h5>

      <div className="table-section">
        <table>
          <thead>
            <tr>
              <td>{/* <input type="checkbox" /> */}</td>
              <td>I.D</td>
              <td>Date & Time</td>
              <td>Account Details</td>
              <td>Transaction Type</td>
              <td>Reference No:</td>
              <td>Amount</td>
              <td>Status</td>
            </tr>
          </thead>

          {dashboard.history.length ? (
            dashboard.history.map((e, i) => {
              if (i <= 4) { 
                return (
                  <tbody key={i}>
                    <tr>
                      <td>{/* <input type="checkbox" /> */}</td>
                      <td>{e.transactionID}</td>
                      <td>{new Date(e.Date).toLocaleString()}</td>
                      <td>
                        <p className="b">
                          {e.fullName
                            ? nameCase(e.fullName)
                            : e.walletTag
                            ? e.walletTag
                            : ""}
                        </p>
                        <p>{e.bankName ? nameCase(e.bankName) : ""}</p>
                      </td>
                      <td>{e.serviceType}</td>
                      <td>{e.reference}</td>
                      <td>₦{e.amount}</td>
                      <td>
                        {" "}
                        <b
                          className={
                            e.status === "Failed" || e.status === "failed"
                              ? " status text-danger "
                              : e.status === "Pending" || e.status === "pending"
                              ? "text-warning"
                              : "status"
                          }
                        >
                          {e.status}
                        </b>{" "}
                        {/* <BsThreeDotsVertical className="ms-3" /> */}
                      </td>
                    </tr>
                  </tbody>
                );
              }
            })
          ) : (
            <tbody>
              <tr>
                <td>You have no transactions history</td>
              </tr>
            </tbody>
          )}

          {/* <tbody>
                        <tr>
                            <td><input type="checkbox" /></td>
                            <td>#123412451</td>
                            <td>June 1, 2020, 08:22 AM</td>
                            <td>
                                <b>Melanie Hobs</b>
                                <p>Adeife@gmail.com</p>
                            </td>
                            <td>N5000</td>
                            <td> <b className="status">Success</b> <BsThreeDotsVertical className="ms-3" /></td>
                        </tr>

                        <tr>
                            <td><input type="checkbox" /></td>
                            <td>#123412451</td>
                            <td>June 1, 2020, 08:22 AM</td>
                            <td>
                                <b>Melanie Hobs</b>
                                <p>Adeife@gmail.com</p>
                            </td>
                            <td>N5000</td>
                            <td> <b className="status">Success</b> <BsThreeDotsVertical className="ms-3" /></td>
                        </tr>

                        <tr>
                            <td><input type="checkbox" /></td>
                            <td>#123412451</td>
                            <td>June 1, 2020, 08:22 AM</td>
                            <td>
                                <b>Melanie Hobs</b>
                                <p>Adeife@gmail.com</p>
                            </td>
                            <td>N5000</td>
                            <td> <b className="status">Success</b> <BsThreeDotsVertical className="ms-3" /></td>
                        </tr>

                        <tr>
                            <td><input type="checkbox" /></td>
                            <td>#123412451</td>
                            <td>June 1, 2020, 08:22 AM</td>
                            <td>
                                <b>Melanie Hobs</b>
                                <p>Adeife@gmail.com</p>
                            </td>
                            <td>N5000</td>
                            <td> <b className="status">Success</b> <BsThreeDotsVertical className="ms-3" /></td>
                        </tr>



                    </tbody>
                     */}
        </table>
      </div>
      <div className="add-container ">
        <div className="add-new">{/* <img src={add} alt="add new" /> */}</div>
      </div>
      {dashboard.history.length ? (
        <p className="showing">
          {" "}
          Showing{" "}
          <span className="shown">
            {handleTruncateHistory(dashboard.history)}
          </span>{" "}
          of <span className="total">{dashboard.history.length}</span> enteries
        </p>
      ) : null}
    </div>
  );
}
