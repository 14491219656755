import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import close from "../../../icons/close.svg";
import next_bg from "../../../icons/next_bg.svg";
import nga_flag from "../../../icons/nga_flag.svg";
import usa_flag from "../../../icons/usa_flag.svg";
import bank_icon from "../../../icons/bank_icon.svg";
import "../../../styles/getPaid.scss";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";
import coming_soon from "../../../icons/coming-soon.png";

export default function AccountType(props) {
   const {
    msg,
    setMsg,
    setModalShowAccount,
    modalShowAccountType,
    setModalShowAccountType, 
    handleGenerateAccount,
  } = useContext(KycManager);

  const { user } = useContext(variableManager); 

  return (
    <div className="select-mongoro-type-container">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h5 className="d-flex justify-content-start w-75 fw-bold">
            Create Account
          </h5>
          <div
            className="modal-header pointer"
            onClick={() => {
              setModalShowAccountType(false);
              setMsg({ ...msg, error: "" });
            }}
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <div className="input-are">
            {/* NAIRA ACCOUNT */}

            <div
              className="method-area mb-4"
              onClick={() => {
                if (user.account) {
                  setModalShowAccount(true);
                  setModalShowAccountType(false);
                } else {
                  handleGenerateAccount(user._id);
                }
              }}
            >
              <div className="method pointer">
                <div className="icon">
                  <img draggable="false" src={nga_flag} alt="bank transfer" />
                </div>
                <div className="text">
                  Naira Account <br />
                  <span className="currency">Create Account</span>
                  <div className="ms-">
                    {" "}
                    {modalShowAccountType && msg.spinner ? (
                      <>
                        <Spinner animation="grow" size="sm" variant="warning" />{" "}
                        <span>creating account...</span>
                      </>
                    ) : msg.error ? (
                      <p className="mo-danger">{msg.error}</p>
                    ) : null}{" "}
                  </div>
                </div>
              </div>

              <div className="amount">
                <img src={next_bg} alt="proceed" style={{ width: "20px" }} />
              </div>
            </div>

            {/* DOLLLAR ACCOUNT */}
            <div className="method-area grey-area">
              <div className="method">
                <div className="icon">
                  <img draggable="false" src={usa_flag} alt="bank transfer" />
                </div>
                <div className="text">
                  Dollar Account <br />
                  <span className="currency">Create Account</span>
                </div>
                
                {/* <div className="image" style={{ width: "30px" }}>
                  <img
                    src={coming_soon}
                    alt="coming soon"
                    className="img-fluid"
                  />
                </div> */}
              </div>
               
              <code className="coming-soon">Coming soon!!!</code>
              <div className="amount">
                <img src={next_bg} alt="proceed" style={{ width: "20px" }} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
