 import { useContext, useState } from "react";
import { Spinner, Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { KycManager } from "../../context-api/KycContext";
import { variableManager } from "../../context-api/VariableContex";
import "../../styles/createAccount.scss";
import axiosInstance from "../../utils/axios_instance";

export default function VerifyAccountForm() {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [smsOtp, setSmsOtp] = useState(new Array(3).fill(""));
  const [error, setError] = useState("");
  const [toast, setToast] = useState(false);



  const {
    userDetails,
    code, setCode,
    handleRegister,
    success,
    setSuccess,
    handleVerification,
    unknownUser,
    setUnknownUser, 
    msg, setMsg, user,
  } = useContext(variableManager);

  const { profile, setProfile } = useContext(KycManager);
 

  const handleChange = (element, index, toMap) => {
    if (isNaN(element.value)) return false;
    setSuccess({ ...success, message: "" });
    if (toMap === "email") {
      setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    } else if (toMap === "sms") {
      setSmsOtp([
        ...smsOtp.map((d, idx) => (idx === index ? element.value : d)),
      ]);
    }
    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  async function validateForgotPasswordEmail(email) {
   

        try {
          setMsg({ ...msg, error: "", spinner: true });
          setCode({
            ...code,
            email: "",
            sms: "",
          });

          await axiosInstance
            .post(`/auth/reset-otp`, { email: email })
            .then((resp) => { 

              setMsg({
                ...msg,
                error: "",
                spinner: false,
              });

              setCode({
                ...code,
                email: resp.data.data,
                sms: resp.data.data,
              });

              setUnknownUser({ ...unknownUser, status: true, email: email });
 
            });
        } catch (error) {
          setMsg({
            ...msg,
            success: "",
            spinner: false,
            error: error.response.data.message,
          }); 
        }


     


   
  } 

  function handleCodeVerification() {} 

  const navigate = useNavigate();
  return (
    <div className="verify-account-form-container create-account-container">
      <div className="form-area">
        {/* <div className="login-redirect-link hide-on-modal">
          Already have an account?{" "}
          <b className="pointer" onClick={() => navigate("/login")}>
            {" "}
            &nbsp; Log in
          </b>
        </div> */}

        <div className="content">
          <h2>Verify your account</h2>
          <p className="mantra">
            Enter the correct 6 digits code sent to your email and your
            phone number in the boxes below
          </p>

          <div className="email-sms-otp">
            <div className="email-otp">
              <div className="otp-box">
                {otp.map((data, index) => {
                  return (
                    <input
                      className="otp-field"
                      type="text"
                      name="otp"
                      maxLength="1"
                      key={index}
                      value={data}
                      onChange={(e) => {handleChange(e.target, index, "email"); setError("")}}
                      onFocus={(e) => e.target.select()}
                      onKeyDown={(e) => {
                        if (e.keyCode === 8) {
                          // handleDelete(e, index)
                        } else if (e.key === "ArrowLeft") {
                          e.target.previousSibling.focus();
                        } else if (e.key === "ArrowRight") {
                          e.target.nextSibling.focus();
                        }
                      }}
                    />
                  );
                })}
              </div>
              {/* <p className="code-here">Email code here</p> */}
            </div>

            {/* <div className="separator">-</div> */}

            {/* <div className="sms-otp">
              <div className="otp-box">
                {smsOtp.map((data, index) => {
                  return (
                    <input
                      className="otp-field"
                      type="text"
                      name="smsOtp"
                      maxLength="1"
                      key={index}
                      value={data}
                      onChange={(e) => handleChange(e.target, index, "sms")}
                      onFocus={(e) => e.target.select()}
                      onKeyDown={(e) => {
                        if (e.keyCode === 8) {
                          // handleDelete(e, index)
                        } else if (e.key === "ArrowLeft") {
                          e.target.previousSibling.focus();
                        } else if (e.key === "ArrowRight") {
                          e.target.nextSibling.focus();
                        }
                      }}
                    />
                  );
                })}
              </div>
              <p className="code-here">SMS code here</p>
            </div> */}
          </div>

          {success.message && <p className="error">{success.message}</p>}
{error && <p className="mo-danger">{error}</p>}
          <button

          disabled={msg.spinner?"{true}":""}
            onClick={() => {
              if (
                !profile.selected.resetPassword &&
                !profile.selected.changePassword &&
                !profile.selected.resetPin &&
                !profile.selected.changePin
              ) {
                if (unknownUser.status) {
                  //FOR AN UNKNOWN USER WHO FORGOT PASSWORD
                  if (
                    parseInt(otp.join("")) === code.email ||
                    parseInt(smsOtp.join("")) === code.sms
                  ) {
                    setSuccess({ ...success, message: "" });
                    navigate("/forgot-password/new-password");
                  } else { 
                    setError("Invalid OTP");
                    // if (parseInt(otp.join("")) === code.email) {
                    //   setError("Wrong SMS code");
                    //   setSuccess({ ...success, message: "Wrong SMS code" });
                    // } else {
                    //   setError("Wrong Email code");
                    //   setSuccess({ ...success, message: "Wrong Email code" });
                    // }
                  }
                } else {
                  //FOR A FRESH USER TRYING TO SIGNUP
                  if (
                    parseInt(otp.join("")) === code.email ||
                    parseInt(smsOtp.join("")) === code.sms
                  ) {
                    setSuccess({ ...success, message: "" });
                    handleRegister();
                  } else {
                    setError("Invalid OTP");

                    // if (parseInt(otp.join("")) === code.email) {
                    //   setError("Wrong SMS code");
                    //   setSuccess({ ...success, message: "Wrong SMS code" });
                    // } else {
                    //   setError("Wrong Email code");
                    //   setSuccess({ ...success, message: "Wrong Email code" });
                    // }
                  }
                }
              } else {
                if (profile.selected.resetPassword) {
                  if (
                    parseInt(otp.join("")) === code.email ||
                    parseInt(smsOtp.join("")) === code.sms
                  ) { 
                    setSuccess({ ...success, message: "" });
                    setProfile({
                      ...profile,
                      verifyEmailPhone: false,
                      resetPassword: true,
                    });
                  } else {
                    setError("Invalid OTP"); 

                    // if (parseInt(otp.join("")) === code.email) {
                    //   setError("Wrong SMS code");
                    //   setSuccess({ ...success, message: "Wrong SMS code" });
                    // } else {
                    //   setError("Wrong Email code");
                    //   setSuccess({ ...success, message: "Wrong Email code" });
                    // }
                  }
                } else if (profile.selected.resetPin) {
                  if (
                    parseInt(otp.join("")) === code.email ||
                    parseInt(smsOtp.join("")) === code.sms
                  ) {
                    setSuccess({ ...success, message: "" });
                    setProfile({
                      ...profile,
                      verifyEmailPhone: false,
                      resetPin: true,
                    });
                  } else {
                    setError("Invalid OTP");
                    // if (parseInt(otp.join("")) === code.email) {
                    //   setError("Wrong SMS code");
                    //   setSuccess({ ...success, message: "Wrong SMS code" });
                    // } else {
                    //   setError("Wrong Email code");
                    //   setSuccess({ ...success, message: "Wrong Email code" });
                    // }
                  }
                }
              }
            }}
          >
           {
            msg.spinner?(
              <Spinner
              size="sm"
              />
            ):" Verify account"
           }
          </button>
 

          <Toast
            onClose={() => setToast(false)}
            show={toast}
            delay={2000}
            autohide
            className="toast"
          >
            <Toast.Body>Code resent to your phone and email!</Toast.Body>
            {/* <Toast.Header></Toast.Header> */}
          </Toast>
          <div className="terms-agreement-area">
            <p className="text-center">
              Didn't receive codes?{" "}
              <b
                className="pointer mo-info"
                onClick={() => {
                 
                  setToast(true);
                  setOtp(new Array(6).fill(""))
                  setError("")
                  if(unknownUser.status){
                    validateForgotPasswordEmail(unknownUser.email?unknownUser.email: localStorage.getItem("unknown_user_email"))
                   
                  }else{
                    // handleVerification(unknownUser.email?unknownUser.email: localStorage.getItem("unknown_user_email"));
                    handleVerification({email:user.email || userDetails.email, phone:user.phone || userDetails.phone});
                  }



                }}
              >
                Resend codes
              </b>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
