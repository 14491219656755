import { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import error from "../../../icons/error.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext"; 
export default function BillError(props) {
  const { showBill, setShowBill, } =
    useContext(KycManager);
 
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>{
                setShowBill({
                    ...showBill,
                    status: { ...showBill.status, error: false },
                  })
            }
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <center>
            <div className="icon">
              <img src={error} alt="transfer failed" />
            </div>
            <div className="detail">
              <h3>Failed Transaction</h3>
              <p>
                {/* {payModalShow.transaction ? payModalShow.transaction.msg : null} */}
              </p>
              <p className="fw-bold mo-danger">
               error message
              </p> 
                <p>Transaction Reference: bbbbbbb </p> 

              {/* <p>Transaction Reference: {payModalShow.transaction ?payModalShow.transaction.ref:null}</p> */}
            </div>
          </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}
