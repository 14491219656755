import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../../context-api/KycContext";
import "../../../styles/modals.scss";
import { variableManager } from "../../../context-api/VariableContex";
export default function RequestPOS(props) {
  const { msg, toverify, showVeriSuccess, showPos, setshowPos } =
    useContext(KycManager);
  const { posRequestDetails, setPosRequestDetails } =
    useContext(variableManager);
  const [fieldStatus, setFieldStatus] = useState({
    empty: false,
    filled: false,
  });

  const [error, setError] = useState("");

  function handleFieldValidation() {
    if (
      !posRequestDetails.businessName ||
      !posRequestDetails.type ||
      !posRequestDetails.phone ||
      !posRequestDetails.quantity ||
      !posRequestDetails.address ||
      !posRequestDetails.state ||
      !posRequestDetails.city ||
      !posRequestDetails.country
    ) {
      setFieldStatus({ ...fieldStatus, empty: true });
    } else {
      if (posRequestDetails.country==="nigeria" && posRequestDetails.phone.substr(4).length < 10) {
        console.log(posRequestDetails.phone.substr(4))
        setError("Phone number cannot be less than 10 digits");
      } else {
        setFieldStatus({ ...fieldStatus, empty: false });
        setshowPos({ ...showPos, terms: true, request: false }); 
      }
    }
  }
 
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Mongoro Amal POS Request</h2>
        <p className="mantra p-2" style={{ backgroundColor: "#EFF3BE" }}>
          Please note that the estimated timeline for delivery is 15 working
          days
        </p>
        <div className="request-pos-container">
          <div className="input-area">
            <div className="label">
              <label htmlFor="businessName">Business Name</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="businessName"
                placeholder="Enter Business Name"
                onChange={(e) =>
                  setPosRequestDetails({
                    ...posRequestDetails,
                    businessName: e.target.value,
                  })
                }
              />
              {fieldStatus.empty && posRequestDetails.businessName === "" ? (
                <p className="mo-danger">Field is required</p>
              ) : null}
            </div>
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="address">Home Address</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="address"
                placeholder="No 5, Yoruba street"
                onChange={(e) =>
                  setPosRequestDetails({
                    ...posRequestDetails,
                    address: e.target.value,
                  })
                }
              />
              {fieldStatus.empty && posRequestDetails.address === "" ? (
                <p className="mo-danger">Field is required</p>
              ) : null}
            </div>
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="city">City</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="city"
                placeholder="Enter your city"
                onChange={(e) =>
                  setPosRequestDetails({
                    ...posRequestDetails,
                    city: e.target.value,
                  })
                }
              />
              {fieldStatus.empty && posRequestDetails.city === "" ? (
                <p className="mo-danger">Field is required</p>
              ) : null}
            </div>
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="state">State</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="state"
                placeholder="Enter your state"
                onChange={(e) =>
                  setPosRequestDetails({
                    ...posRequestDetails,
                    state: e.target.value,
                  })
                }
              />
              {fieldStatus.empty && posRequestDetails.state === "" ? (
                <p className="mo-danger">Field is required</p>
              ) : null}
            </div>
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="country">Country</label>
            </div>
            <div className="input">
              <select
                name=""
                id="country"
                onChange={(e) =>
                  setPosRequestDetails({
                    ...posRequestDetails,
                    country: e.target.value,
                  })
                }
              >
                <option value="nigeria">Nigeria</option>
                <option value="usa">USA</option>
              </select>
              {fieldStatus.empty && posRequestDetails.country === "" ? (
                <p className="mo-danger">Field is required</p>
              ) : null}
            </div>
          </div>

          <div className="input-area ">
            <div className="label">
              <label htmlFor="phone">Phone Number</label>
            </div>
            <div className="input phone-area">
              <select
                name="country-code"
                id="country-code"
                onChange={(e) =>
                  setPosRequestDetails({
                    ...posRequestDetails,
                    countryCode: e.target.value,
                    phone: `${e.target.value}${posRequestDetails.phone.substr(
                      -10
                    )}`,
                  })
                }
              >
                <option value="+234">+234</option>
                <option value="+44">+44</option>
                <option value="+65">+65</option>
                <option value="+84">+84</option>
              </select>
              <input
                type="tel"
                id="phone"
                name="phone"
                pattern={[0 - 9]}
                maxLength="10"
                minLength="10"
                placeholder="8143626356"
                onChange={(e) =>
               {   setPosRequestDetails({
                    ...posRequestDetails,
                    phone: `${posRequestDetails.countryCode}${e.target.value}`,
                  })
                setError("")
                }
                }
              />
            </div>
              {fieldStatus.empty && posRequestDetails.phone === "" ? (
                <p className="mo-danger">Field is required</p>
              ) : null}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="type">POS Type</label>
            </div>
            <div className="input">
              <select
                name=""
                id="type"
                onChange={(e) =>
                  setPosRequestDetails({
                    ...posRequestDetails,
                    type: e.target.value,
                  })
                }
              >
                <option value="">Mongoro Amal</option>
              </select>
            </div>
            {fieldStatus.empty && posRequestDetails.type === "" ? (
              <p className="mo-danger">Field is required</p>
            ) : null}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="quantity">Quantity</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="quantity"
                placeholder="Number of Terminals"
                onChange={(e) =>
                  setPosRequestDetails({
                    ...posRequestDetails,
                    quantity: e.target.value,
                  })
                }
              />
              {fieldStatus.empty && posRequestDetails.quantity === "" ? (
                <p className="mo-danger">Field is required</p>
              ) : null}
            </div>
          </div>
        </div>

        {
          error && (<p className="error">{error}</p>)
        }

        <div className="buttons">
          <button
            className={toverify.bvn ? "btn-active" : "btn-active"}
            onClick={() => {
              handleFieldValidation();
            }}
            disabled={msg.spinner ? "{true}" : ""}
          >
            {msg.spinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Request POS"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
