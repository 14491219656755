import ForgotPasswordForm from "../components/forms/ForgotPasswordForm";
import SideFrame from "../components/signup/SideFrame";
import signup_frame from "../images/signup-frame.png"

export default function ForgotPassword(){
    return(
        <div className="create-account-container">
        {/* <div className="background-area">
            <img src={signup_frame} alt="Best solutions" />
        </div>  */}

        {<SideFrame />}   
        {<ForgotPasswordForm />}   

    </div>
    )
}