import UpdatePasswordForm from "../components/forms/UpdatePasswordForm";
import SideFrame from "../components/signup/SideFrame";
import signup_frame from "../images/signup-frame.png"

export default function UpdatePassword(){
    return(
        <div className="create-account-container">
        {/* <div className="background-area">
            <img src={signup_frame} alt="Best solutions" />
        </div>  */}

        {<SideFrame/>}  
        {<UpdatePasswordForm/>}  

    </div>
    )
}