import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../context-api/KycContext";
import { variableManager } from "../../context-api/VariableContex";
import "../../styles/modals.scss";
export default function KycBvn(props) {
  const {
    // msg,
    setModalShowBvn,
    setModalShowNin,
    toverify,
    setToverify,
    // handleVerifyBvn,
    showVeriSuccess,
    setShowVeriSuccess,
    handleGenerateAccount,
    handleBankList,
  } = useContext(KycManager);
  const [bvn, setBvn] = useState(false);

  const {msg, setMsg,
    handleVerifyBvn,
    handleFetchBVN,
  } = useContext(variableManager)
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onHide={()=> {setShowVeriSuccess({ ...showVeriSuccess, error: "" }); setMsg({...msg, spinner:false})}} ></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>BVN</h2>
        <p>Please Enter your BVN</p>

        <div className="input-area">
          <div className="label">
            <label htmlFor="enterEvn">Enter BVN</label>
          </div>
          <div className="input">
            <input
              type="text"
              id="enterBvn"
              placeholder="Enter BVN"
              maxLength={11}
              onChange={(e) => {
                setShowVeriSuccess({ ...showVeriSuccess, error: "" });
                setToverify({ ...toverify, bvn: e.target.value });
              }}
            />
          </div>
        </div>
        <p className="text-danger text-center fw-bold">
          {showVeriSuccess.error ? showVeriSuccess.error : null}
        </p>

        <div className="buttons">
          <button
            className={toverify.bvn ? "btn-active" : "btn-inactive"}
            disabled={msg.spinner?"{true}":""}
            onClick={() => {
              handleVerifyBvn(); 
              // handleFetchBVN(); 
            }}
          >
           {
                        msg.spinner ? (
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        ):"Validate BVN"
                      }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
