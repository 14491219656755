import { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/modals.scss"; 
import { KycManager } from "../../context-api/KycContext";
export default function MongoroTerms(props) {
  const { setShowTerms } = useContext(KycManager);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <h5 className="terms-modal-header">Terms and Conditions</h5>
      </Modal.Header>
      <Modal.Body className="modalContent terms-body">
        <div className="p-3">
          <div className="mongoro-terms-headings">
            <div className="terms-applied">Terms and Conditions Applied</div>
            <b>Mongoro - Privacy Policy:</b> <br />
            <b>Effective date: 2023-02-01</b>
            <div className="text">
              This document sets out the terms and conditions applicable to your
              use of our payment services <b>(“Agreement”)</b>. If a term is not
              defined in the terms, then you can find definitions here.
            </div>
          </div>

          <div className="mongoro-terms-content">
            <ol className="headings">
              <li>Introduction</li>
              <pre>
                {`1.1 This Agreement creates a legal relationship between us and you, our merchant, for the provision of payment services to you in Nigeria.  
 1.2 We are not a registered bank, and we may not operate as a bank.  
 1.3 We are registered as a third-party payment provider - which means that as part of our service offering, we facilitate the processing of Transactions.  
 1.4 To enable us to act as a third-party payment provider we have entered into agreements with our Acquiring Bank through our Third-Party Payment Service Providers.  
 1.5 WEMA Bank is our Acquiring Bank, as provided by our Third-Party Payment Service Provider, Flutterwave. All merchant acceptance of Account numbers, Payment lodgings and Virtual card services are extended by WEMA Bank.  
 1.6 What you are agreeing to - By entering into this Agreement, you agree to be bound to this Agreement and to our Policies. Please also take note that you will be required to comply with the Association Rules (including security rules), and with applicable Payment Card Industry Data Security Standards (PCI DSS) rules (available on request) when processing Transactions.  
 1.7 What are the Association Rules? - The Associations require that we, you, and the Acquiring Bank comply with the Association Rules, which are amended from time to time. You can request a copy of these from us.  
 1.8 Who are the Associations? - The card associations supported by the Acquiring Bank from time to time for the purposes of processing Transactions.  
 1.9 Acceptance Date – You are bound by this Agreement the moment you commence our online application process (Signup). However, this does not guarantee that you will be accepted as our merchant once we have completed our Onboarding Process. Please refer to clause 2 for our Onboarding Process.  
 1.10 How do we change our Agreement? – We may at any time amend the terms of this Agreement. We will attempt to provide you with reasonable notice of any amendment but are not required to do so.  
 1.11 Read Carefully - Please read this Agreement carefully. It is your responsibility to keep yourself up to date with any amendments to the Agreement and to ensure you are always familiar with its terms.  
 1.12 If you have questions? - Please do not hesitate to contact our Legal Team if any part of this Agreement, the Association Rules, or our Policies, are unclear, or if you wish to better understand any of them.  
 1.13 What if you don’t agree? – If you don’t agree to any of the terms of this Agreement, or to any amendment as contemplated in clause 1.10, then please immediately stop using our Services and, if applicable, send our Support Team a termination notice as per clause 1.14.  
 1.14 What if you want to get out of the Agreement? - You may terminate this Agreement at any time by sending a written notice to our Support Team.  
 1.15 When may we terminate or suspend this Agreement or any of our Services?  
 1.15.1 We may terminate this Agreement, or terminate or suspend any of our Services immediately, without notice to you, if:  
 1.15.1.1 we are required to do so by the Acquiring Bank, the Associations, or by any law or regulation; 
 1.15.1.2 any provision in this Agreement allows us to;  
 1.15.1.3 we suspect any fraudulent or criminal activities in connection with our Services, or that there is any unlawful or negligent use of our Services;  
 1.15.1.4 we suspect or know of any activity that may create harm or loss to the goodwill of the card payment system.  
 1.15.2 We may also terminate this Agreement or any of our Services at any time by giving you reasonable notice of termination. Reasonable notice will be determined by us at our discretion.  
 1.16 Who can act on your behalf? - Only those authorised representatives appointed by you, as contemplated in clause 2.1.1 below, may act on your behalf in relation to our Services. You are responsible for putting in place safeguards and controls to prevent misrepresentation, fraud, unwanted, or unauthorised Transactions, and any other unauthorised action on your behalf. Where applicable, you are responsible for your employees’ actions while in your employ.  
 1.17 What happens if someone acts on your behalf?  
 1.17.1 You will be liable for the value of all Transactions made on your behalf (whether authorised by you or not).  
 1.17.2 You will also be liable for all losses and costs arising due to any actions taken on your behalf (whether authorised by you or not).  
 1.17.3 You acknowledge that you understand the risks associated with giving someone the ability to act and transact on your behalf and the other risks as highlighted in clause 1.16 and in this clause 1.17. Please also refer to clause 6 for Authorised and Unauthorised Transactions.  
 1.18 Can you assign your rights & obligations to someone else? – You will not be entitled at any time to transfer any of your rights, or delegate any of your obligations to another party, unless we consent to this in writing.  
 1.19 Notify our Support Team immediately if:  
 1.19.1 you know or suspect any unauthorised actions being taken on your behalf in relation to the Services; 
 1.19.2 your circumstances change that could create any risk for us, the Associations, our Acquiring Bank or to the card payment system;  
 1.19.3 you are placed under an administration order, sequestrated or liquidated, or placed under any other form of insolvency or legal disability, including business rescue;  
 1.19.4 you receive a notice that your business may be deregistered;  
 1.19.5 your business is closed for any reason whatsoever;  
 1.20 If the circumstances stipulated in clauses 1.19.1 to 1.19.5, occur, we may immediately without notice to you, terminate or suspend this Agreement or any of our Services, at our discretion.  
 1.21 What about your Privacy? - At Mongoro we care about our merchants and their privacy and Personal Information. Therefore, we will process your Personal Information in line with our Privacy, Website, App & Cookie Policy ("Privacy Policy") which may be found here: https://www.Mongoro.com/terms/.  
 1.21.1 By accepting this Agreement and/or by providing Personal Information to us, you agree and consent to the provisions of our Privacy Policy. If you do not agree or consent, please do not submit Personal Information to us because we may not be able to provide our products or Services, or both, to you. If you have any questions or concerns about this, please contact our Privacy Team.  
 1.21.2 Where it is necessary to obtain consent for processing outside of our Privacy Policy, we will seek your consent directly.  
 1.21.3 If you provide us with Personal Information about or on behalf of another person (including, but not limited to, Authorised Account Holders, signatories, authorised representatives and beneficiaries), you confirm that you are authorised to: (i) give us the Personal Information; (ii) consent on their behalf to the processing of their Personal Information, and (iii) receive any privacy notices on their behalf. Please note that you are required to protect the Personal Information of Cardholders. 
 `}
              </pre>
              <li>Our onboarding process</li>
              <pre>{`2.1 Our Onboarding Process is as follows: What do you need to do?  
2.1.1 You will apply to accept Card payments using a Bank Account on the Website (“Signup”) by submitting accurate and up-to-date details about you, your authorised representatives (if any), and the nature of your business.  
2.1.2 During the Signup process you may elect to:  
2.1.2.1 purchase a Card Reader from us, if you do so, you will be required to pay us the sale price of your chosen Card Reader; and/or  
2.1.2.2 register an existing Card Reader (purchased or otherwise lawfully obtained from a Third Party); and/or  
2.1.2.3 select an Online Payment option.  
2.1.3 You will provide us with the information and Verification Documentation requested by us, for us to perform regulatory and other identification and verification checks, as may be required by law and as determined by us and the Acquiring Bank as part of our and the Acquiring Bank’s Verification Process.  
2.1.4 If you choose to purchase a Card Reader during Signup, it will be delivered to your specified delivery address.  
2.1.5 You will download the Mongoro App and any other free Mongoro software needed to make use of our Services in conjunction with your chosen Card Reader(s) and/or Online Payment solution(s).  
2.1.6 You will provide all ancillary devices, equipment or infrastructure needed in order to make lawful use of the Card Reader and/or Online Payment solution and our Services. This may include but is not limited to providing and connecting to Compatible Devices, ensuring a stable data connection and any other operational needs that may be reasonably required (like a power supply to safely charge your Card Reader). 
2.1.7 An offer to do business - Your application is an offer to do business with us. We are entitled to decide whether or not to accept your offer after performing our Verification Process.  
2.1.8 After Signup but before completion of the Verification Process You can commence processing Transactions from the date on which you have completed Signup, but the settlement (payment of money into your account) for these Transactions will not be completed by us until and unless your Verification Process and application has been successful and accepted, as determined by us, and all documentation requested by us has been received, reviewed, approved and electronically stored by us.  
2.1.9 Verification Process - Upon receipt of the information and Verification Documentation we will perform an identification, screening, and Verification Process on you and your business, to determine whether your application is successful or not. Upon completion, we will notify you of the results of the Verification Process. 
2.1.10 Authorisation - For this purpose, you expressly authorise us to perform vetting, credit, background and regulatory checks on you and related parties, such as your principal, appointed signatory, partner(s), or owner(s) of the business, as may be applicable.  
2.2 If we decide not to accept your application? - Should you not pass our Verification Process and/or we decide not to accept your application, then:  
2.2.1 all funds you have Transacted through us thus far will be refunded to the original Cardholders, and you will not be entitled to receive those funds from us, however lawfully they may have been generated. The onus will fall on you, to recoup any lost earnings as a result, from the affected Cardholders, in your own capacity, and you will not be able to recoup such losses or recover any costs from us;  
2.2.2 if applicable, you will immediately return our Card Reader to us;  
2.2.3 you must immediately cease using our Services;  
2.2.4 you will refrain from making defamatory statements about us and our Services on any social media platform or any other platform, media, or other method of whatsoever nature;  
2.2.5 you agree that you will not hold us liable for any damages, losses, and costs of whatsoever nature you may incur directly or indirectly due to the circumstances as contemplated in this clause 2.2;  
2.2.6 the provisions in the Agreement and any other applicable documents that by their nature remain applicable to the relationship between us and you, will continue to apply until such time as they are no longer applicable.  
2.3 What if your details change? If, at any time, there are any changes to the information and Verification Documentation you have submitted to us including (but not limited) to any changes to the Authorised Account Holder, or any other material change to the business’s related parties, then:  
2.3.1 you must, immediately inform our Support Team in writing of any such change and resubmit any relevant Verification Documentation;  
2.3.2 your access to our Services (or parts thereof) may be suspended; 2.3.3 you will have to complete our Onboarding Process anew, to receive the Services and any settlement owed from Transactions made during the completion of this subsequent Onboarding Process.  
2.4 Know your client - In terms of KYC, we may not enter into a relationship with you unless you have provided us with acceptable and valid documents as part of our customer due diligence process, which may include identity verification, address verification, the incorporation, registration and other details regarding your business and its ownership structure.  
2.5 Non-Compliance with KYC - If the documentation provided by you does not comply with the required KYC, we will have to suspend our Services (or the relevant portion thereof) to you as is required by law. We will make all reasonable attempts to obtain the correct documentation from you, but if we are unable to obtain the documents the relevant portion of our Services to you will be suspended, and we will notify you accordingly.  
2.6 Retention of your information and Verification Documentation - We will be required by law to retain your information and Verification Documentation for a period of at least 5 (five) years from the date of the last Transaction.  
2.7 Who can we share your information and Verification Documentation with? - You acknowledge and consent to us sharing your information and Verification Documentation in our possession with our Acquiring Bank and with the Associations on their request.  
`}</pre>
              <li>Our Services </li>
              <pre>{`3.1 Our Services include:  
3.1.1 clearing and settlement services:  
3.1.1.1 debit and credit card and Online Payment clearing and settlement services - you accept Cards from a Cardholder or via an Online Payment link as a form of payment for goods, services and/or facilities supplied by you to that Cardholder, and we clear and settle such payments for you;  
3.1.1.2 we pay the amounts due to you, and retain the amounts due to us, we are required to settle amounts directly to you; 3.1.2 supply and support services:  
3.1.2.1 (when applicable) the supply of the Card Reader and support and replacement of the Card Reader; 
3.1.2.2 support in respect of our clearing and settlement services;  
3.1.2.3 billing and invoicing services; and  
3.1.2.4 any extra services which we may, from time to time, introduce in relation to our clearing and settlement services.  
3.2 Use of our Services is at your own Risk – You understand that your use of our Services may have certain risks, and that due to the nature of our Services, use of our Services is at your own risk (please ensure that you guard against these risks appropriately).  
3.3 What happens if there is a defect with our Services?  
3.3.1 Due to the nature of our Services, these are provided on an “as is” and “as available” basis. We will take reasonable steps to ensure that our Services are delivered in accordance with this Agreement, but we cannot guarantee that there will be no defects with our Services.  
3.3.2 We shall be entitled to recover from you, immediately upon our demand all damages, losses and costs of any nature which we may incur due to any defect with our Services or due to any mistake, error, act or omission or due to the time taken to detect such mistakes, errors, acts or omissions.  
3.4 Exclusion of Warranties - To the maximum extent permitted by law, our Services are provided without warranties of any kind. We do not warrant –  
3.4.1 that our Services will meet your requirements;  
3.4.2 that our Services will be available at any particular time or location, uninterrupted or secure;  
3.4.3 that any defects or errors in our Services will be corrected; or  
3.4.4 that our Services are free of viruses or other harmful components.  
`}</pre>
              <li> Our Fees</li>
              <pre>{` 4.1 You are liable for the fees as set out in our Fee Schedule on an ongoing basis, on the terms set out in the Fee Schedule, unless agreed otherwise in writing with us.  
4.2 Changes to our fees - Upon reasonable notice to you, we reserve the right to change any of our fees and payment terms or introduce a new fee.  
4.3 What if you don’t agree to fee changes? - To keep using our Services, you agree to any changes we may make to our fees and payment terms. If you do not agree to our changes, you will need to terminate our Services as per 1.14.  
4.4 Currency – Nigerian Naira - All balances in your Merchant Account and all fees, charges, and payments collected or paid through the Services are denominated in Nigerian Naira, except where authoritatively stated otherwise.  
`}</pre>
              <li>Authorisations</li>
              <pre>{`5.1 You authorise us to hold, receive, and disburse all settlement funds on your behalf.  
5.2 If you owe us any amount that is due in terms of this Agreement, then:  
5.2.1 we may set-off all amounts standing to the credit of your Merchant Account for repayment of any such amounts;  
5.2.2 you grant us a cession in security of the rights and claims in and to your Merchant Account for repayment of any such amounts;  
5.2.3 if there is no amount standing to the credit of your Merchant Account for repayment of any such amounts, we have the right to debit your Bank Account for any outstanding amounts due to us in order to settle your Merchant Account.  
5.3 The above grants and authorisations:  
5.3.1 permit us to process each Transaction that you authorise; and  
5.3.2 will remain in full effect until your Merchant Account is closed.  
`}</pre>
              <li>Authorised and Unauthorised Transactions</li>
              <pre>{`6.1 You agree that:  
6.1.1 you will not process any Transaction if it does not relate to a genuine transaction for goods and/or services;  
6.1.2 you will not submit previously disputed Transactions;  
6.1.3 you will only process Transactions that take place inside and outside of Nigeria in amounts denominated in Nigerian Naira;  
6.1.4 you will accept each valid Card that is presented to you by the rightful Cardholder in payment for goods and/or services. You agree not to be in possession of written Cardholder information such as the Cardholder’s account number, expiration date CVV, signature or any other card account data;  
6.1.5 you will supply the goods and/or services at your normal price for those goods and/or services; 
6.1.6 you will not add any extra charges or set any minimum or maximum Transaction amount for any particular Transaction;  
6.1.7 you are prohibited from surcharging, unless explicitly done in accordance with applicable law, regulations and the Association Rules;  
6.1.8 you will retain a copy of each signed sales voucher and the receipt for a period of at least 180 (one hundred and eighty) days from the date of the Transaction;  
6.1.9 you will not disburse funds in the form of traveler's cheques if the sole purpose is to enable a cash purchase of goods and/or services from you;  
6.1.10 you will not accept a Transaction that does not result from an act between you and a valid Cardholder;  
6.1.11 you will not collect tax separately from the Transaction amount;  
6.1.12 you will not attempt to limit your liability by asking or requiring Cardholders to waive their dispute rights in your favour;  
6.1.13 you will not submit illegal Transactions or Transactions that are fraudulent or unauthorised.  
6.2 Unauthorised Transactions – where unauthorised Transactions have been detected, we will have the right to immediately, without notice to you, terminate or suspend our Services (or any part thereof).  
6.3 You agree that we can accept that any action committed on your behalf has been fully authorised by you and is on your behalf, and we are not required to verify any such actions. Please beware of phishing scams and other potentially fraudulent activities for which you will be fully liable and accountable.  
6.4 Mistakes and Errors by you - Where you make a mistake, we will not be liable for any loss or damage of any nature as a result of your mistake, error, act or omission, or as a result of the time taken to detect such mistakes, errors, act or omissions. We do not verify the Transaction details and are not liable as such.  
6.5 Additional Transaction functionality on the App, you will be able to –  
6.5.1 reverse Transactions on the same day back to the Cardholder;  
6.5.2 refund a Transaction for up to 90 (ninety) days back to the Cardholder;  
6.5.3 send / resend receipts to the Cardholder;  
6.5.4 view your Transaction data in different ways;  
6.5.5 update your business details (e.g. including your Bank Account, business trading address);  
6.5.6 add employees who can accept Cards and/or Online Payments under your Merchant Account;  
6.5.7 add and access Third Party Features, which are subject to their own separate terms and conditions; and  
6.5.8 perform any other actions using any functionality that we may add to the Services from time to time. 
6.6 You agree to manage all functions relating to the Transactions on your Merchant Account and agree to accept all risk connected and or related to managing these functions, for as long as you are the Authorised Account Holder or until such time as the new Authorised Account Holder assumes these obligations.  
6.7 You agree that we may share the details regarding your Transactions with our employees, agents, the Acquiring Bank, the Associations and Third-Party Service Providers for training, research, analysis, and operational business purposes, in accordance with our Privacy Policy.  
`}</pre>
              <li>Bank Accounts, Settlements, and Invoices</li>
              <pre>{`7.1 What does Settlement mean? – it is the payment of settlement funds to you in accordance with this Agreement.  
7.2 Subject to clause 7.3, we will, on a per-Transaction basis and in accordance with our Settlement Schedule, credit your Bank Account with the total net amount of valid Transactions presented for payment, LESS the fees as set out in our Fee Schedule.  
7.3 When can we delay settlement or withhold payments? - we are entitled to delay settlement and/or to withhold payment of settlement funds:  
7.3.1 if there is any outstanding Verification Documentation from you;  
7.3.2 we do not accept your application as contemplated in clause 2.2;  
7.3.3 we suspect any fraudulent or criminal activity;  
7.3.4 where unauthorised Transactions have been detected;  
7.3.5 for any of the reasons set out in clause 8.  
7.4 You will receive a monthly settlement invoice, setting out –  
7.4.1 the record of Transactions on your Merchant Account; and  
7.4.2 settlement payments made by us to you.  
`}</pre>
              <li>Chargebacks</li>
              <pre>{`8.1 You agree that in the event of a Chargeback, we may  
8.1.1 withhold the full value of the Chargeback amount in the Reserve Account, subject to clause 8.2  
8.1.2 adjust the fees set out in our Fee Schedule;  
8.1.3 delay the payment of any settlement amounts into your Bank Account;  
8.1.4 immediately and without notice to you terminate, modify or suspend your access to our Services; and/or  
8.1.5 debit the amount of any Chargeback and any associated fees, fines, or penalties applicable or imposed by any Association or any Third-Party Service Provider from your Merchant Account (including without limitation any Reserve Account), any settlement funds due to you.  
8.2 For purposes of clause 8.1.1, we may withhold the amount of the potential Chargeback from settlements due to you until such time that –  
8.2.1 a Chargeback is finally assessed in favour of a Cardholder; in which case we will retain such funds;  
8.2.2 the period of time under applicable law or Association Rules by which the Cardholder may dispute the Transaction has expired; or  
8.2.3 we determine that a Chargeback on the Transaction will not occur.  
8.3 If we are unable to recover funds related to a Chargeback for which you are liable, you will pay us the full amount of the Chargeback immediately upon our demand. You agree to pay all costs and expenses, including, without limitation, attorneys’ fees on an attorney and own-client scale, incurred by us in connection with the collection of all such amounts from you. For this purpose, you agree that we shall be entitled to debit the full amount of any Chargeback from the Reserve Account.  
8.4 You agree to any timeframes to resolve Chargebacks as imposed by us or by the Associations or local laws and regulations.  
`}</pre>
              <li>What do you Warrant?</li>
              <pre>{`9.1 You warrant that  
9.1.1 you truthfully and accurately disclosed the nature of your business to us upon your registration with us;  
9.1.2 your business does not involve offering goods and/or services to or in respect of any business which we may identify, from time to time, due to regulatory, risk or operational reasons, and which has been notified to you;  
9.1.3 you are not a payment service provider;  
9.1.4 if you are a natural person, that you are at least 18 (eighteen) years of age;  
9.1.5 you are eligible to register and use the Services and have the right, power, and ability to enter into and perform under this Agreement;  
9.1.6 the name identified by you when you registered with us is your name or business name under which you sell goods and/or services;  
9.1.7 you are the Authorised Account Holder and that you will inform us, in writing, if there is any change to your Authorised Account Holder status or details and warrant that you will provide any additional Verification Documentation, as may be required, from time to time;  
9.1.8 any Transaction submitted by you will represent a genuine sale by you;  
9.1.9 any Transaction submitted by you will accurately describe the goods and/or services sold and delivered to a Cardholder;  
9.1.10 you will fulfil all of your obligations to each Cardholder for which you submit a Transaction and will resolve any consumer dispute or complaint directly with the Cardholder;  
9.1.11 you will, and all Transactions initiated by you, will comply with all provincial, national laws, rules, and regulations applicable to you and/or your business and employees;  
9.1.12 except in the ordinary course of business, no Transaction submitted by you through our Services will represent a sale to any principal, partner, proprietor, or owner of your business;  
9.1.13 you will not use the Services, directly or indirectly, for any fraudulent undertaking or in any manner so as to interfere with the operation of the Services;  
9.1.14 no Transaction submitted by you through the Services will involve your Cards (except for reasonable test swipes);  
9.1.15 your use of the Services will be in compliance with this Agreement;  
9.1.16 you have never had a contract with an Association whose contract was terminated at the direction of the Association or any regulatory authority or court of law.  
`}</pre>
              <li>Criminal Activities </li>
              <pre>{`10.1 We aim to prevent criminal activities such as money laundering, terrorist financing and cybercrimes. To this end, we reserve the right to do any of the following where we think it is necessary and/or where required in law –  
10.1.1 refuse to do business with anyone we suspect may be involved in illegal activities or other activities that may have a negative impact on us;  
10.1.2 verify the identity of anyone who attempts to access or Transact on your Merchant Account;  
10.1.3 block access and/or suspend your Merchant Account, if we suspect your Merchant Account may be the target of or being used for any unlawful activities or cybercrimes;  
10.1.4 ask for further information and check any Transaction, instruction or recipient before we act, even if this causes a delay in our Services.  
10.2 If, at any time, we have cause to suspect that any fraudulent or criminal activity is taking place (in relation to anything relevant to this Agreement, the Services, any Transaction or on any other basis), we (or our agent) may:  
10.2.1 conduct physical inspections and investigations at your place of business, or at any other place that we may consider relevant; and/or  
10.2.2 immediately and without notice to you suspend or terminate your access to our Services, on terms determined by us; and/or  
10.2.3 delay settlement or withhold payment; and/or  
10.2.4 refund the Cardholder; and/or  
10.2.5 we may also provide your details, as well as details of any conduct that caused our suspicion, to the Nigerian Fraud Prevention Service, the Nigerian Police Service, the Acquiring Bank or any of their agents, the Associations or any of their agents, any other investigating authority, or any other interested third party. You agree to fully cooperate if undergoing forensic or other investigations, until completed.  
`}</pre>
              <li>Audit & Inspection Rights </li>
              <pre>{`11.1 You agree that you will, at your own cost –  
11.1.1 establish and maintain, complete and accurate books of account and financial records in relation to each Transaction authorised by you;  
11.1.2 provide us with a copy of the statements relating to your Bank Account within 5 (five) business days of our request;  
11.1.3 provide us with a copy of – 11.1.3.1 to the extent available, any audited financial statements relating to your business, within 5 (five) days of such statements becoming available; or 11.1.3.2 any unaudited financial statements relating to your business, within 5 (five) days of us requesting such statements from you;  
11.1.4 provide and submit to us such further information relating to the Services as we may require. 11.2 You hereby authorise us to share any such financials and/or bank statements and information received from you with the Acquiring Bank and their agents, the Associations and their agents or any other regulator, or third party as may be required by law. 
`}</pre>
              <li>Now for the Legal Stuff</li>
              <pre>{`12.1 Limitation of Liability - Subject to clauses 12.3 and 12.4, to the maximum extent permitted by law, neither we nor any Third Party Service Providers (and 14 their respective employees, shareholders, directors, agents, affiliates, and representatives) shall be liable for any direct, indirect, punitive, incidental, special, consequential, or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data, or other intangible losses, that result from:  
12.1.1 your use of, inability to use, or unavailability of our Services;  
12.1.2 hacking, tampering, or other unauthorised access or use of our Services or your Merchant Account; 
12.1.3 errors, mistakes, or inaccuracies of our Services; even if the alleged liability is based on contract, delict, negligence, strict liability, or any other basis, and even if we have been advised of the possibility of such damage.  
12.2 Indemnity - Subject to clauses 12.3 and 12.4, to the maximum extent permitted by law, you agree to indemnify and hold us and the Third Party Service Providers (and their respective employees, shareholders, directors, agents, affiliates, and representatives) harmless from and against any and all claims from any third parties, for costs, damages (including, without limitation, direct, indirect, extrinsic, special, penal, punitive, consequential or exemplary loss or damage of any kind), penalties, actions, judgements, suits, expenses, disbursements, fines, or other amounts that arise out of or relate to -  
12.2.1 any actual or alleged breach of your warranties or obligations set out in this Agreement;  
12.2.2 any Transaction submitted by you through the Services; or  
12.2.3 your violation of any third-party right, including without limitation any right of privacy, publicity rights or intellectual property rights.  
12.3 Consumer Protection Act - If this Agreement and/or any goods and/or Services provided under it are regulated by the Consumer Protection Act, No. 68 of 2008 as amended, replaced or re-enacted from time to time, it is not intended that any provision of this Agreement contravenes any provision of the Consumer Protection Act and therefore all provisions of this Agreement must be treated as being qualified, if necessary, to ensure that the provisions of the Consumer Protection Act are complied with.  
12.4 Evidence - If you owe us money, a certificate, signed by us that states:  
12.4.1 the fact that the debt is payable;  
12.4.2 the amount payable;  
12.4.3 the applicable interest rate and the date from which such interest is calculated, 15 will be enough proof of the facts stated on the certificate, unless you can prove otherwise. The appointment of the person who signed the certificate on our behalf does not have to be proved.  
12.5 Jurisdiction - To the extent permissible in law, you consent and submit, for purposes of us having to take legal steps to enforce any of our rights as set out in this Agreement, to the jurisdiction of any division of the Magistrates Court, even if our claim against you is greater than would otherwise be allowed. This does not prevent us from bringing legal proceedings against you in any court that has jurisdiction.  
12.6 If any provision of this Agreement is or becomes invalid, illegal, or unenforceable, the remainder shall survive unaffected.  
12.7 For any required disclosures in respect of the Electronic Communication Act, 25 of 2002, as amended, replaced or repealed please refer to our Website or contact us for any required information.  
`}</pre>
              <li>Addresses</li>
              <pre>{`13.1 Your Address for Legal Notices and Documents - Your physical (street) address on your Signup application form is your chosen address for receiving any legal notices and documents. You must inform us immediately if your address changes.  
13.2 Your Address for Notices and Documents that are not Legal - We may choose to send notices that are not legal notices regarding our Services to the email address or postal address, which you give on your Signup application form.  
13.3 We will assume that you have received any notice we send you within 10 (ten) days of posting, or on the same day if delivered by hand or sent by email.  
13.4 Our Address - We choose as our address for purpose of legal proceedings and legal notices at which address all processes and notices arising out of or in connection with this Agreement, its breach or termination may validly be served upon or delivered to us, as: Physical Address: 21 Blantyre Crescent, Wuse 2. Abuja, Nigeria. Email: sales@Mongoro.com  
`}</pre>
              <li>Definitions</li>
              <pre>{`•	Acquiring Bank means the bank(s) which we have partnered with for the purposes of providing the Services contemplated in this Agreement Associations means the deposits, withdrawals, payments, card associations supported by the Acquiring Bank from time to time for the purposes of processing Transactions. 
•	Association Rules means the bylaws, rules, and regulations published and updated by the Associations from time to time.  
•	Authorised Account Holder means the person nominated during our Onboarding Process as the primary person responsible for managing your Merchant.  
•	Account Bank Account means the Nigerian bank account provided by our acquiring bank during our Onboarding Process, into which all settlement funds will be paid to you by us and used for any other purposes as described in this.  
•	Agreement Card means any of the cards supported by the Associations from time to time for the purposes of processing Transactions.  
•	Cardholder means the person in whose name a Card is issued by an issuing bank.  
•	Chargeback means any Transaction that: is disputed by the Cardholder in question; is reversed for any reason by an Association, any of the Third-Party Service Providers, or the Acquiring Bank; is, in our reasonable opinion, suspicious; if processed, would amount to a breach of the terms of this Agreement or any Association Rules or any law or regulation Compatible. 
•	Device means all devices used to facilitate and provide the Services.  
•	Fee Schedule means the fee schedule according to which we will charge you for Transactions completed using the Services, which is accessible on our Website and/or as we may communicate to you from time to time.  
•	KYC means Know Your Customer.  
•	Merchant Account means an account set up by us, on your behalf, for the purpose of managing settlements due to you and deducting our fees.  
•	Onboarding Process means our onboarding process to onboard you as our merchant, and which process we may change without notice to you, in our discretion, from time to time.  
•	Online Payment means the execution of a Transaction, where consent of the Cardholder to execute the Transaction is given by means of an online telecommunication, digital or information technology device that we own or that we are licensed to use.  
•	Policies means all our policies that relate to the provision of our Services, as amended from time to time. 
•	Chargeback Services means the services described in this Agreement and any other ancillary services that we may offer from time to time.  
•	Settlement Schedule means the schedule according to which we will make payment of settlement funds to you in accordance with this Agreement.  
•	Third Party Features means any additional services provided by independent third parties, approved by us, and which additional services do not form part of the Services.  
•	Third Party Service Provider means any third-party service provider that we have contracted with in order to provide the Services.  
•	Transaction means any payment made by a Cardholder to you which payment transaction is effected by our web or mobile app payment features, a Card, processed by a Card Reader or via a payment link and cleared and settled by the Acquiring Bank Verification.  
•	Documentation means all documentation requested by us from you in order to enable us to perform regulatory, identification, and verification checks in order to comply with our contractual obligations to the Acquiring Bank, and to enable the Acquiring Bank to comply with its obligations in terms of KYC, and any other regulatory requirements and due diligence international best practices.  
•	Verification Process means our or the Acquiring Bank’s verification process Website means any website operated by us, located at any domain or subdomain of Mongoro.com, as updated from time to time. 
•	Mongoro App means any app operated by us from which you can access our Services, whether web or mobile, as updated from time to time. 
`}</pre>
            </ol>
          </div>

          
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="modal-footer-area">
        <div className="check">
            <input type="checkbox" /> <span> &nbsp; I have read through the Policy and I agree with the Terms and Conditions applied</span>
        </div>
       <br />
           <div className="button">
           <button>Accept</button> 
           </div>
      </Modal.Footer> */}

      <Modal.Footer className="footer">
        <div className="footer-area">
          {/* <label className="checkbox" htmlFor="check">
            <input type="checkbox" id="check" /> I have read through the Policy
            and I accept the Terms and Conditions Applied
          </label> */}
          <div className="button">
            <button onClick={() => setShowTerms(false)}>Ok</button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
