import LoginForm from "../../components/forms/LoginForm";
import overlay from "../../icons/pattern_overlay.svg";
import logo from "../../icons/logo.png";

import "../../styles/createAccount.scss";
export default function SideFrame() {
  return ( 
      <div className="background-area">
        <div className="content">
          <header>Best solutions & <br /> value-for your business</header>

          <div className="overlay">
            <img src={overlay} draggable="false" alt="Best solutions" />
          </div>

          <div className="logo">
            <img src={logo} draggable="false" alt="Mongoro logo" />
          </div>
        </div>
      </div>
  
  );
}
