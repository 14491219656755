import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import success from "../../../icons/girl-success.svg";
// import success from "../../../icons/success.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";
export default function RequestSuccess(props) {
  const { showPos, setshowPos } =
    useContext(KycManager);
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>
                setshowPos({ ...showPos, request_success: false })
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <center>
            <div className="icon">
              <img src={success} alt="transfer successful" />
            </div>
            <div className="detail">
                <h5>POS Request Successful</h5>
                <p>You just requested for Mongoro POS, it will be delivered to you. Feel free to reach out to us via <br /> <span className="mo-info text-decoration-underline pointer">support.mongoro.com</span></p>
                
            </div>
          </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}
