import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import close from "../../../icons/close.svg";
import eye from "../../../icons/eye.svg";
import no_eye from "../../../icons/no_eye.svg";
import "../../../styles/modals.scss";
import { validPassword } from "../../regex/Regex";
import axiosInstance from "../../../utils/axios_instance";

export default function ChangePassword(props) {
  const {
    user, setUser,
    name,
    url,
    code,
    msg,
    setMsg,
    handleVerification,
    handleGetUser,
    setShowPassword,
    showPassword,
  } = useContext(variableManager);

  const { setShowCreate, showCreate, profile, setProfile } =
    useContext(KycManager);
  const [empty, setEmpty] = useState(false);

  const navigate = useNavigate();

  const [payload, setPayload] = useState({ 
    email: "",
    password: "",
    newPassword: "",
    confirm_password: "",
  });

  // console.log(payload);

  useEffect(() => {
    if (payload.email === "" || payload.email === undefined) {
      setPayload({ ...payload, email: user.email });
    }
  }, []);

  function handleValidation() {
    if (profile.selected.changePassword) {
      if (
        payload.password === "" ||
        payload.newPassword === "" ||
        payload.confirm_password === ""
      ) {
        setEmpty(true);
      } else {
        if (
          validPassword.test(payload.newPassword) &&
          payload.newPassword !== "" &&
          payload.confirm_password !== "" &&
          payload.newPassword === payload.confirm_password
        ) {
          handleUpdatePassword();
        } else {
          if (!validPassword.test(payload.newPassword)) {
            setMsg({
              ...msg,
              error:
                "Password must be at least 8 digits and contain at least 1 Uppercase letter, 1 lowercase letter, 1 number and a special character",
            });
          } else {
            setMsg({ ...msg, error: "Password fields do not match" });
          }
        }
      }
    } else if (profile.selected.resetPassword) {
      if (
        // payload.password === "" ||
        payload.newPassword === "" ||
        payload.confirm_password === ""
      ) {
        setEmpty(true);
      } else {
        if (
          validPassword.test(payload.newPassword) &&
          payload.newPassword === payload.confirm_password
        ) {
          handleResetPassword();
        } else {
          if (!validPassword.test(payload.newPassword)) {
            setMsg({
              ...msg,
              error:
                "Password must be at least 8 digits and contain at least 1 Uppercase letter, 1 lowercase letter, 1 number and a special character",
            });
          } else {
            setMsg({ ...msg, error: "Password fields do not match" });
          }
        }
      }
    }
  }

  function handleChange(e) {
    const value = e.target.value;
    setPayload({ ...payload, [e.target.name]: value });
    setMsg({ ...msg, error: "" });
  }

  async function handleUpdatePassword() {
    setMsg({ ...msg, spinner: true, error: "", success: "" });
   
    try {
      await axiosInstance
        .post(`/user/password/reset`, {password:payload.password, newPassword:payload.newPassword})
        .then((resp) => {
          console.log(resp.data);
          setMsg({ ...msg, spinner: false, error: "", success: "" });
          handleGetUser();

          if (resp.data) {  
            setProfile({
              ...profile,
              changePassword: false,
              passwordSuccess: true,
            }); 
            setTimeout(() => {
              setShowCreate({ ...showCreate, pinSuccess: false });
            }, 2000);
          }
        });
    } catch (error) {
      console.log(error);
      setMsg({
        ...msg,
        spinner: false,
        error: error.response.data.message,
        success: "",
      });
    }
  }

  async function handleResetPassword() {
    setMsg({ ...msg, spinner: true, error: "", success: "" });
    
    try {
      await axiosInstance
        .post(`/auth/reset`, {email:payload.email, newPassword:payload.newPassword})
        .then((resp) => {
          setMsg({ ...msg, spinner: false, error: "", success: "" });
          handleGetUser();

          if (resp.data.user) {
            // localStorage.removeItem("mongoro_user");
            // localStorage.setItem(
            //   "mongoro_user",
            //   JSON.stringify(resp.data.user)
            // );
            setProfile({
              ...profile,
              resetPassword: false,
              passwordSuccess: true,
            });

            // setShowCreate({ ...showCreate, pin: false, pinSuccess: true });
            // navigate("/user/dashboard");
            setTimeout(() => {
              setProfile({ ...profile, passwordSuccess: false });
            }, 2000);
          } else {
            setProfile({
              ...profile,
              resetPassword: false,
              passwordSuccess: true,
            });

            // setShowCreate({ ...showCreate, pin: false, pinSuccess: true });
            // navigate("/user/dashboard");
            setTimeout(() => {
              setProfile({
                ...profile,
                resetPassword: false,
                passwordSuccess: false,
              });
            }, 2000);
          }
        });
    } catch (error) {
      console.log(error);
      setMsg({
        ...msg,
        spinner: false,
        error: error.response.data.message,
        success: "",
      });
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <div
          className="modal-header pointer"
          onClick={() =>
            setProfile({
              ...profile,
              changePassword: false,
              resetPassword: false,
            })
          }
        >
          <img draggable="false" src={close} alt="close" />
        </div>
      </Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Change Password</h2>

        <div className="">
          {profile.changePassword && (
            <div className="input-area">
              <div className="label">
                <label htmlFor="current_password">Enter current password</label>
              </div>
              <div className="input">
                <input
                  type="password"
                  id="current_password"
                  name="password"
                  onChange={(e) => handleChange(e)}
                  placeholder="***************"
                />
              </div>
              {empty && payload.password === "" ? (
                <div className="mo-danger">Current password is required</div>
              ) : null}
            </div>
          )}

          <div className="input-area">
            <div className="label">
              <label htmlFor="new_password">Enter new password</label>
            </div>

            <div className="input">
              <input
                type={showPassword.password ? "text" : "password"}
                id="new_password"
                name="newPassword"
                onChange={(e) => handleChange(e)}
                placeholder="***************"
              />
           {
            showPassword.password?(
              <img
              className="eye pointer"
              onClick={() =>
                setShowPassword({
                  ...showPassword,
                  password: !showPassword.password,
                })
              }
              draggable="false"
              src={no_eye}
              alt="view password"
            />
            ):(
              <img
              className="eye pointer"
              onClick={() =>
                setShowPassword({
                  ...showPassword,
                  password: !showPassword.password,
                })
              }
              draggable="false"
              src={eye}
              alt="view password"
            />
            )
           }
            </div>

            {empty && payload.newPassword === "" ? (
              <div className="mo-danger">New password is required</div>
            ) : null}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="confirm_password">Confirm new password</label>
            </div>
            <div className="input">
              <input
                type={showPassword.confirm ? "text" : "password"}
                id="confirm_password"
                name="confirm_password"
                onChange={(e) => {
                  handleChange(e);
                  // if(payload.newPassword !== payload.confirm_password){
                  //   setMsg({...msg, error:"Password fields do not match"})
                  // }else{
                  //   setMsg({...msg, error:""})
                  // }
                }}
                placeholder="***************"
              />
            {
              showPassword.confirm?(
                <img
                className="eye pointer"
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    confirm: !showPassword.confirm,
                  })
                }
                draggable="false"
                src={no_eye}
                alt="view password"
              />
              ):(
                <img
                className="eye pointer"
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    confirm: !showPassword.confirm,
                  })
                }
                draggable="false"
                src={eye}
                alt="view password"
              />
              )
            }
            </div>
            {empty && payload.confirm_password === "" ? (
              <div className="mo-danger">Confirm password is required</div>
            ) : null}
          </div>
          {msg.error && <div className="error">{msg.error}</div>}
          <button
            className="mo-active-btn mt-4"
            disabled={msg.spinner ? "{true}" : ""}
            onClick={() => {
              handleValidation();
            }}
          >
            {msg.spinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Proceed"
            )}
          </button>
        </div>

        {profile.changePassword && (
          <p className="text-center mantra mt-4 pointer">
            Can't Recall Password ?{" "}
            <span
              className="mo-info pointer fw-bold"
              onClick={() => {
                handleVerification();
                setEmpty(false);
                setMsg({ ...msg, error: "" });

                setProfile({
                  ...profile,
                  changePassword: false,
                  verifyEmailPhone: true,
                  selected: {
                    ...profile.selected,
                    resetPassword: true,
                    resetPin: false,
                    changePassword: false,
                  },
                });
              }}
            >
              Reset
            </span>
          </p>
        )}
      </Modal.Body>
    </Modal>
  );
}
