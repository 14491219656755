import axiosInstance from "../../utils/axios_instance";

export async function getBundlesFor(billerCode){
    let data ="";
    let error="";
    try {
        await axiosInstance.get(`/user/bill/category/data/${billerCode}`).then((resp)=>{
            data= resp.data.data
        })
    } catch (err) {
        error=err
    }


    return {data, error}

}


// export async function getCableBundlesFor(billerCode){
//     let data ="";
//     let error="";
//     try {
//         await axiosInstance.get(`/user/bill/category/data/${billerCode}`).then((resp)=>{
//             data= resp.data.data
//         })
//     } catch (err) {
//         error=err
//     }


//     return {data, error}

// }