import FinishAccountSetupForm from "../components/forms/FinishAccountSetupForm"
import SideFrame from "../components/signup/SideFrame"
import signup_frame from "../images/signup-frame.png"

export default function FinishAccountSetup(){
    return (
        <div className="create-account-container">
        {/* <div className="background-area">
            <img src={signup_frame} alt="Best solutions" />
        </div>  */}

        {<SideFrame/>}  
        {<FinishAccountSetupForm/>}  

    </div>
    )
}