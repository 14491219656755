import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import success from "../../../icons/success.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";
export default function DeleteAccount(props) {
  const { profile, setProfile } = useContext(KycManager);
  const [error, setError] = useState("");
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header d-flex justify-content-between w-100 pointer"
            onClick={() => setProfile({ ...profile, deleteAccount: false })}
          >
            <h5 className="ps-5 fw-bold">Delete Account</h5>
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>

        <Modal.Body className="modalContent">
          <p className="neutral-mantra">
            <b className="bold">NOTE:</b> Kindly note that you will not be able
            to log in again after your account is deleted and you will lose all
            your data.
          </p>

          <div className="input-area">
            <label htmlFor="reason">Reason</label>
            <select
              name="reason"
              id="reason"
              onChange={(e) => {
                setProfile({
                  ...profile,
                  deleteAccount: {
                    ...profile.deleteAccount,
                    payload: {
                      ...profile.deleteAccount.payload,
                      reason: e.target.value,
                    },
                  },
                });
              }}
            >
              <option value="">Select Reason</option>
              <option value="wrong credentials">Wrong Credentials</option>
              <option value="wrong email or phone">
                Wrong Email or Phone Number
              </option>
              <option value="others">Other Reason</option>
            </select>
          </div>

          <div className="input-area">
            <label htmlFor="note">Reason</label>
            <textarea
              name="note"
              id="note"
              cols="30"
              rows="5"
              placeholder="Leave a note"
              required
              onChange={(e) => {
                setError("");
                setProfile({
                  ...profile,
                  deleteAccount: {
                    ...profile.deleteAccount,
                    payload: {
                      ...profile.deleteAccount.payload,
                      description: e.target.value,
                    },
                  },
                });
              }}
            ></textarea>
          <b className="mo-danger">{error}</b>
          </div>
          <button
            className="mo-active-btn"
            onClick={() => {
              if (profile.deleteAccount.payload.description) {
                setProfile({
                  ...profile,
                  deleteAccount: {
                    ...profile.deleteAccount,
                    enterPin: true,
                    delete: false,
                  },
                });
              } else {
                setError("Field is required");
              }
            }}
          >
            Confirm
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
