import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import pinsuccess from "../../../icons/pinsuccess.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";
import axios from "axios";
import { variableManager } from "../../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";

export default function DeletePrompt(props) {
  const { setProfile, profile } = useContext(KycManager);
  const { url, msg, setMsg, handleLoginActivity, handleGetUserBanks, handleDeleteBeneficiary, user, name, handleGetUser} = useContext(variableManager);

 
  function handleDeleteBank(id) {
    setMsg({ ...msg, spinner: true });
    try {
      axios.delete(`${url}/bank/delete`, {data:{id:id}}).then((resp) => {
        setMsg({ ...msg, spinner: false });
        console.log(resp.data);
        // handleGetUser()
        handleGetUserBanks(user._id)

        setProfile({
          ...profile,
          deletePrompt: {
            ...profile.deletePrompt,
            device: false,
            bank: false,
            bankId: "",
          },
        });
      });
    } catch (error) {
      setMsg({ ...msg, spinner: false, error: error.response.data.message });
      console.log(error);
    }
  }

 
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>
              setProfile({
                ...profile,
                deletePrompt: {
                  ...profile.deletePrompt,
                  device: false,
                  bank: false,
                  beneficiary:false,
                  beneficiaryId:"",
                  bankId: "",
                },
              })
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <div className="detail">
            <h5>
              Are you sure you want to delete this{" "}
              {profile.deletePrompt.bank ? "bank" : profile.deletePrompt.beneficiary?"beneficiary": "device"} ?
            </h5>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            {profile.deletePrompt.bank && msg.spinner ? (
              <div className="mo-danger">Removing bank...</div>
            ) :profile.deletePrompt.device && msg.spinner ?(<div className="mo-danger">Removing device...</div>): (
              <b
                className="mo-danger pointer"
                onClick={() => {
                  if (profile.deletePrompt.device) {
                    handleLoginActivity(
                      "delete",
                      profile.deletePrompt.deviceId
                    ); 
                  } else if (profile.deletePrompt.bank) { 
                    handleDeleteBank(profile.deletePrompt.bankId);
                  } else if (profile.deletePrompt.beneficiary){
                    handleDeleteBeneficiary(profile.deletePrompt.beneficiaryId)
                    
                    setProfile({...profile, deletePrompt:({...profile.deletePrompt, beneficiary:false, beneficiaryId:""})})
                  }
                }}
              >
                Yes, Delete {profile.deletePrompt.bank ? "Bank" : profile.deletePrompt.beneficiary?"Beneficiary": "Device"}
              </b>
            )}

            {msg.spinner ? (
              <Spinner />
            ) : (



              <button
                className="mo-active-btn w-25"
                onClick={() =>
                  setProfile({
                    ...profile,
                    deletePrompt: {
                      ...profile.deletePrompt,
                      device: false,
                      bank: false,
                      beneficiary:false,
                      bankId: "",
                    },
                  })
                }
              >
                Cancel
              </button>
            )}
          </div>

{msg.success && (<p className="mo-success">{msg.success}</p>)}

{/* {profile.deletePrompt.device && msg.success?(setProfile({...profile, deletePrompt:{...profile.deletePrompt, device:false}})):null } */}





          {msg.error && (
            <div className="mo-danger ">
              {msg.error}{" "}
              <span
                className="pointer text-dark"
                onClick={() => {
                  if (profile.deletePrompt.bank) {
                    handleDeleteBank(profile.deletePrompt.bankId);
                  } else if (profile.deletePrompt.device) {
                    handleLoginActivity(
                      profile.deletePrompt.deviceId,
                      "delete"
                    );
                  }
                }}
              >
                Retry
              </span>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
