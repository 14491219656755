import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import tag1 from "../../../icons/tag1.svg";
import tag2 from "../../../icons/tag2.svg";
import close from "../../../icons/close.svg";
import next from "../../../icons/next.svg";
import free from "../../../icons/free.svg";
import coming_soon from "../../../icons/coming-soon.png";
import bank_icon from "../../../icons/bank_icon.svg";
import "../../../styles/getPaid.scss";
import { KycManager } from "../../../context-api/KycContext";
export default function SelectMethod(props) {
  const [userName, setUsername] = useState("");
  const [modalShowPayNow, setModalShowPayNo] = useState(false);

  const { setModalShowAccount, payModalShow, setPayModalShow, initPayModal } =
    useContext(KycManager);
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>
              setPayModalShow({ ...payModalShow, receiveMethod: false })
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <h2>Request Payment</h2>

          <div className="input-are">
            <div className="method-area"
            onClick={()=>{
                setModalShowAccount(true)
                setPayModalShow({ ...initPayModal, receiveMethod: false })
            }}
            >
              <div className="method pointer">
                <div className="icon">
                  <img draggable="false" src={bank_icon} alt="bank transfer" />
                </div>
                <div className="text">Bank Transfer</div>
              </div>


              
              <div className="charge-proceed">
                {/* <div className="charge"> 
                  FREE
                </div> */}
                <div className="proceed">
                  <img draggable="false" src={next} alt="proceed" />
                </div>
              </div>
            </div>


            <div className="method-area not-allowed"
            // onClick={()=>setPayModalShow({...initPayModal, fromCard:{...initPayModal.fromCard, method:true}})}
            >
              <div className="method">
                <div className="icon">
                  <img draggable="false" src={bank_icon} alt="bank transfer" />
                </div>
                <div className="text">Card Deposit</div>
                <div className="coming-soon-container" >
                <img src={coming_soon} alt="coming soon" />
              </div>
                </div>


              
              <div className="charge-proceed">
                {/* <div className="charge"> 
                  FREE
                </div> */}
                <div className="proceed">
                  <img draggable="false" src={next} alt="proceed" />
                </div>
              </div>
            </div>

            {/* <div className="method-area">
              <div className="method">
                <div className="icon">
                  <img draggable="false" src={bank_icon} alt="bank transfer" />
                </div>
                <div className="text">Generate QRcode</div>
              </div>


              
              <div className="charge-proceed">
                <div className="charge"> 
                  FREE
                </div>
                <div className="proceed">
                  <img draggable="false" src={next} alt="proceed" />
                </div>
              </div>

            </div>

            <div className="method-area"
            onClick={()=>{
                setPayModalShow({ ...initPayModal, mongoroType: true })
            }}
            >
              <div className="method">
                <div className="icon">
                  <img draggable="false" src={bank_icon} alt="bank transfer" />
                </div>
                <div className="text">Pay via <u className="pointer">Mongoro.me</u></div>
              </div>


              
              <div className="charge-proceed">
                <div className="charge"> 
                  FREE
                </div>
                <div className="proceed">
                  <img draggable="false" src={next} alt="proceed" />
                </div>
              </div>
            </div>

            <div className="method-area">
              <div className="method">
                <div className="icon">
                  <img draggable="false" src={bank_icon} alt="bank transfer" />
                </div>
                <div className="text">Pay via USSD</div>
              </div>


              
              <div className="charge-proceed">
                <div className="charge"> 
                  FREE
                </div>
                <div className="proceed">
                  <img draggable="false" src={next} alt="proceed" />
                </div>
              </div>
            </div>

            <div className="method-area">
              <div className="method">
                <div className="icon">
                  <img draggable="false" src={bank_icon} alt="bank transfer" />
                </div>
                <div className="text">Pay via Phone NUmber</div>
              </div>


              
              <div className="charge-proceed">
                <div className="charge"> 
                  FREE
                </div>
                <div className="proceed">
                  <img draggable="false" src={next} alt="proceed" />
                </div>
              </div>
            </div> */}

   

          </div>
        </Modal.Body>
      </Modal>

      <div className="other-modals">
        {/* <SendToUsername
                    show={payModalShow.toUsername}
                    onHide={()=>setPayModalShow({...payModalShow, toUsername:false})}
                    backdrop="static"
                /> */}

        {/* <SendToBank
                    show={payModalShow.toBank}
                    onHide={()=>setPayModalShow({...payModalShow, toBank:false})}
                    backdrop="static"
                /> */}
      </div>
    </div>
  );
}
