import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { variableManager } from "../../context-api/VariableContex";
import "../../styles/updatePassword.scss";
import { validPassword } from "../regex/Regex";
import eye from "../../icons/eye.svg";
import no_eye from "../../icons/no_eye.svg";
import axiosInstance from "../../utils/axios_instance";


export default function UpdatePasswordForm() {
  const { url, msg, setMsg, unknownUser, showPassword, setShowPassword } =
    useContext(variableManager);

  const [payload, setPayload] = useState({
    email: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (unknownUser.email) {
      setPayload({ ...payload, email: unknownUser.email });
    } else {
      setPayload({
        ...payload,
        email: localStorage.getItem("unknown_user_email"),
      });
    }
  }, []);

  const navigate = useNavigate();
 

  async function handleResetPassword() {
    setMsg({ ...msg, spinner: true, error: "", success: "" });

    try {
      await axiosInstance.post(`/auth/reset`, {email:payload.email, newPassword:payload.newPassword}).then((resp) => {
        setMsg({ ...msg, spinner: false, error: "", success: resp.data.msg });
        setTimeout(() => {
          setMsg({ ...msg, success: "", error: "" });
        }, 2000);

        navigate("/login");
        setMsg({ ...msg, success: "", error: "" }); 
        localStorage.removeItem("unknown_user_email") 
      });

    } catch (error) { 
      setMsg({
        ...msg,
        spinner: false,
        error: error.response.data.message,
        success: "",
      });
    }
  }

  return (
    <div className="update-password-container create-account-container">
      <div className="form-area">
        {/* <span className="signup-redirect-link">Already have an account ?  <Link to={"/login"} className="pointer text-dark fw-bold"> &nbsp; Log in</Link></span> */}

        <div className="content">
          <h2>Update Password</h2>
          <p className="mantra mb-3">
            Kindly enter a new password and confirm the new password. This will
            be your new credentials to access your account
          </p>

          <div className="input-area">
            <div className="label">
              <label htmlFor="password">Create a Password</label>
            </div>
            <div className="input">
              <input
                type={showPassword.password ? "text" : "password"}
                id="password"
                onChange={(e) =>
                  setPayload({ ...payload, newPassword: e.target.value })
                }
                placeholder="***************"
              />
              {
                showPassword.password?(
                  <img
                className="eye pointer"
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    password: !showPassword.password,
                  })
                }
                draggable="false"
                src={no_eye}
                alt="view password"
              />
                ):(
                  <img
                className="eye pointer"
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    password: !showPassword.password,
                  })
                }
                draggable="false"
                src={eye}
                alt="view password"
              />
                )
              }
            </div>
          </div>
          <div className="input-area">
            <div className="label">
              <label htmlFor="password">Retype Password</label>
            </div>
            <div className="input">
              <input
                type={showPassword.confirm ? "text" : "password"}
                onChange={(e) =>
                  setPayload({ ...payload, confirmPassword: e.target.value })
                }
                id="confirm_password"
                placeholder="***************"
              />
            {
              showPassword.confirm?(
                <img
                className="eye pointer"
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    confirm: !showPassword.confirm,
                  })
                }
                draggable="false"
                src={no_eye}
                alt="view password"
              />
              ):(
                <img
                className="eye pointer"
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    confirm: !showPassword.confirm,
                  })
                }
                draggable="false"
                src={eye}
                alt="view password"
              />
              )
            }
            </div>
          </div>

          {msg.error && <p className="error">{msg.error}</p>}
          {msg.success && <p className="success">{msg.success}</p>}

          <button
            className="mt-5"
            onClick={() => {
              if (
                payload.newPassword &&
                payload.newPassword === payload.confirmPassword
              ) {
                if (validPassword.test(payload.newPassword)) {
                  handleResetPassword();
                } else {
                  setMsg({
                    ...msg,
                    error:
                      "Password must be at least 8 digits and contain at least 1 Uppercase letter, 1 lowercase letter and 1 number",
                  });
                  setTimeout(() => {
                    setMsg({ ...msg, error: "" });
                  }, 16000);
                }
              } else if (!payload.newPassword || !payload.confirmPassword) {
                setMsg({ ...msg, error: "All fields are required" });
                setTimeout(() => {
                  setMsg({ ...msg, error: "" });
                }, 3000);
              } else if (payload.newPassword !== payload.confirmPassword) {
                setMsg({ ...msg, error: "passwords do not match" });
                setTimeout(() => {
                  setMsg({ ...msg, error: "" });
                }, 3000);
              }
            }}
          >
            {msg.spinner ? <Spinner size="sm" /> : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
}
