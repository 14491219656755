import { BsThreeDotsVertical } from "react-icons/bs";
import done from "../../icons/done.svg";
import pending from "../../icons/pending.svg";
import failed from "../../icons/failed.svg";
import { useContext, useState } from "react";
import { variableManager } from "../../context-api/VariableContex";
import { KycManager } from "../../context-api/KycContext";
import DisputeTransaction from "./support/DisputeTransaction";
export default function Activities() {
  const { dashboard, nameCase } = useContext(variableManager);
  // const {raiseTicket, setRaiseTicket} = useContext(KycManager)
  const [raiseTicket, setRaiseTicket] = useState({ action: false, id: "" });

  return (
    <div className="activities-container">
      <div className="table-section">
        <table>
          <thead>
            <tr>
              <td>{/* <input type="checkbox" /> */}</td>
              <td>ID Invoice</td>
              <td>Date & Time</td>
              <td>Account Details</td>
              <td>Transaction Type</td>
              <td>Reference NO:</td>
              <td>Amount</td>
              <td>Status</td>
            </tr>
          </thead>

          {dashboard.history.length ? (
            dashboard.history.map((e, i) => {
              return (
                <tbody key={i}>
                  <tr>
                    <td>{/* <input type="checkbox" /> */}</td>
                    <td>{e.transactionID}</td>
                    <td>{new Date(e.Date).toLocaleString()}</td>
                    <td className="user">
                      {/* <div className="image">
                        <img src={avatar} alt="" />
                      </div> */}
                      <div className="others">
                        <p className="b">
                          {e.fullName ? nameCase(e.fullName) : "@user"}
                        </p>
                        <p>{e.bankName ? nameCase(e.bankName) : "Mongoro"}</p>
                        {/* {e.flw_id} */}
                      </div>
                    </td>
                    <td className="">{e.serviceType}</td>
                    <td className="">{e.reference}</td>
                    <td className="amount">₦{e.amount}</td>
                    <td>
                      {" "}
                      <div className="d-flex align-items-center">
                        <b
                          className={
                            e.status === "Pending" || e.status === "pending"
                              ? " status bg-light"
                              : e.status === "Failed" || e.status === "failed"
                              ? " status failed"
                              : "status"
                          }
                        >
                          <div className="done">
                            {e.status === "Failed" || e.status === "failed" ? (
                              <img
                                src={failed}
                                alt="done"
                                className="img-fluid"
                                style={{ width: "15px" }}
                              />
                            ) : e.status === "pending" ? (
                              <img
                                src={pending}
                                alt="done"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={done}
                                alt="done"
                                className="img-fluid"
                              />
                            )}
                          </div>{" "}
                          <span
                            className={
                              e.status === "Failed" || e.status === "failed"
                                ? "mo-danger "
                                : e.status === "Pending" ||
                                  e.status === "pending"
                                ? "pending"
                                : ""
                            }
                          >
                            {" "}
                            {e.status}
                          </span>
                        </b>
                        <div className="options">
                          {" "}
                          <BsThreeDotsVertical
                            className="ms-3 text-ligh"
                            onClick={() => {
                              setRaiseTicket({
                                ...raiseTicket,
                                action: !raiseTicket.action,
                                id: e.tid,
                              });
                            }}
                          />
                          {raiseTicket.action && raiseTicket.id === e.tid && (
                            <DisputeTransaction amount={e.amount} category={e.serviceType} />
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })
          ) : (
            <tbody>
              <tr>
                <td>You have no transaction history</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <p className="showing">
        {" "}
        Showing <span className="shown">
          {dashboard.history.length}
        </span> of <span className="total">{dashboard.history.length}</span>{" "}
        enteries
      </p>
    </div>
  );
}
